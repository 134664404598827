import config from 'config';
import { capitalizeString } from 'helpers';
import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import ShidoIcon from 'components/Icons/ShidoIcon';

import { Images } from '../types';

import ConfettiAnimation from './ConfettiAnimation';
import ExpiredBlock from './ExpiredBlock';
import {
  RegisterSymbol,
  ShidoLink,
  ShidoLinkWrapper,
} from './styledElements/StyledShidoLink';

type Props = {
  userName: string;
  images: Images;
};

const MobileSuccess = ({ userName, images }: Props) => (
  <>
    <MainContent $background={images.background}>
      <ShidoLinkWrapper>
        <ShidoLink href={config.shidoURL}>
          <LogoIcon width={63} height={37} />
          <ShidoIcon width={98} height={32} />
          <RegisterSymbol>®</RegisterSymbol>
        </ShidoLink>
      </ShidoLinkWrapper>
      <Gratitude>
        Thanks {capitalizeString(userName)}, you’re all set!
      </Gratitude>
      <Suggestion>Open the shido app & sign in</Suggestion>
      <ConfettiWrapper>
        <ConfettiAnimation height={450} />
      </ConfettiWrapper>
    </MainContent>
    <ExpiredBlock isMobile />
  </>
);

const MainContent = styled.div<{ $background?: string }>`
  overflow: hidden;
  height: 470px;
  padding-top: 40px;
  background:
    center 154% no-repeat url('/images/mobile_background.png'),
    ${({ theme }) => theme.colors.primary} 50% 88% / contain no-repeat
      ${({ $background }) =>
        $background
          ? `url(${$background})`
          : "url('/images/default_mobile_background_image.png')"};

  @media screen and (orientation: landscape) {
    background:
      51% 381px / cover no-repeat url('/images/mobile_background.png'),
      ${({ theme }) => theme.colors.primary} 50% 88% / cover no-repeat
        ${({ $background }) =>
          $background
            ? `url(${$background})`
            : "url('/images/default_mobile_background_image.png')"};
  }
`;

const Gratitude = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 400;
`;

const Suggestion = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
`;

const ConfettiWrapper = styled.div`
  margin-right: 400px;
  transform: scale(0.4);
`;

export default MobileSuccess;
