import React from 'react';

const IncreaseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.013"
    height="9.514"
    viewBox="0 0 17.013 9.514"
    {...props}
  >
    <defs>
      <clipPath id="increase-clip-path">
        <path
          id="Path_14441"
          data-name="Path 14441"
          d="M10.177-4.09A.25.25,0,0,1,10-4.017a.25.25,0,0,1-.177-.073L7.914-6A2,2,0,0,0,5.086-6L1.293-2.206a1,1,0,0,0-.273.973,1,1,0,0,0,.714.714,1,1,0,0,0,.973-.273L6.323-4.408A.25.25,0,0,1,6.5-4.482a.25.25,0,0,1,.177.073L8.586-2.5a2,2,0,0,0,2.828,0l4.013-4.014a.25.25,0,0,1,.353,0l1.366,1.367A.5.5,0,0,0,17.5-5a.489.489,0,0,0,.191-.038A.5.5,0,0,0,18-5.5v-4a.5.5,0,0,0-.5-.5h-4a.5.5,0,0,0-.463.309.5.5,0,0,0,.109.546l.867.866a.25.25,0,0,1,0,.353Z"
          transform="translate(-0.988 10)"
          fill="#404d73"
        />
      </clipPath>
    </defs>
    <g id="Group_56729" data-name="Group 56729" opacity="0.8">
      <g
        id="Group_15431"
        data-name="Group 15431"
        transform="translate(0 0)"
        clipPath="url(#increase-clip-path)"
      >
        <path
          id="Path_14440"
          data-name="Path 14440"
          d="M-4.012-15H23V4.515H-4.012Z"
          transform="translate(-0.988 9.999)"
          fill="#404d73"
        />
      </g>
    </g>
  </svg>
);

export default IncreaseIcon;
