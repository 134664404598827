import React, { forwardRef } from 'react';
import styled from 'styled-components';

import CircleArrowIcon from 'components/Icons/CircleArrowIcon';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

import { SuccessStoriesCarouselData } from '../SuccessStoriesCarousel/carouselData';
import SuccessStoriesCarousel from '../SuccessStoriesCarousel/SuccessStoriesCarousel';

import { ArrowCircleTemplate } from './HealthImprovementProgramSection';
import { DashedLine } from './ShidoPowerSection';

type Props = {
  withFooter?: boolean;
  onScroll?: () => void;
};

const SuccessStoriesSection = (
  { withFooter = true, onScroll }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  return (
    <Container ref={ref}>
      <Title>Success stories</Title>
      <Subtitle>
        See what our satisfied users have to say about their journey with Shido.
      </Subtitle>
      <SuccessStoriesCarousel
        isMobile={isMobile}
        slides={SuccessStoriesCarouselData}
      />
      {!!onScroll && withFooter && (
        <ArrowCircleContainer onClick={onScroll}>
          <CircleArrowIcon uniqueID="success-stories-arrow" />
        </ArrowCircleContainer>
      )}
      {withFooter && <DashedLine />}
    </Container>
  );
};

const ArrowCircleContainer = styled(ArrowCircleTemplate)`
  margin: 40px auto 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 38px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 330px;
  }
`;

const Title = styled.h4`
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 23px;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  padding: 63px 0 66px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 34px;
    padding-bottom: 32px;
  }
`;

export default forwardRef(SuccessStoriesSection);
