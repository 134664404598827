import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isIOSDevice } from 'helpers/getDeviceInfo';
import { AppRoutes } from 'router/models';

import { getAffiliateStoreOfferCode } from '../../modules/affiliateOffer/services';
import { AffiliatePlatform } from '../../modules/affiliateOffer/types';

export const AffiliateQR = () => {
  const { publicCode, emailVerificationCode } = useParams<{
    publicCode: string;
    emailVerificationCode: string;
  }>();
  const navigate = useNavigate();

  useQuery(
    ['storeOfferCode', publicCode, emailVerificationCode],
    () => {
      if (publicCode && emailVerificationCode) {
        return getAffiliateStoreOfferCode({
          platform: isIOSDevice
            ? AffiliatePlatform.IOS
            : AffiliatePlatform.ANDROID,
          publicCode,
          emailVerificationCode: decodeURIComponent(emailVerificationCode),
        });
      }

      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          window.location.assign(data.url);
        }
      },
      onError: () => {
        navigate(AppRoutes.Base);
      },
      enabled: !!publicCode && !!emailVerificationCode,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Container>
      <p>Redirect...</p>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};

  p {
    font-size: 40px;
    font-weight: 400;
    color: white;
  }
`;
