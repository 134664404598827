/* eslint-disable camelcase */
import axios from 'axios';
import { differenceInDays } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { Currency, CurrencyCode } from '../types';

const CLIENT_LOCATION = 'clientLocation';
const IP_GEOLOCATION_API = 'https://ipapi.co/json/';
const MONTH = 30;

type Props = {
  continent_code: string;
  country: string;
};

export const useGetCurrency = () => {
  const [clientCurrency, setClientCurrency] = useState<CurrencyCode>(
    Currency.GBP
  );

  const setCurrency = useCallback(({ continent_code, country }: Props) => {
    if (country === 'GB') {
      setClientCurrency(Currency.GBP);

      return;
    }

    if (continent_code === 'EU') {
      setClientCurrency(Currency.EUR);

      return;
    }

    setClientCurrency(Currency.USD);
  }, []);

  const fetchLocation = useCallback(async () => {
    const { data } = await axios.get<Props>(IP_GEOLOCATION_API);

    localStorage.setItem(
      CLIENT_LOCATION,
      JSON.stringify({
        continent_code: data.continent_code,
        country: data.country,
        createdAt: new Date(),
      })
    );

    setCurrency({
      country: data.country,
      continent_code: data.continent_code,
    });
  }, [setCurrency]);

  useEffect(() => {
    const clientLocationData = localStorage.getItem(CLIENT_LOCATION);

    if (!clientLocationData) {
      fetchLocation();

      return;
    }

    const clientLocation = JSON.parse(clientLocationData);

    if (
      differenceInDays(new Date(), new Date(clientLocation.createdAt)) >= MONTH
    ) {
      fetchLocation();
    } else {
      setCurrency({
        country: clientLocation.country,
        continent_code: clientLocation.continent_code,
      });
    }
  }, [fetchLocation, setClientCurrency, setCurrency]);

  return {
    clientCurrency,
  };
};
