import styled from 'styled-components';

export const ShidoLinkWrapper = styled.div`
  position: relative;
  width: 180px;
  margin: 0 auto 37px;
`;

export const RegisterSymbol = styled.div`
  position: absolute;
  top: 0;
  right: -6px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`;

export const ShidoLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 15px;
  text-decoration: none;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
