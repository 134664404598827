import React from 'react';
import styled, { css } from 'styled-components';

import getDeviceInfo, { DeviceType, OS } from 'helpers/getDeviceInfo';
import { Footer } from 'modules/betterhealth/components/ActivateMobileContent';
import DesktopAndTablet from 'modules/betterhealth/components/DesktopAndTablet';
import Header from 'modules/betterhealth/components/Header';
import Mobile from 'modules/betterhealth/components/Mobile';

export const BetterHealth = () => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;
  const isiOSDevice = getDeviceInfo().name === OS.Ios;

  return (
    <BetterHealth.Wrapper>
      {isMobile ? (
        <BetterHealth.ContentContainer $isMobile={isMobile}>
          <Mobile isiOSDevice={isiOSDevice} />
        </BetterHealth.ContentContainer>
      ) : (
        <>
          <Header isMobile={isMobile} />
          <BetterHealth.ContentContainer $isMobile={isMobile}>
            <DesktopAndTablet isiOSDevice={isiOSDevice} />
          </BetterHealth.ContentContainer>
          <Footer />
        </>
      )}
    </BetterHealth.Wrapper>
  );
};

BetterHealth.Wrapper = styled.div`
  display: grid;
  justify-content: center;
  overflow: hidden;
`;

BetterHealth.ContentContainer = styled.main<{ $isMobile?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  width: 900px;
  height: 1044px;
  margin: 35px auto 80px;
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 95vw;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 0;
      box-shadow: none;
      height: auto;
      width: 100vw !important;
    `};
`;
