import React from 'react';

export const codeFormKeyDownHandler = (
  event: React.KeyboardEvent<HTMLInputElement>,
  focusedElementIndex: number,
  setFocus: (value: string) => void,
  values: string[],
  inputValue?: string
) => {
  if (event.key === 'Backspace' && !inputValue) {
    if (focusedElementIndex !== 0) {
      setFocus(values[focusedElementIndex - 1]);
    }
  }
  if (event.key === 'ArrowLeft') {
    if (focusedElementIndex === 0) {
      setFocus(values[values.length - 1]);
    } else {
      setFocus(values[focusedElementIndex - 1]);
    }
  }
  if (event.key === 'ArrowRight') {
    if (focusedElementIndex === values.length - 1) {
      setFocus(values[0]);
    } else {
      setFocus(values[focusedElementIndex + 1]);
    }
  }
};
