import config from 'config';
import { axiosClient } from 'lib';

import { PromotionOffer } from '../types';

export const getPromotionOffer = async (
  publicCode: string
): Promise<PromotionOffer> => {
  const { data } = await axiosClient.get<{ payload: { data: PromotionOffer } }>(
    `/api/v${config.nextApiVersion}/PromotionOffers?publicCode=${publicCode}`
  );

  return data.payload.data;
};
