import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { AppleIcon } from 'components/Icons/AppleIcon';

type StoreButtonProps = {
  href: string;
  color: string;
  isHeader?: boolean;
  isMobile?: boolean;
  variant: 'appStore' | 'googlePlay';
};

/**
 * @param variant - determine which store will be displayed
 */
const StoreButton = ({
  href,
  color,
  isHeader,
  isMobile,
  variant,
}: StoreButtonProps) => {
  /* eslint-disable */
  const iosDownloadMessage = isMobile
    ? isHeader
      ? 'Get on the'
      : 'Download on'
    : 'Download on'.toUpperCase();
  const androidDownloadMessage = isMobile
    ? isHeader
      ? 'Get on the'
      : 'Get it on'
    : 'Get it on'.toUpperCase();

  return (
    <>
      <StyledLink
        $isMobile={isMobile}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          $color={color}
          $variant={variant}
          $isHeader={!!isHeader}
          $isMobile={isMobile}
        >
          {variant === 'appStore' ? (
            <IconWrapper $isMobile={isMobile} $isHeader={!!isHeader}>
              <AppleIcon prefixId="store" />
            </IconWrapper>
          ) : (
            <IconWrapper $isMobile={isMobile} $isHeader={!!isHeader}>
              <img
                src="/icons/googlePlay.svg"
                alt="Google play icon"
                width="22.83"
                height="21.52"
              />
            </IconWrapper>
          )}
          <ButtonContent $isMobile={isMobile}>
            {isMobile ? (
              <ButtonSubtitle $isMobile={isMobile}>
                {variant === 'appStore'
                  ? iosDownloadMessage
                  : androidDownloadMessage}{' '}
                {variant === 'appStore' ? 'App Store' : 'Google Play'}
              </ButtonSubtitle>
            ) : (
              <>
                <ButtonSubtitle $isMobile={isMobile}>
                  {variant === 'appStore' ? 'Download on' : 'Get it on'}
                </ButtonSubtitle>
                <ButtonTitle>
                  {variant === 'appStore' ? 'App Store' : 'Google Play'}
                </ButtonTitle>
              </>
            )}
          </ButtonContent>
        </Button>
      </StyledLink>
    </>
  );
};

const Button = styled.div<{
  $isHeader: boolean;
  $color: string;
  $isMobile?: boolean;
  $variant?: string;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: ${({ $variant }) => ($variant === 'googlePlay' ? '7px' : '12px')};
  width: ${({ $isMobile }) => ($isMobile ? '100%' : '140px')};
  height: 40px;
  border-radius: ${({ $isHeader }) => (!$isHeader ? '4px' : 'none')};
  border: none;
  background-color: ${({ $color }) => $color};
  box-shadow: 0 10px 20px rgb(0 0 0 / 25%);
  outline: none;
  cursor: pointer;
  padding: ${({ $isMobile }) => ($isMobile ? '0' : '2px 0 0 10px')};
  position: relative;
  transition: box-shadow 0.5s ease-out;

  &:hover {
    box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      justify-content: center;
      align-items: center;
    `};
`;

const ButtonTextStyles = css`
  line-height: 11px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
`;

const ButtonSubtitle = styled.span<{ $isMobile?: boolean }>`
  font-size: 10px;
  text-transform: uppercase;
  ${ButtonTextStyles};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 14px;
      text-transform: unset;
    `};
`;

const ButtonTitle = styled.p`
  font-size: 14px;
  margin-top: 1px;
  ${ButtonTextStyles};
  line-height: 16px;
`;

const StyledLink = styled.a<{ $isMobile?: boolean }>`
  text-decoration: none;
  display: grid;
  justify-content: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: initial;
      width: 80vw;
      margin: 0 auto;
    `};
`;

const ButtonContent = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  text-align: left;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-left: unset;
    `};

  /* Styles for galaxy fold resolution */
  @media (width <= 319.8px) {
    margin-left: 15px;
  }
`;

const IconWrapper = styled.div<{
  $isHeader: boolean;
  $isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ $isMobile, $isHeader }) =>
    $isMobile &&
    $isHeader &&
    css`
      left: -8px;
      top: 0;
    `};
  ${({ $isMobile, $isHeader }) =>
    $isMobile &&
    !$isHeader &&
    css`
      position: absolute;
      left: 10px;
      top: 8px;
    `};

  /* Styles for galaxy fold resolution */
  @media (width <= 319.8px) {
    top: 7px;
    left: 9px;
  }
`;

export default memo(StoreButton);
