import config from 'config';
import { fetcher, generateStoreLink } from 'helpers';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { BetterHealthIcon } from 'components/Icons/BetterHealthIcon';
import StoreButton from 'components/StoreButton';
import theme from 'theme/theme';

import ActivateButton from './ActivateButton';
import CircleContent from './CircleContent';
import Header from './Header';

type ActivateMobileContentProps = {
  isiOSDevice: boolean;
  setCodesExpired: () => void;
};

const CODE_FETCH_URL =
  'https://shido-me-backend-prod.azurewebsites.net/api/GetUnusedOfferCode?code=1FAa0caEQUwyGEV1pP4BKBeEihBdlhrd6FviXguFwo3K/hxKLjdg8A==&platform=';

const ActivateMobileContent = ({
  isiOSDevice,
  setCodesExpired,
}: ActivateMobileContentProps) => {
  const [pending, setPending] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackUserDevice = useTrackEvent(appInsights, 'User device', {});

  const fetchData = useCallback(async () => {
    setPending(true);

    try {
      const userOfferCodeInfo = await fetcher(
        `${CODE_FETCH_URL}${isiOSDevice ? 'ios' : 'android'}`
      );

      if (userOfferCodeInfo) {
        const redirectLink = generateStoreLink(
          isiOSDevice,
          userOfferCodeInfo.offerCode
        );

        trackUserDevice({
          userAgent: window.navigator.userAgent,
          offerLink: redirectLink,
        });

        window.location.href = redirectLink;
      } else {
        setPending(false);
        setCodesExpired();
        trackUserDevice({
          userAgent: window.navigator.userAgent,
          offerLink: 'EXPIRED',
        });
      }
    } catch (error) {
      setPending(false);
      setCodesExpired();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isiOSDevice ? (
        <StoreButton
          color={theme.colors.black}
          variant="appStore"
          href={config.appStoreLink}
          isMobile
          isHeader
        />
      ) : (
        <StoreButton
          color={theme.colors.black}
          variant="googlePlay"
          href={config.googlePlayLink}
          isMobile
          isHeader
        />
      )}
      <MainContent>
        <TitleContainer>
          <Header isMobile />
        </TitleContainer>
        <HeroContainer>
          <CircleContent />
        </HeroContainer>
      </MainContent>
      <ActiveButtonWrapper>
        <ActivateButton fetchData={fetchData} pending={pending} />
      </ActiveButtonWrapper>
      <Footer>
        <>
          <MobileFooterLogoWrapper>
            <BroughtLabel>Brought to you by</BroughtLabel>
            <BetterHealthIcon />
          </MobileFooterLogoWrapper>
        </>
      </Footer>
    </>
  );
};

export const MainContent = styled.div`
  padding: 0 20px;
  height: 570px;
  background:
    bottom no-repeat url('/images/desktop_background_2.svg'),
    55% 190% no-repeat url('/images/mobile_background_1.svg'),
    ${({ theme }) => theme.colors.primary} 50% 87% / contain no-repeat
      url('/images/desktop_background_1.png');

  @media screen and (orientation: landscape) {
    background:
      50% 126px / cover no-repeat url('/images/desktop_background_2.svg'),
      55% 190% no-repeat url('/images/mobile_background_1.svg'),
      ${({ theme }) => theme.colors.primary} 50% 87% / contain no-repeat
        url('/images/desktop_background_1.png');
  }
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 40px;
`;

const ActiveButtonWrapper = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
`;

export const Footer = styled.div<{ $isExpired?: boolean }>`
  position: relative;
  display: grid;
  justify-content: center;
  background: white;
  top: ${({ $isExpired }) => ($isExpired ? '-40px' : '-10px')};
`;

export const MobileFooterLogoWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  justify-content: center;
`;

const BroughtLabel = styled.span`
  margin: 7px 0;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
`;

const HeroContainer = styled.div`
  margin-top: 42px;
  display: grid;
  justify-content: center;
  font-weight: bold;
`;

export default ActivateMobileContent;
