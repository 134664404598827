import React from 'react';

const ImmuneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_4274" data-name="Group 4274" transform="translate(-985 -1825)">
      <path
        id="Path_3898"
        data-name="Path 3898"
        d="M997,1825a12,12,0,1,0,12,12A12.013,12.013,0,0,0,997,1825Zm0,23a11,11,0,1,1,11-11A11.012,11.012,0,0,1,997,1848Z"
        fill="#69b0c7"
      />
      <path
        id="Path_3899"
        data-name="Path 3899"
        d="M997,1828a9,9,0,1,0,9,9A9.01,9.01,0,0,0,997,1828Zm5,10.5a.5.5,0,0,1-.5.5H999v2.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5V1839h-2.5a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5H995v-2.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v2.5h2.5a.5.5,0,0,1,.5.5Z"
        fill="#69b0c7"
      />
    </g>
  </svg>
);

export default ImmuneIcon;
