import { useCallback, useRef } from 'react';

const useCreateRefsForClick = () => {
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const responseRef = useRef<HTMLDivElement | null>(null);
  const emailRef = useRef<HTMLDivElement | null>(null);

  const handleRefClick = useCallback(
    (element: React.RefObject<HTMLDivElement>) => {
      return () => {
        if (element.current) {
          element.current?.scrollIntoView({ behavior: 'smooth' });
        }
      };
    },
    []
  );

  return {
    refs: [detailsRef, responseRef, emailRef],
    handleRefClick,
  };
};

export default useCreateRefsForClick;
