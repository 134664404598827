import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import ShidoIcon from 'components/Icons/ShidoIcon';
import theme from 'theme/theme';

const SHADOW_DISTANCE = 700;

type Props = {
  accountName: string;
};

const StickyHeader = ({ accountName }: Props) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > SHADOW_DISTANCE && !scrolled) {
        setScrolled(true);
      } else if (window.scrollY <= SHADOW_DISTANCE && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <Container $withShadow={scrolled}>
      <Wrapper>
        <LogoWrapper>
          <LogoIcon width={63} height={40} fill="white" />
          <ShidoWrapper>
            <ShidoIcon width={98} height={32} fill="white" />
          </ShidoWrapper>
          <TradeMark $logoColor={theme.colors.white}>®</TradeMark>
        </LogoWrapper>
        {!!accountName && <Text>Brought to you by {accountName}</Text>}
      </Wrapper>
    </Container>
  );
};

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
  }
`;

const TradeMark = styled.span<{ $logoColor: string }>`
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 12px;
  color: ${({ $logoColor }) => $logoColor};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px;
    top: -3px;
    right: -17px;
  }
`;

const ShidoWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    width: 46px;
    height: 29px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.header<{ $withShadow: boolean }>`
  background-color: ${({ theme }) => theme.colors.topicBlue};
  display: flex;
  align-items: center;
  padding: 30px 20px;
  border-bottom: 0.25px solid rgb(255 255 255 / 60%);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: filter 0.3s;
  ${({ $withShadow }) =>
    $withShadow &&
    css`
      filter: drop-shadow(0 20px 40px rgb(0 0 0 / 20%));
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 21px 20px;
  }
`;

export default StickyHeader;
