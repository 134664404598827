import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    scroll-behavior: auto;
  }

  body {
    font-family: 'F37Ginger', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  h1,
  h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: inherit;
    color: ${theme.colors.darkBlue};
  }

  p {
    font-size: 20px;
    line-height: inherit;
    color: ${theme.colors.darkBlue};
    font-family: 'F37Ginger', sans-serif;
  }

  span {
    font-size: 12px;
    line-height: inherit;
    color: ${theme.colors.darkBlue};
    font-family: 'F37Ginger', sans-serif;
  }

  button,
  input {
    font-family: 'F37Ginger', sans-serif;
  }

  .no-scroll {
    overflow: hidden;
  }
`;
