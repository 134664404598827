import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Block, Inline } from '@contentful/rich-text-types';

export const HeadingNode = (node: Block | Inline, children: ReactNode) => (
  <>
    <Underline />
    <Heading>{children}</Heading>
  </>
);

export const ParagraphNode = (node: Block | Inline, children: ReactNode) => (
  <Paragraph>{children}</Paragraph>
);

export const LinkNode = (node: Block | Inline, children: ReactNode) => (
  <Link href={node.data.uri}>{children}</Link>
);

export const BoldTextNode = (text: ReactNode) => <BoldText>{text}</BoldText>;

export const UnorderedlListNode = (
  node: Block | Inline,
  children: ReactNode
) => <UnorderedlList>{children}</UnorderedlList>;

export const ListItemNode = (node: Block | Inline, children: ReactNode) => (
  <ListItem>{children}</ListItem>
);

const Heading = styled.h2`
  font-size: 25px;
  font-weight: bold;
  margin: 20px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    margin: 28px 0;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 15px;
  font-weight: 300;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5em;
  }
`;

const Underline = styled.span`
  height: 3px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: block;

  &:first-child {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 20px;
    margin-bottom: 37px;
  }
`;

const Link = styled.a`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

const BoldText = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const UnorderedlList = styled.ul`
  &&& {
    margin-top: -20px;
    font-size: 16px;
    list-style-type: disc;
    padding-inline-start: 1ch;
    padding-left: 30px;
    color: ${({ theme }) => theme.colors.primary};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: 5px;
    }
  }
`;

const ListItem = styled.li`
  p {
    margin-bottom: 8px;
  }
`;
