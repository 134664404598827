import config from 'config';
import React from 'react';
import styled from 'styled-components';

import StoreButton from 'components/StoreButton';
import theme from 'theme/theme';

import {
  Footer,
  MainContent,
  MobileFooterLogoWrapper,
} from './ActivateMobileContent';
import CircleContent from './CircleContent';
import Header from './Header';

type ExpiredMobileContentProps = {
  isiOSDevice: boolean;
};

const ExpiredMobileContent = ({ isiOSDevice }: ExpiredMobileContentProps) => (
  <>
    <MainContent>
      <TitleContainer>
        <Header isMobile />
      </TitleContainer>
      <HeroContainer>
        <CircleContent isExpired />
      </HeroContainer>
    </MainContent>
    <Footer $isExpired>
      <MobileFooterLogoWrapper>
        <TrialLabel>You can still get a 7 day free trial</TrialLabel>
        {isiOSDevice ? (
          <StoreButton
            color={theme.colors.black}
            variant="appStore"
            href={config.appStoreLink}
            isMobile
          />
        ) : (
          <StoreButton
            color={theme.colors.black}
            variant="googlePlay"
            href={config.googlePlayLink}
            isMobile
          />
        )}
      </MobileFooterLogoWrapper>
    </Footer>
  </>
);

const TrialLabel = styled.span`
  margin: 16px 0;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 40px;
`;

const HeroContainer = styled.div`
  margin-top: 42px;
  display: grid;
  justify-content: center;
  font-weight: bold;
`;

export default ExpiredMobileContent;
