import { createRandomId } from 'helpers';
import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const DEFAULT_SIZE = 24;

const DashedCheckMark = ({
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}: Props) => {
  const clipId = createRandomId('unique-checkmark-');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id={clipId}>
          <path
            d="M12-24A12,12,0,0,0,0-12,12,12,0,0,0,12,0,12,12,0,0,0,24-12,12.014,12.014,0,0,0,12-24Zm6.927,8.2L12.082-6.511a1.011,1.011,0,0,1-.676.394,1.011,1.011,0,0,1-.754-.206L5.764-10.231a1,1,0,0,1-.156-1.406,1,1,0,0,1,1.406-.156L11.09-8.532l6.227-8.451a1,1,0,0,1,.927-.441,1,1,0,0,1,.827.608A1,1,0,0,1,18.927-15.8Z"
            fill="#a6c4b2"
          />
        </clipPath>
      </defs>
      <g transform="translate(0 24)">
        <g clipPath={`url(#${clipId})`}>
          <path d="M-5-29H29V5H-5Z" fill="#a6c4b2" />
        </g>
      </g>
    </svg>
  );
};

export default DashedCheckMark;
