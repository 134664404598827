export type DesktopCarouselData = {
  id: number;
  items: {
    firstRow: { id: number; text: string; imageSrc: string }[];
    secondRow: { id: number; text: string; imageSrc: string }[];
  };
};

const APP_IMAGE_PATH = '/images/appForEverything';

export const AppForEverythingCarouselDataDesktop: DesktopCarouselData[] = [
  {
    id: 1,
    items: {
      firstRow: [
        {
          id: 1,
          text: 'Expert podcasts',
          imageSrc: `${APP_IMAGE_PATH}/expert-podcasts.png`,
        },
        {
          id: 2,
          text: 'Weekly meal plans',
          imageSrc: `${APP_IMAGE_PATH}/weekly-meal-plans.png`,
        },
        {
          id: 3,
          text: 'Guided meditation',
          imageSrc: `${APP_IMAGE_PATH}/guided-meditation.png`,
        },
      ],
      secondRow: [
        {
          id: 4,
          text: 'Health plan',
          imageSrc: `${APP_IMAGE_PATH}/health-programme.png`,
        },
        {
          id: 5,
          text: 'Progress trackers',
          imageSrc: `${APP_IMAGE_PATH}/progress-trackers.png`,
        },
        {
          id: 6,
          text: 'Fitness tutorials',
          imageSrc: `${APP_IMAGE_PATH}/fitness-tutorials.png`,
        },
      ],
    },
  },
  {
    id: 2,
    items: {
      firstRow: [
        {
          id: 1,
          text: 'Gut health & allergies',
          imageSrc: `${APP_IMAGE_PATH}/gut-health-&-allergies.png`,
        },
        {
          id: 2,
          text: 'Hormonal wellbeing',
          imageSrc: `${APP_IMAGE_PATH}/hormonal-wellbeing.png`,
        },
        {
          id: 3,
          text: 'Mental strength \n & positivity',
          imageSrc: `${APP_IMAGE_PATH}/mental-strength-&-positivity.png`,
        },
      ],
      secondRow: [
        {
          id: 4,
          text: 'Energy & vitality',
          imageSrc: `${APP_IMAGE_PATH}/energy-&-vitality.png`,
        },
        {
          id: 5,
          text: 'Nutrition & eating habits',
          imageSrc: `${APP_IMAGE_PATH}/nutrition-&-eating-habits.png`,
        },
        {
          id: 6,
          text: 'Sleep, stress & relaxation',
          imageSrc: `${APP_IMAGE_PATH}/sleep-stress-&-relaxation.png`,
        },
      ],
    },
  },
  {
    id: 3,
    items: {
      firstRow: [
        {
          id: 1,
          text: 'Community Q & A',
          imageSrc: `${APP_IMAGE_PATH}/expert-advice.png`,
        },
        {
          id: 2,
          text: 'Achievements & rewards',
          imageSrc: `${APP_IMAGE_PATH}/achievements-&-rewards.png`,
        },
        {
          id: 3,
          text: 'Environmental insights',
          imageSrc: `${APP_IMAGE_PATH}/environmental-insights.png`,
        },
      ],
      secondRow: [
        {
          id: 4,
          text: 'Goal modules',
          imageSrc: `${APP_IMAGE_PATH}/goal-modules.png`,
        },
        {
          id: 5,
          text: 'Symptom checklists',
          imageSrc: `${APP_IMAGE_PATH}/symptom-checklists.png`,
        },
        {
          id: 6,
          text: 'Offline library',
          imageSrc: `${APP_IMAGE_PATH}/offline-library.png`,
        },
      ],
    },
  },
];

export type MobileCarouselData = {
  id: number;
  text: string;
  imageSrc: string;
};

export const AppForEverythingCarouselDataMobile: MobileCarouselData[] = [
  {
    id: 0,
    text: 'Expert podcasts',
    imageSrc: `${APP_IMAGE_PATH}/expert-podcasts.png`,
  },
  {
    id: 1,
    text: 'Health plan',
    imageSrc: `${APP_IMAGE_PATH}/health-programme.png`,
  },
  {
    id: 2,
    text: 'Progress trackers',
    imageSrc: `${APP_IMAGE_PATH}/progress-trackers.png`,
  },
  {
    id: 3,
    text: 'Weekly meal plans',
    imageSrc: `${APP_IMAGE_PATH}/weekly-meal-plans.png`,
  },
  {
    id: 4,
    text: 'Workouts',
    imageSrc: `${APP_IMAGE_PATH}/fitness-tutorials.png`,
  },
  {
    id: 5,
    text: 'Guided meditation',
    imageSrc: `${APP_IMAGE_PATH}/guided-meditation.png`,
  },
  {
    id: 6,
    text: 'Energy & vitality',
    imageSrc: `${APP_IMAGE_PATH}/energy-&-vitality.png`,
  },
  {
    id: 7,
    text: 'Gut health & allergies',
    imageSrc: `${APP_IMAGE_PATH}/gut-health-&-allergies.png`,
  },
  {
    id: 8,
    text: 'Nutrition & eating habits',
    imageSrc: `${APP_IMAGE_PATH}/nutrition-&-eating-habits.png`,
  },
  {
    id: 9,
    text: 'Hormonal wellbeing',
    imageSrc: `${APP_IMAGE_PATH}/hormonal-wellbeing.png`,
  },
  {
    id: 10,
    text: 'Sleep, stress & relaxation',
    imageSrc: `${APP_IMAGE_PATH}/sleep-stress-&-relaxation.png`,
  },
  {
    id: 11,
    text: 'Mental strength \n & positivity',
    imageSrc: `${APP_IMAGE_PATH}/mental-strength-&-positivity.png`,
  },
  {
    id: 12,
    text: 'Goal modules',
    imageSrc: `${APP_IMAGE_PATH}/goal-modules.png`,
  },
  {
    id: 13,
    text: 'Expert advice',
    imageSrc: `${APP_IMAGE_PATH}/expert-advice.png`,
  },
  {
    id: 14,
    text: 'Symptom checklists',
    imageSrc: `${APP_IMAGE_PATH}/symptom-checklists.png`,
  },
  {
    id: 15,
    text: 'Achievements & rewards',
    imageSrc: `${APP_IMAGE_PATH}/achievements-&-rewards.png`,
  },
  {
    id: 16,
    text: 'Offline library',
    imageSrc: `${APP_IMAGE_PATH}/offline-library.png`,
  },
  {
    id: 17,
    text: 'Environmental insights',
    imageSrc: `${APP_IMAGE_PATH}/environmental-insights.png`,
  },
];
