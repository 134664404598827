import React from 'react';

const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <path
      id="Path_3887"
      data-name="Path 3887"
      d="M1140.5,2354a11.5,11.5,0,1,0,11.5,11.5A11.512,11.512,0,0,0,1140.5,2354Zm-.5,5a1,1,0,1,1-1,1A1,1,0,0,1,1140,2359Zm3.5,14h-6a.5.5,0,0,1,0-1h2.5v-8h-1.5a.5.5,0,0,1,0-1h2a.5.5,0,0,1,.5.5v8.5h2.5a.5.5,0,0,1,0,1Z"
      transform="translate(-1129 -2354.004)"
      fill="#414e72"
    />
  </svg>
);

export default InfoIcon;
