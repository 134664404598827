import {
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import WarningIcon from 'components/Icons/WarningIcon';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';
import theme from 'theme/theme';

import Loader from '../Loader';
import OfferCodeForm from '../OfferCodeForm';
import OfferLogo from '../OfferLogo';

// Choose at what point the image opacity becomes 0
// E.G: 60px from the top
const OFFSET_HEIGHT = 60;

const useParallax = (value: MotionValue<number>, distance: number) => {
  return useTransform(value, [0, 0.5], [0, -distance]);
};

type Props = {
  accountName: string;
  logo: string | undefined;
  backgroundImg: string | undefined;
  monthPeriod: string | undefined;
  isLoadingData: boolean;
  isExpired: boolean;
  isShowDepartment: boolean;
  setIsExpired: () => void;
};

const HeroSection = ({
  accountName,
  logo,
  backgroundImg,
  monthPeriod,
  isLoadingData,
  isExpired,
  isShowDepartment,
  setIsExpired,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  const { scrollY, scrollYProgress } = useScroll();

  const yRange = useTransform(scrollY, [360 - OFFSET_HEIGHT, 0], [0, 1]);

  const opacity = useSpring(yRange, { stiffness: 400, damping: 40 });
  const yTranslateHero = useParallax(scrollYProgress, 300);
  const yTranslateBubbles = useParallax(scrollYProgress, 150);

  return (
    <Container ref={containerRef}>
      <Hero>
        <FormWrapper
          style={{
            opacity,
            y: yTranslateHero,
            display: isLoadingData || isLoading ? 'none' : 'block',
          }}
        >
          <Notification>
            {isExpired
              ? 'This offer has expired'
              : `Receive ${monthPeriod} free access!`}
          </Notification>
          {isExpired ? (
            <WarningWrapper>
              <WarningIcon
                width={60}
                height={60}
                color={theme.colors.topicBlue}
              />
            </WarningWrapper>
          ) : (
            <OfferCodeForm
              setPageToExpired={setIsExpired}
              isMobile={isMobile}
              onLoading={setIsLoading}
            />
          )}
          <OfferLogo src={logo} accountName={accountName} />
        </FormWrapper>
        {(isLoadingData || isLoading) && (
          <LoaderWrapper>
            <Loader
              bigCircleSize={isMobile ? 85 : 160}
              smallCircleSize={isMobile ? 63 : 120}
            />
          </LoaderWrapper>
        )}
        <BackgroundWrapper $isSmallForm={!isShowDepartment}>
          {backgroundImg ? (
            <img src={backgroundImg} alt="background" />
          ) : (
            <img src="/images/weight.png" alt="background" />
          )}
        </BackgroundWrapper>
      </Hero>
      <FirstBubble style={{ y: yTranslateBubbles }}>
        <BubbleCircleContainer
          $bottom={15.5}
          $left={-5}
          $width={198}
          $height={198}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </FirstBubble>
      <SecondBubble style={{ y: yTranslateBubbles }}>
        <BubbleCircleContainer
          $top={38}
          $left={13.5}
          $width={71}
          $height={71}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </SecondBubble>
      <DesktopBubble
        style={{
          y: yTranslateBubbles,
          position: 'absolute',
          bottom: '-5%',
          left: '29%',
        }}
      >
        <BubbleCircleContainer
          $bottom={4}
          $left={29}
          $width={98}
          $height={98}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </DesktopBubble>
      <DesktopBubble
        style={{
          y: yTranslateBubbles,
          position: 'absolute',
          bottom: '35.5%',
          right: '29%',
        }}
      >
        <BubbleCircleContainer
          $bottom={35.5}
          $right={29}
          $width={98}
          $height={98}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </DesktopBubble>
      <ThirdBubble style={{ y: yTranslateBubbles }}>
        <BubbleCircleContainer
          $top={29}
          $right={22.5}
          $width={71}
          $height={71}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </ThirdBubble>
      <DesktopBubble
        style={{
          y: yTranslateBubbles,
          position: 'absolute',
          top: '20.4%',
          right: '13.5%',
        }}
      >
        <BubbleCircleContainer
          $top={20.4}
          $right={13.5}
          $width={28}
          $height={28}
          $usePct
        >
          <BubbleCircle />
        </BubbleCircleContainer>
      </DesktopBubble>
    </Container>
  );
};

const DesktopBubble = styled(motion.div)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
const ThirdBubble = styled(motion.div)`
  position: absolute;
  top: 29%;
  right: 22.5%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: 15%;
    right: -40px;

    & > div {
      width: 78px;
      height: 78px;
    }
  }
`;
const SecondBubble = styled(motion.div)`
  position: absolute;
  top: 38%;
  left: 13.5%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: 0;
    top: 18.5%;

    & > div {
      width: 38px;
      height: 38px;
    }
  }
`;
const FirstBubble = styled(motion.div)`
  position: absolute;
  bottom: 15.5%;
  left: -5%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 0;
    left: -100px;
  }
`;

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 57px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 51px;
    margin-bottom: 37px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 200px;
  height: 200px;
`;

const BackgroundWrapper = styled.div<{ $isSmallForm: boolean }>`
  position: absolute;
  bottom: ${({ $isSmallForm }) => ($isSmallForm ? '-34px' : '-100px')};

  img {
    width: 533px;
    height: 349px;
    object-fit: contain;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    bottom: 0;

    img {
      width: 396px;
      height: 259px;
    }
  }
`;

const FormWrapper = styled(motion.div)`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  filter: drop-shadow(0 10px 20px rgb(0 0 0 / 16%));
  padding: 26px 16px;
  height: fit-content;
  width: 700px;
  min-height: 300px;
  position: absolute;
  top: -95px;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 335px;
    padding-top: 31px;
  }
`;

const Notification = styled.p`
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 33px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 35px;
    padding: 0 30px;
    margin-bottom: 19px;
  }
`;

const BubbleCircleContainer = styled.div<{
  $width: number;
  $height: number;
  $bottom?: number;
  $right?: number;
  $top?: number;
  $left?: number;
  $opacity?: number;
  $usePct?: boolean;
}>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  bottom: ${({ $bottom, $usePct }) =>
    $usePct ? `${$bottom}%` : `${$bottom}px`};
  top: ${({ $top, $usePct }) => ($usePct ? `${$top}%` : `${$top}px`)};
  right: ${({ $right, $usePct }) => ($usePct ? `${$right}%` : `${$right}px`)};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: ${({ $left, $usePct }) => ($usePct ? `${$left}%` : `${$left}px`)};
  opacity: ${({ $opacity }) => ($opacity !== undefined ? $opacity : 1)};
  position: absolute;
  border: 1px dashed white;
  border-radius: 50%;
  overflow: hidden;
`;

const BubbleCircle = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  opacity: 0.15;
`;

const Hero = styled.div`
  margin-top: 110px;
  position: relative;
  width: 1460px;
  height: 584px;
  display: flex;
  justify-content: center;
  background-image: url('/images/weightBackground.png');
  background-size: contain;
  background-position: 65% 34px;
  background-repeat: no-repeat;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    background-size: cover;
    background-position: 38% 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 55px;
  }
`;

const Container = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.topicBlue};
  padding-top: 65px;
  clip-path: ellipse(72% 91% at 50% 9%);
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 70px;
    clip-path: ellipse(122% 83% at 50% 9%);
    overflow: hidden;
  }
`;

export default HeroSection;
