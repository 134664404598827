import React from 'react';
import styled, { css, keyframes } from 'styled-components';

enum TypingIndicatorItemPosition {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
}

type TypingIndicatorProps = {
  isMobile?: boolean;
};

const TypingIndicator = ({ isMobile }: TypingIndicatorProps) => (
  <Container $isMobile={isMobile}>
    <TypingIndicatorItem $itemPosition={TypingIndicatorItemPosition.First} />
    <TypingIndicatorItem $itemPosition={TypingIndicatorItemPosition.Second} />
    <TypingIndicatorItem $itemPosition={TypingIndicatorItemPosition.Third} />
  </Container>
);

const Container = styled.div<{ $isMobile?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  height: 25px;
  column-gap: 5px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-left: 20px;
      margin-right: auto;
    `}
`;

const animation = keyframes`
  50% {
    top: -4px;
    opacity: 1;
  }

  100% {
    top: 4px;
    opacity: 0.5;
  }
`;

const TypingIndicatorItem = styled.div<{
  $itemPosition: TypingIndicatorItemPosition;
}>`
  position: relative;
  top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: 0.5;
  animation: ${animation} 0.4s linear 0s alternate;
  ${({ $itemPosition }) =>
    $itemPosition === TypingIndicatorItemPosition.Second &&
    css`
      animation-delay: 0.3s;
    `}
  ${({ $itemPosition }) =>
    $itemPosition === TypingIndicatorItemPosition.Third &&
    css`
      animation-delay: 0.6s;
    `}
`;

export default TypingIndicator;
