import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'components/Icons/Arrow';

import { CAROUSEL_DATA_DESKTOP, CAROUSEL_DATA_MOBILE } from '../constants';

import ShidoIsTheBestAssistantCarousel from './ShidoIsTheBestAssistantCarousel';

type Props = {
  isMobile?: boolean;
  isDesktop?: boolean;
  isHideArrow?: boolean;
  goToNextSection?: () => void;
};

const ShidoIsTheBestAssistantSection = (
  { isMobile = false, isDesktop, isHideArrow, goToNextSection }: Props,
  ref?: React.Ref<HTMLDivElement>
) => (
  <Container ref={ref} $isDesktop={!!isDesktop}>
    <Title $isMobile={isMobile}>What do people say about us?</Title>
    <ShidoIsTheBestAssistantCarousel
      isMobile={isMobile}
      isDesktop={isDesktop}
      slides={isMobile ? CAROUSEL_DATA_DESKTOP : CAROUSEL_DATA_MOBILE}
    />
    {!isHideArrow && (
      <ArrowWrapper data-testid="response-arrow" onClick={goToNextSection}>
        <Arrow />
      </ArrowWrapper>
    )}
  </Container>
);

const ArrowWrapper = styled.div`
  margin: auto;
  margin-top: 40px;
  cursor: pointer;
`;

const Title = styled.div<{ $isMobile?: boolean }>`
  font-size: 30px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 20px;
    `};
  color: ${({ theme }) => theme.colors.primary};

  @media (width <= 600px) {
    font-size: 20px;
  }

  @media (width <= 300px) {
    font-size: 15px;
  }
`;

const Container = styled.div<{ $isDesktop: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  padding: 77px 0 78px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1.5px dashed rgb(216 218 224 / 25500%);
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      padding: 75px 0 78px;
    `};

  @media (width <= 600px) {
    padding: 36px 0 37px;
  }
`;

export default React.forwardRef(ShidoIsTheBestAssistantSection);
