import {
  StyledBreakpointsTheme,
  createStyledBreakpointsTheme,
} from 'styled-breakpoints';

export const primaryTheme = {
  breakpoints: {
    xxs: '320px',
    xs: '375px',
    sm: '426px',
    md: '769px',
    lg: '1025px',
    xl: '1400px',
    xxl: '1920px',
  },
  colors: {
    primary: '#414E72',
    secondary: '#D6A6B0',
    darkBlue: '#4B5474',
    darkBlueV1: '#67718e',
    darkBlueV2: '#69B0C7',
    darkBlueV3: '#58A5D0',
    topicBlue: '#414E71',
    darkRed: '#d4c1c5',
    primRed: '#E6CAD0',
    darkYellow: '#DFDFDA',
    lightYellow: '#F7F7F2',
    red: '#ff0000',
    lightRed: '#D82E5A',
    lightBlue: '#62B0CB',
    lightBlueV1: '#62b1cb',
    black: '#000',
    white: '#fff',
    gray: '#EBEBEB',
    darkGray: '#707070',
    darkGrayV1: '#bcbcbc',
    sierraWhite: '#DEDFDA',
    funtainBlue: '#65B0C9',
    careysPink: '#d7a6b0',
    saltpan: '#F4F8F6',
    vistaWhite: '#fbf4f6',
    springRain: '#A4C5B2',
    doveGray: '#707070',
    cararra: '#edede8',
    sand: '#F7F7F2',
    pinkLight: '#D6A5AF',
    blueBayoux: '#4B5778',
  },
  fonts: {
    light: 'F37GingerLight',
    regular: 'F37GingerRegular',
    bold: 'F37GingerBold',
  },
};

export type PrimaryTheme = typeof primaryTheme & StyledBreakpointsTheme;

const theme: PrimaryTheme = {
  ...primaryTheme,
  ...createStyledBreakpointsTheme(),
};

export default theme;
