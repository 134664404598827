import React, { useState } from 'react';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import Email from '../../chatActions/Email';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

const EMAIL_PROGRESS = 25;

type EmailAddressProps = {
  isMobile?: boolean;
  isEditing: boolean;
  shown: boolean;
  setEmail: (email: string) => void;
  goToNextStage: () => void;
  toggleEditingStatus: () => void;
  togglePasscodeStatus: (value: boolean) => void;
};

const EmailAddress = ({
  isMobile,
  goToNextStage,
  isEditing,
  setEmail,
  shown,
  toggleEditingStatus,
  togglePasscodeStatus,
}: EmailAddressProps) => {
  const { changeProgress } = useChatContext();

  const [isDelayShown, setShowDelay] = useState(false);
  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  const emailHandlerClick = (email: string) => {
    setUserAnswer(email);
    setEmail(email);
    setShowActionContainer(false);

    if (isEditing) {
      goToNextStage();
      toggleEditingStatus();
      togglePasscodeStatus(true);
    } else {
      changeProgress(EMAIL_PROGRESS);
    }
  };

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message="OK, what email address do you sign in with?"
        isMobile={isMobile}
        goToNextStep={() => {
          setShowDelay(true);
        }}
      />
      {isDelayShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {userAnswer && !isEditing && (
        <ChatMessage
          isOutgoing
          message={userAnswer}
          isMobile={isMobile}
          goToNextStep={goToNextStage}
          toggleEditingStatus={toggleEditingStatus}
          lastMessage
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <Email
          isMobile={isMobile}
          goToNextStep={emailHandlerClick}
          value={userAnswer}
          isEditing={isEditing}
        />
      )}
    </StageContainer>
  );
};

export default EmailAddress;
