import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const ANIMATION_MULTIPLIER_MOBILE = 2.5;
const DESKTOP_PIE_SIZE = 16;
const MOBILE_PIE_SIZE = 6;
const DESKTOP_CIRCLE_RADIUS = 8;
const MOBILE_CIRCLE_RADIUS = 3;

type Props = {
  slides: { id: number }[];
  activeIdx: number;
  isMobile?: boolean;
  interval?: number;
  clickHandler: (idx: number) => void;
};

const SlideSelector = ({
  slides,
  activeIdx,
  interval,
  clickHandler,
  isMobile,
}: Props) => (
  <Container>
    {slides.map((slide, i) => (
      <PickedItem
        key={slide.id}
        onClick={() => clickHandler(i)}
        $isMobile={isMobile}
      >
        <Pie
          width={isMobile ? MOBILE_PIE_SIZE : DESKTOP_PIE_SIZE}
          height={isMobile ? MOBILE_PIE_SIZE : DESKTOP_PIE_SIZE}
        >
          <Circle
            $isMobile={isMobile}
            r={isMobile ? MOBILE_CIRCLE_RADIUS : DESKTOP_CIRCLE_RADIUS}
            cx={isMobile ? MOBILE_CIRCLE_RADIUS : DESKTOP_CIRCLE_RADIUS}
            cy={isMobile ? MOBILE_CIRCLE_RADIUS : DESKTOP_CIRCLE_RADIUS}
            $focused={activeIdx === i}
            $interval={interval}
          />
        </Pie>
      </PickedItem>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const PickedItem = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.darkBlueV3};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border: 1px solid ${({ theme }) => theme.colors.darkBlueV3};

      &:not(:last-child) {
        margin-right: 5px;
      }
    `}
`;

const animate = keyframes`
  from {
    stroke-dasharray: 0 52;

    @media (max-width: 450px) {
      stroke-dasharray: 0 314;
    }
  }

  to {
    stroke-dasharray: 52;

    @media (max-width: 450px) {
      stroke-dasharray: 314;
    }
  }
`;

const Circle = styled.circle<{
  $focused: boolean;
  $interval?: number;
  $isMobile?: boolean;
}>`
  fill: none;
  ${({ $focused, $interval, $isMobile }) =>
    $focused &&
    $interval &&
    css`
      stroke-width: 16;
      stroke-dasharray: 0 52;
      animation: ${animate} ${$interval}ms linear;
      stroke: ${({ theme }) => theme.colors.darkBlueV3};
      ${$isMobile &&
      css`
        stroke-width: 6;
        stroke-dasharray: 0 314;
        animation: ${animate} ${$interval * ANIMATION_MULTIPLIER_MOBILE}ms
          linear;
        stroke: ${({ theme }) => theme.colors.darkBlueV3};
      `}
    `};
  ${({ theme, $focused, $interval, $isMobile }) =>
    $focused &&
    !$interval &&
    css`
      fill: ${theme.colors.darkBlueV3};
      ${$isMobile &&
      css`
        fill: ${theme.colors.darkBlueV3};
      `}
    `}
`;

const Pie = styled.svg`
  transform: rotate(-90deg);
  background: transparent;
  border-radius: 50%;
`;

export default SlideSelector;
