import React from 'react';
import styled from 'styled-components';

import Chat from '../chat/Chat';
import ChatHeader from '../chat/ChatHeader';

const DesktopChat = () => (
  <Container>
    <ModalWindow>
      <ChatHeader />
      <Chat />
    </ModalWindow>
    <BackgroundImage src="/images/desktop_background_3.svg" />
  </Container>
);

const Container = styled.div`
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.cararra};
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 120%;
  height: auto;
  left: -10%;
  top: 25%;
  z-index: 5;
`;

const ModalWindow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  height: 85vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 90px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 20px 20px 0;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);
`;

export default DesktopChat;
