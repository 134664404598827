import { AxiosError } from 'axios';
import config from 'config';
import { axiosClient } from 'lib';

type OfferCodePayload = {
  publicCode: string;
  offerCode: string;
};

type OfferCodeResponse = {
  succeeded: boolean;
  message: string;
};

type FailedOfferCodeData = { payload: OfferCodeResponse };

export const validateOfferCode = async ({
  publicCode,
  offerCode,
}: OfferCodePayload): Promise<OfferCodeResponse | null> => {
  try {
    const response = await axiosClient.post(
      `/api/v${config.apiVersion}/CorporateOffers/Validate`,
      {
        publicCode,
        code: offerCode,
      }
    );

    return response.data.payload;
  } catch (error) {
    return (
      (error as AxiosError<FailedOfferCodeData>).response?.data.payload || null
    );
  }
};

export const getPublicInfo = async (account: string) => {
  const response = await axiosClient.get(
    `/api/v${config.apiVersion}/CorporateOffers/${account}/PublicInfo`
  );

  return response.data.payload.data;
};
