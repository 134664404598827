import React from 'react';
import styled, { css } from 'styled-components';

import Logo from './Logo';

type HeaderProps = {
  isMobile?: boolean;
};

const Header = ({ isMobile }: HeaderProps) => (
  <Header.Container $isMobile={isMobile}>
    <Logo />
    <Header.Title $isMobile={isMobile}>Welcome to Shido!</Header.Title>
    <Header.Content $isMobile={isMobile}>
      Helping you be your best & achieve optimal health.
    </Header.Content>
  </Header.Container>
);

Header.Container = styled.header<{ $isMobile?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-top: 0;
    `};
`;

Header.Title = styled.h1<{ $isMobile?: boolean }>`
  padding-top: 20px;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      color: white;
      font-size: 20px;
      line-height: 24px;
    `};
`;

Header.Content = styled.span<{ $isMobile?: boolean }>`
  padding-top: 8px;
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      color: white;
      font-size: 14px;
      line-height: 21px;
    `};
`;

export default Header;
