import styled from 'styled-components';

export const DesktopContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cararra};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 135px 0;
`;

export const DesktopContent = styled.div`
  width: 900px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);
  margin: 0 20px;
  height: fit-content;
`;
