import React, { useState } from 'react';
import styled from 'styled-components';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import PasscodeAction from '../../chatActions/Passcode';
import PasscodeMobileAction from '../../chatActions/PasscodeMobile';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

const PASSCODE_VALUE = '* * * * * *';
const PASSCODE_PROGRESS = 25;

type PasscodeProps = {
  isMobile?: boolean;
  shown: boolean;
  isEditing: boolean;
  goToNextStage: (password: string) => void;
  toggleEditingStatus: () => void;
};

const Passcode = ({
  isMobile,
  isEditing,
  goToNextStage,
  shown,
  toggleEditingStatus,
}: PasscodeProps) => {
  const { changeProgress } = useChatContext();

  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [isDelayBeforeActionShown, setShowDelayBeforeAction] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message="Thanks, now enter your 6 digit passcode"
        isMobile={isMobile}
        goToNextStep={() => {
          setShowDelayBeforeAction(true);
        }}
      />
      {isDelayBeforeActionShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {userAnswer && (
        <ChatMessage
          isOutgoing
          message={<UserPasscode>{PASSCODE_VALUE}</UserPasscode>}
          isMobile={isMobile}
          goToNextStep={() => goToNextStage(userAnswer)}
          lastMessage
          toggleEditingStatus={toggleEditingStatus}
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <>
          {isMobile ? (
            <PasscodeMobileAction
              goToNextStep={(password: string) => {
                setUserAnswer(password);
                setShowActionContainer(false);
                if (isEditing) {
                  goToNextStage(password);
                  toggleEditingStatus();
                } else {
                  changeProgress(PASSCODE_PROGRESS);
                }
              }}
            />
          ) : (
            <PasscodeAction
              label="Enter your 6 digit passcode"
              value={userAnswer}
              isEditing={isEditing}
              goToNextStep={(password: string) => {
                setUserAnswer(password);
                setShowActionContainer(false);
                if (isEditing) {
                  goToNextStage(password);
                  toggleEditingStatus();
                } else {
                  changeProgress(PASSCODE_PROGRESS);
                }
              }}
            />
          )}
        </>
      )}
    </StageContainer>
  );
};

const UserPasscode = styled.span`
  font-size: 20px !important;
  position: relative;
  top: 5px;
`;

export default Passcode;
