import React from 'react';

type CloseIconProps = {
  color: string;
};

const CloseIcon = ({ color }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 20 20"
  >
    <path
      data-name="Path 4133"
      d="m2.35.402 7.648 7.487L17.65.402a1.4 1.4 0 0 1 1.8-.127l.131.11.01.01a1.327 1.327 0 0 1 .032 1.877l-7.471 7.73 7.428 7.744a1.332 1.332 0 0 1-.029 1.873 1.346 1.346 0 0 1-1.883 0l-7.67-7.517-7.665 7.514a1.346 1.346 0 0 1-1.883 0 1.332 1.332 0 0 1-.03-1.873l7.428-7.741L.373 2.269A1.327 1.327 0 0 1 .405.392l.01-.01a1.4 1.4 0 0 1 1.935.02Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
