import React from 'react';

import theme from 'theme/theme';

type CheckboxIconProps = {
  isChecked?: boolean;
};

const CheckboxIcon = ({ isChecked }: CheckboxIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_4103"
          data-name="Path 4103"
          d="M5.128-20h9.744a5.8,5.8,0,0,1,3.081.534,3.635,3.635,0,0,1,1.512,1.512A5.8,5.8,0,0,1,20-14.872v9.744a5.8,5.8,0,0,1-.534,3.081A3.635,3.635,0,0,1,17.954-.534,5.8,5.8,0,0,1,14.872,0H5.128A5.8,5.8,0,0,1,2.046-.534,3.635,3.635,0,0,1,.534-2.046,5.8,5.8,0,0,1,0-5.128v-9.744a5.8,5.8,0,0,1,.534-3.081,3.635,3.635,0,0,1,1.512-1.512A5.8,5.8,0,0,1,5.128-20Z"
          fill="none"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath id="check-clip-path-2">
        <path
          id="Path_4102"
          data-name="Path 4102"
          d="M-30,1744H345V-493H-30Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          id="Path_4106"
          data-name="Path 4106"
          d="M15.223-14.73a.944.944,0,0,1,1.322,0,.934.934,0,0,1,0,1.32h0L8.832-4.779a.932.932,0,0,1-.213.329.943.943,0,0,1-1.062.18.959.959,0,0,1-.308-.2A.936.936,0,0,1,7.142-4.6L3.274-8.41A.932.932,0,0,1,3-9.07a.932.932,0,0,1,.274-.66.945.945,0,0,1,1.323,0L7.875-6.5Z"
          fill="none"
          clipRule="evenodd"
        />
      </clipPath>
    </defs>
    <g id="Group_10925" data-name="Group 10925" transform="translate(0 20)">
      <g id="Group_4317" data-name="Group 4317" clipPath="url(#clip-path)">
        <g
          id="Group_4316"
          data-name="Group 4316"
          clipPath="url(#check-clip-path-2)"
        >
          <path
            id="Path_4101"
            data-name="Path 4101"
            d="M-5-25H25V5H-5Z"
            fill="#62b0cb"
          />
        </g>
      </g>
      <g id="Group_4319" data-name="Group 4319" clipPath="url(#clip-path-3)">
        <g
          id="Group_4318"
          data-name="Group 4318"
          clipPath="url(#check-clip-path-2)"
        >
          <path
            id="Path_4104"
            data-name="Path 4104"
            d="M-2-20H21.818V.82H-2Z"
            fill={isChecked ? 'white' : theme.colors.funtainBlue}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CheckboxIcon;
