import { capitalizeString } from 'helpers';
import React from 'react';
import styled from 'styled-components';

import DashedLine from 'components/DashedLine';

import { Images } from '../types';

import ConfettiAnimation from './ConfettiAnimation';
import ExpiredBlock from './ExpiredBlock';
import Footer from './Footer';
import Header from './Header';
import {
  DesktopContent,
  DesktopContentContainer,
} from './styledElements/StyledDesktop';

type Props = {
  userName: string;
  images: Images;
};

const DesktopSuccess = ({ userName, images }: Props) => (
  <DesktopContentContainer>
    <Header />
    <DesktopContent>
      <MainContent $background={images.background}>
        <Gratitude>
          Thanks {capitalizeString(userName)}, you’re all set!
        </Gratitude>
        <Suggestion>Open the shido app on your mobile & sign in</Suggestion>

        <ConfettiWrapper>
          <ConfettiAnimation height={540} />
        </ConfettiWrapper>
      </MainContent>
      <ExpiredBlock />
      <DashedLine style={{ paddingTop: '20px', paddingBottom: '70px' }} />
      <Footer logo={images.logo} />
    </DesktopContent>
  </DesktopContentContainer>
);

const MainContent = styled.div<{ $background?: string }>`
  overflow: hidden;
  height: 552px;
  padding-top: 78px;
  background:
    center 105% no-repeat url('/images/desktop_background_2.svg'),
    ${({ theme }) => theme.colors.primary} 50% 90% / contain no-repeat
      ${({ $background }) =>
        $background
          ? `url(${$background})`
          : "url('/images/default_desktop_background_image.png')"};
`;

const Gratitude = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: 400;
`;

const Suggestion = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 30px;
  font-weight: 400;
  margin-top: 10px;
`;

const ConfettiWrapper = styled.div`
  margin-left: -220px;
  margin-bottom: 100px;
  transform: scale(0.6);
`;

export default DesktopSuccess;
