import React from 'react';
import styled, { keyframes } from 'styled-components';

import { DashedCircleIcon } from 'components/Icons/DashedCircleIcon';
import theme from 'theme/theme';

interface CircleContentProps {
  isExpired?: boolean;
}

const CircleContent = ({ isExpired }: CircleContentProps) => (
  <CircleContent.Container>
    <CircleContent.Circle>
      {isExpired ? (
        <CircleContent.BoldText>EXPIRED</CircleContent.BoldText>
      ) : (
        <CircleContent.ContentWrapper>
          <CircleContent.Text>Free</CircleContent.Text>
          <CircleContent.BoldText>1 MONTH</CircleContent.BoldText>
          <CircleContent.Text>Trial</CircleContent.Text>
        </CircleContent.ContentWrapper>
      )}
    </CircleContent.Circle>
    <DashedCircleAnimate>
      <DashedCircleIcon width={142} height={142} color={theme.colors.white} />
    </DashedCircleAnimate>
  </CircleContent.Container>
);

CircleContent.Container = styled.div`
  width: 145px;
  height: 145px;
  display: grid;
  justify-content: center;
  align-items: center;
  background: center no-repeat;
  position: relative;
`;

CircleContent.Circle = styled.div`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background: white;
  line-height: 21px;
  display: grid;
  justify-content: center;
  align-items: center;
`;

CircleContent.ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

CircleContent.BoldText = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

CircleContent.Text = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const DashedCircleAnimate = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 3s linear infinite;
`;

export default CircleContent;
