import React from 'react';

const StressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
    viewBox="0 0 24 22"
  >
    <g data-name="Group 4280">
      <g data-name="Group 4178">
        <path
          data-name="Path 3947"
          d="m11.843 22 .112-11.013H9.484L10.602 0h3.911L12.63 8.82l2.634.237Z"
          fill="#69b0c7"
        />
      </g>
      <g data-name="Group 4179">
        <path
          data-name="Path 3948"
          d="m14.472 21.703 3.937-7.4-1.681-.873 4.612-7.025 2.661 1.381-4.373 5.291 1.71 1.089Z"
          fill="#69b0c7"
        />
      </g>
      <g data-name="Group 4180">
        <path
          data-name="Path 3949"
          d="m9.359 21.776-4.2-7.251-1.629.966L.002 7.864l2.578-1.53 2.183 6.508 1.828-.877Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default StressIcon;
