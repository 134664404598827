import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import {
  applyOfferCodeToNewAccount,
  registerWithFullCredentials,
} from 'modules/corporateOffer/services';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import { useCorporateContext } from '../../../CorporateContext';
import PasscodeAction from '../../chatActions/Passcode';
import PasscodeMobileAction from '../../chatActions/PasscodeMobile';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION } from './CreateAccount';

const GO_TO_SUCCESS_DELAY = 2000;
const PASSCODE_VALUE = '* * * * * *';

type PasscodeProps = {
  isMobile?: boolean;
  shown: boolean;
  email: string;
  pincode: string;
  isEditing: boolean;
  togglePassCodeEditingStatus: () => void;
};

const Passcode = ({
  isMobile,
  shown,
  email,
  pincode,
  isEditing,
  togglePassCodeEditingStatus,
}: PasscodeProps) => {
  const { changeProgress } = useChatContext();
  const { onChatSuccess, codeValue, department } = useCorporateContext();

  const [isPasscodeShown, setShowPasscode] = useState(false);
  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [isLastMessageShown, setShowLastMessage] = useState(false);
  const [isDelayBeforeActionShown, setShowDelayBeforeAction] = useState(false);
  const [isLastDelayShown, setShowLastDelay] = useState(false);

  const [userAnswer, setUserAnswer] = useState('');

  const [isSuccessRequest, setIsSuccessRequest] = useState(false);

  const { mutate, data } = useMutation(registerWithFullCredentials, {
    onSuccess: () => {
      if (isEditing) {
        togglePassCodeEditingStatus();
      }

      changeProgress(CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION);
    },
    onError: () => {
      if (isEditing) {
        togglePassCodeEditingStatus();
      }
    },
  });

  const sendRegisterRequest = useCallback(
    (passcode: string) => {
      setUserAnswer(passcode);
      mutate({
        passcode,
        termsAndConditions: true,
        email,
        pincode,
        department: department ? department.displayNameBE : department,
      });
      setShowActionContainer(false);
    },
    [department, email, mutate, pincode]
  );

  useEffect(() => {
    if (userAnswer && data) {
      setIsSuccessRequest(true);

      applyOfferCodeToNewAccount(userAnswer, email, codeValue);
    }
  }, [codeValue, data, email, userAnswer]);

  useEffect(() => {
    const finishRegistration = setTimeout(() => {
      if (isLastMessageShown) {
        onChatSuccess();
      }
    }, GO_TO_SUCCESS_DELAY);

    return () => clearTimeout(finishRegistration);
  }, [isLastDelayShown, isLastMessageShown, onChatSuccess]);

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message="Just about done!"
        isMobile={isMobile}
        goToNextStep={() => {
          setShowPasscode(true);
          changeProgress(CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION);
        }}
      />
      {isPasscodeShown && (
        <ChatMessage
          message="So you can sign in to the Shido app, please choose a 6 digit passcode"
          isMobile={isMobile}
          goToNextStep={() => setShowDelayBeforeAction(true)}
        />
      )}
      {isDelayBeforeActionShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {userAnswer && isSuccessRequest && (
        <ChatMessage
          isOutgoing
          message={<UserPasscode>{PASSCODE_VALUE}</UserPasscode>}
          isMobile={isMobile}
          goToNextStep={() => setShowLastMessage(true)}
          lastMessage
          doNotShowEdit
        />
      )}
      {isLastMessageShown && (
        <ChatMessage
          message="Creating your account …"
          isMobile={isMobile}
          goToNextStep={() => {
            setShowLastDelay(true);
          }}
        />
      )}
      {isLastDelayShown && <ChatMessage isMobile={isMobile} />}
      {(isActionContainerShown || isEditing) && (
        <>
          {isMobile ? (
            <PasscodeMobileAction goToNextStep={sendRegisterRequest} />
          ) : (
            <PasscodeAction
              label="Choose a 6 digit passcode"
              goToNextStep={sendRegisterRequest}
            />
          )}
        </>
      )}
    </StageContainer>
  );
};

const UserPasscode = styled.span`
  font-size: 20px !important;
  position: relative;
  top: 5px;
`;

export default Passcode;
