import config from 'config';
import React from 'react';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import StoreButton from 'components/StoreButton';
import { isAndroid, isIOSDevice, isMobile } from 'helpers/getDeviceInfo';
import theme from 'theme/theme';

const Header = () => (
  <Container>
    <SubContainer $isDesktop={!isMobile}>
      <ShidoIconWrapper>
        <LogoIcon width={61} height={37} />
        <ShidoTitle>
          <ShidoText>shido</ShidoText>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
      {!isMobile && (
        <StoreButtonsWrapper>
          {!isAndroid && (
            <StoreButton
              color={theme.colors.primary}
              variant="appStore"
              href={config.appStoreLink}
            />
          )}
          {!isIOSDevice && (
            <StoreButton
              color={theme.colors.primary}
              variant="googlePlay"
              href={config.googlePlayLink}
            />
          )}
        </StoreButtonsWrapper>
      )}
    </SubContainer>
  </Container>
);

const SubContainer = styled.div<{ $isDesktop?: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  justify-content: ${({ $isDesktop }) =>
    $isDesktop ? 'space-between' : 'center'};
  ${({ $isDesktop: isDesktop }) =>
    isDesktop &&
    css`
      border-bottom: 1px solid rgb(216 218 224 / 25500%);
    `};

  @media (width <= 600px) {
    position: relative;
    right: 7px;
    top: 1px;
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: -14px;

  @media (width <= 600px) {
    right: -12px;
  }
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -3px;

  @media (width <= 600px) {
    right: -3px;
    top: -5px;
  }
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 38px;
  font-weight: 700;

  @media (width <= 600px) {
    font-size: 40px;
  }
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  @media (width <= 600px) {
    column-gap: 14px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div`
  z-index: 2;
  position: relative;
  max-width: 1420px;
  margin: auto;
  box-sizing: border-box;
  height: 123px;
  padding: 42px 10px 0 12px;

  @media (width <= 600px) {
    height: 80px;
  }
`;

const StoreButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-template-columns: auto auto;
  column-gap: 20px;
  position: relative;
  top: -3px;
  right: -1px;
`;

export default Header;
