import * as React from 'react';

export const BetterHealthIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="225"
    height="31"
    viewBox="0 0 225 31"
  >
    <g
      id="Group_6261"
      data-name="Group 6261"
      transform="translate(-196 -223.421)"
    >
      <g
        id="Group_6258"
        data-name="Group 6258"
        transform="translate(196 226.199)"
      >
        <g id="Group_6252" data-name="Group 6252">
          <path
            id="Path_6259"
            data-name="Path 6259"
            d="M196,253.4h3.486v-20.91H196V229h11.146c6.74,0,9.748,3.263,9.748,7.161,0,3.3-2.077,5.465-4.958,6.4,3.6,1.017,5.764,3.093,5.764,6.611,0,4.491-3.645,7.711-10.046,7.711H196Zm11.263-12.447c4.05,0,6.163-1.853,6.163-4.653,0-2.716-2.155-4.309-6.035-4.309h-3.92v8.962Zm.829,12.447c3.846,0,6.179-1.813,6.179-4.707,0-3.111-2.506-4.753-6.74-4.753H203.47V253.4Z"
            transform="translate(-196 -229)"
            fill="#c42c45"
          />
        </g>
        <g
          id="Group_6253"
          data-name="Group 6253"
          transform="translate(24.424 9.271)"
        >
          <path
            id="Path_6260"
            data-name="Path 6260"
            d="M248.858,258.274c.51,3.476,3.052,5.173,6.614,5.173a12.691,12.691,0,0,0,6.486-1.952l-.3,3.349a12.679,12.679,0,0,1-6.74,1.739c-5.893,0-9.878-3.817-9.878-9.412,0-5.131,3.517-9.538,9.071-9.538,5.639,0,8.605,4.027,8.605,8.69a13.463,13.463,0,0,1-.168,1.951Zm0-2.988h10.216c-.169-2.925-1.949-4.917-5.044-4.917C251.572,250.369,249.324,251.979,248.858,255.286Z"
            transform="translate(-245.043 -247.633)"
            fill="#c42c45"
          />
        </g>
        <g
          id="Group_6254"
          data-name="Group 6254"
          transform="translate(42.829 3.782)"
        >
          <path
            id="Path_6261"
            data-name="Path 6261"
            d="M303.651,256.849a2.079,2.079,0,0,1-1.986-.848,3.133,3.133,0,0,1-.243-1.333V236.9l-3.486,1.413v3.962h-8.964V236.6l-3.486,1.482v4.2H282v2.987h3.486v10.512a6.623,6.623,0,0,0,.526,2.671,4.317,4.317,0,0,0,4.217,2.287,8.076,8.076,0,0,0,3.932-1.06l.366-3.263a8.173,8.173,0,0,1-3.325,1.019,2.045,2.045,0,0,1-1.986-.891,3.45,3.45,0,0,1-.243-1.4V245.26h8.964v10.015a6.071,6.071,0,0,0,.527,2.544,4.363,4.363,0,0,0,4.214,2.18,8.393,8.393,0,0,0,3.932-1.01l.364-3.11A8.47,8.47,0,0,1,303.651,256.849Z"
            transform="translate(-282 -236.595)"
            fill="#c42c45"
          />
        </g>
        <g
          id="Group_6255"
          data-name="Group 6255"
          transform="translate(69.758 9.271)"
        >
          <path
            id="Path_6262"
            data-name="Path 6262"
            d="M339.887,258.274c.508,3.476,3.053,5.173,6.613,5.173a12.684,12.684,0,0,0,6.485-1.952l-.3,3.349a12.678,12.678,0,0,1-6.741,1.739c-5.891,0-9.877-3.817-9.877-9.412,0-5.131,3.519-9.538,9.073-9.538,5.637,0,8.6,4.027,8.6,8.69a13.323,13.323,0,0,1-.169,1.951Zm0-2.988H350.1c-.169-2.925-1.949-4.917-5.043-4.917C342.6,250.369,340.353,251.979,339.887,255.286Z"
            transform="translate(-336.072 -247.633)"
            fill="#c42c45"
          />
        </g>
        <g
          id="Group_6256"
          data-name="Group 6256"
          transform="translate(89.643 9.224)"
        >
          <path
            id="Path_6263"
            data-name="Path 6263"
            d="M391.048,250.209a2.439,2.439,0,0,1-2.333,2.459A2.585,2.585,0,0,1,386.3,251.1c-1.358.423-3.825,3.687-3.825,3.687v8.42h3.486v2.988H376v-2.988h2.988v-12.45H376v-2.988h5.976v4.264c1.823-2.5,3.94-4.494,6.06-4.494A2.685,2.685,0,0,1,391.048,250.209Z"
            transform="translate(-376 -247.538)"
            fill="#c42c45"
          />
        </g>
        <g
          id="Group_6257"
          data-name="Group 6257"
          transform="translate(63.08 6.723)"
        >
          <path
            id="Path_6264"
            data-name="Path 6264"
            d="M328.086,247.084a9.282,9.282,0,0,1-5.423,1.376,8.09,8.09,0,0,1,8.657-5.941A9.283,9.283,0,0,1,328.086,247.084Z"
            transform="translate(-322.663 -242.504)"
            fill="#c42c45"
          />
        </g>
      </g>
      <g
        id="Group_6260"
        data-name="Group 6260"
        transform="translate(301.735 223.421)"
      >
        <g id="Group_6259" data-name="Group 6259">
          <path
            id="Path_6265"
            data-name="Path 6265"
            d="M408.935,253.767h3.347V233.024h-3.347v-3.387H419.6v3.387h-3.513v8.448H430.27v-8.448h-3.513v-3.387h10.668v3.387h-3.347v20.743h3.347v3.388H426.756v-3.388h3.513v-8.991H416.089v8.991H419.6v3.388H408.935Z"
            transform="translate(-408.935 -226.541)"
            fill="#537e89"
          />
          <path
            id="Path_6266"
            data-name="Path 6266"
            d="M475.448,258.533c.5,3.43,3.012,5.1,6.526,5.1a12.526,12.526,0,0,0,6.4-1.924l-.293,3.3a12.513,12.513,0,0,1-6.652,1.715c-5.815,0-9.747-3.765-9.747-9.287,0-5.061,3.472-9.412,8.952-9.412,5.564,0,8.492,3.974,8.492,8.576a13.187,13.187,0,0,1-.167,1.924Zm0-2.677h10.082c-.167-2.887-1.924-4.853-4.978-4.853C478.125,251,475.908,252.593,475.448,255.856Z"
            transform="translate(-440.434 -235.784)"
            fill="#537e89"
          />
          <path
            id="Path_6267"
            data-name="Path 6267"
            d="M512.171,261.587c0-3.3,2.928-5.438,7.07-5.438a19.431,19.431,0,0,1,4.936.711v-2.05a3.919,3.919,0,0,0-.794-2.51,5.628,5.628,0,0,0-6.108-.837,2.693,2.693,0,0,1,.209,1,2.012,2.012,0,0,1-2.092,2.049,2.043,2.043,0,0,1-2.134-2.133c0-2.134,2.343-4.351,6.945-4.351,2.8,0,4.853.837,6.066,2.259a6.269,6.269,0,0,1,1.38,4.141v8.952h2.8V266.4H524.26v-2.175a9.357,9.357,0,0,1-6.191,2.51C514.765,266.733,512.171,264.85,512.171,261.587Zm12.006.293v-2.928a16.811,16.811,0,0,0-4.392-.628c-2.552,0-3.974,1.213-3.974,2.97,0,1.632,1.213,2.719,3.3,2.719A7.814,7.814,0,0,0,524.177,261.88Z"
            transform="translate(-460.758 -235.784)"
            fill="#537e89"
          />
          <path
            id="Path_6268"
            data-name="Path 6268"
            d="M552.49,251.023h2.928V226.516H552.49v-3.095h6.526v27.6H561.9v3.011H552.49Z"
            transform="translate(-480.997 -223.421)"
            fill="#537e89"
          />
          <path
            id="Path_6269"
            data-name="Path 6269"
            d="M578.933,259.161a6.3,6.3,0,0,1-.543-2.636V246.15H575v-3.1h3.389v-4.142l3.6-1.464v5.606h5.48v3.1h-5.48V255.9a3.256,3.256,0,0,0,.251,1.381,2.144,2.144,0,0,0,2.05.879,8.717,8.717,0,0,0,3.43-1l-.377,3.221a8.633,8.633,0,0,1-4.057,1.046C581.276,261.42,579.728,260.708,578.933,259.161Z"
            transform="translate(-492.297 -230.471)"
            fill="#537e89"
          />
          <path
            id="Path_6270"
            data-name="Path 6270"
            d="M605.577,226.516v-3.095h6.4v15.6c1.715-1.84,3.848-3.346,6.4-3.346A5.417,5.417,0,0,1,622.9,237.6a6.76,6.76,0,0,1,1.213,4.14v9.284h2.8v3.011h-9.078v-3.011h2.677v-8.49a3.948,3.948,0,0,0-.711-2.551,3.011,3.011,0,0,0-2.468-1,7.856,7.856,0,0,0-5.355,2.718v9.326h2.677v3.011h-9.078v-3.011h2.8V226.516Z"
            transform="translate(-507.646 -223.421)"
            fill="#537e89"
          />
        </g>
      </g>
    </g>
  </svg>
);
