export const convertDate = (date: string) => {
  const [, month, dayNum, year] = new Date(date).toDateString().split(' ');
  const intDayNum = Number(dayNum);

  if (intDayNum > 3 && intDayNum < 21) {
    return `${intDayNum}th ${month} ${year}`;
  }

  let dateSuffix = 'th';

  switch (intDayNum % 10) {
    case 1:
      dateSuffix = 'st';
      break;
    case 2:
      dateSuffix = 'nd';
      break;
    case 3:
      dateSuffix = 'rd';
      break;
    default:
      dateSuffix = 'th';
  }

  return `${intDayNum}${dateSuffix} ${month} ${year}`;
};
