import React, { ReactNode, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import EditIcon from 'components/Icons/EditIcon';

import { ADDITIONAL_MESSAGE_DELAY, MESSAGE_DELAY } from '../constants';

import TypingIndicator from './../components/TypingIndicator';
import { useChatContext } from './ChatContext';

type ChatMessageProps = {
  withLongDelay?: boolean;
  message?: string | ReactNode;
  isOutgoing?: boolean;
  isMobile?: boolean;
  lastMessage?: boolean;
  doNotShowEdit?: boolean;
  goToNextStep?: () => void;
  toggleEditingStatus?: () => void;
};

const ChatMessage = ({
  message,
  isOutgoing,
  isMobile,
  doNotShowEdit,
  withLongDelay,
  goToNextStep,
  lastMessage,
  toggleEditingStatus,
}: ChatMessageProps) => {
  const { scrollChat } = useChatContext();

  const [isMessageDisplayed, setIsMessageDisplayed] = useState(false);
  const [goToNextStepComplete, setGoToNextStepComplete] = useState(false);

  const messageDelay = withLongDelay
    ? MESSAGE_DELAY + ADDITIONAL_MESSAGE_DELAY
    : MESSAGE_DELAY;

  useEffect(() => {
    const actionWithDelay = setTimeout(() => {
      if (!isMessageDisplayed && !lastMessage && !goToNextStepComplete) {
        setIsMessageDisplayed(true);
        setGoToNextStepComplete(true);
        goToNextStep && goToNextStep();
      }
    }, messageDelay);

    if ((isMessageDisplayed || lastMessage) && !goToNextStepComplete) {
      setGoToNextStepComplete(true);
      goToNextStep && goToNextStep();
    }

    scrollChat();

    return () => clearTimeout(actionWithDelay);
  }, [
    goToNextStepComplete,
    goToNextStep,
    isMessageDisplayed,
    lastMessage,
    scrollChat,
    withLongDelay,
    messageDelay,
  ]);

  if (isMessageDisplayed || isOutgoing) {
    if (!message) {
      return <WithoutContent />;
    }

    return (
      <Message $isMobile={isMobile} $isOutgoing={isOutgoing}>
        {message}
        {isOutgoing && !doNotShowEdit && (
          <EditWrapper onClick={toggleEditingStatus}>
            <EditIcon />
          </EditWrapper>
        )}
      </Message>
    );
  }

  return <TypingIndicator isMobile={isMobile} />;
};

const animation = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const Message = styled.div<{ $isOutgoing?: boolean; $isMobile?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  animation: ${animation} 0.3s linear 0s alternate;
  width: fit-content;
  padding: ${({ $isMobile }) =>
    $isMobile ? '10px 10px 10px 16px' : '10px 10px 10px 15px'};
  border: 1px solid
    ${({ theme, $isOutgoing }) =>
      $isOutgoing ? theme.colors.springRain : theme.colors.secondary};
  border-radius: 5px;
  z-index: 25;
  margin: 10px 0;
  line-height: 20px;
  font-weight: 300;
  font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '16px')};
  color: ${({ theme }) => theme.colors.primary};
  ${({ $isOutgoing, $isMobile }) =>
    $isOutgoing
      ? css`
          margin-left: auto;
          margin-right: ${() => ($isMobile ? '10px' : '0')};
          border-image: url('/images/border_2.svg') 20 fill / 15px / 1px stretch;
          padding: ${() =>
            $isMobile ? '10px 16px 10px 10px' : '10px 15px 10px 10px'};
        `
      : css`
          margin-left: ${() => ($isMobile ? '10px' : '0')};
          margin-right: auto;
          border-image: url('/images/border_1.svg') 20 fill / 15px / 1px stretch;
        `};

  span {
    font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '16px')};
  }
`;

const WithoutContent = styled.div`
  height: 0;
`;

const EditWrapper = styled.div`
  position: relative;
  margin-left: 20px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -11px;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.3;
    cursor: default;
  }
`;

export default ChatMessage;
