import React, {
  createContext,
  RefObject,
  useState,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';

type ChatContext = {
  progress: number;
  changeProgress: (value: number, clearProgress?: boolean) => void;
  setNewChatRef: (value: RefObject<HTMLDivElement>) => void;
  scrollChat: () => void;
};

const initialValue: ChatContext = {
  progress: 0,
  changeProgress: () => null,
  setNewChatRef: () => null,
  scrollChat: () => null,
};

const ChatContext = createContext<ChatContext>(initialValue);

const ChatContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [chatRef, setChatRef] = useState<RefObject<HTMLDivElement> | null>(
    null
  );

  const changeProgress = useCallback(
    (progressValue: number, clearProgress?: boolean) => {
      if (clearProgress) {
        setProgress(progressValue);

        return;
      }
      setProgress(progress + progressValue);
    },
    [progress]
  );

  const setNewChatRef = (value: RefObject<HTMLDivElement>) => {
    setChatRef(value);
  };

  const scrollChat = () => {
    if (chatRef && chatRef.current && chatRef.current.scrollTo) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: 'auto',
      });
    }
  };

  return (
    <ChatContext.Provider
      value={{
        progress,
        changeProgress,
        setNewChatRef,
        scrollChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;

export const useChatContext = () => useContext(ChatContext);
