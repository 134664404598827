import * as React from 'react';

type AppleIconProps = {
  prefixId: string;
  className?: string;
};

export const AppleIcon = ({ prefixId, className }: AppleIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={22.941}
    viewBox="0 0 17.643 22.941"
    fill="#FFF"
    className={className}
  >
    <defs>
      <clipPath id={`${prefixId}-apple-a`}>
        <path
          data-name="Path 4310"
          d="M15.58-10.71a4.82 4.82 0 011.94-3.87.5.5 0 00.189-.345.5.5 0 00-.119-.375A5.16 5.16 0 0014-17c-1.69-.16-3.3 1-4.16 1s-2.26-1-3.66-1a5.34 5.34 0 00-4.51 2.76C-.25-10.9 1.18-6 3-3.26 3.91-1.94 5-.45 6.44-.5s1.9-.89 3.57-.89 2.14.89 3.6.86S16.08-1.88 17-3.2a12.23 12.23 0 001.37-2.67.5.5 0 00-.23-.61 4.76 4.76 0 01-2.56-4.23z"
        />
      </clipPath>
      <clipPath id={`${prefixId}-apple-b`}>
        <path
          data-name="Path 4312"
          d="M13-19.83a5.24 5.24 0 001.22-3.09.5.5 0 00-.176-.4.5.5 0 00-.424-.109 5.35 5.35 0 00-2.81 1.67 5.07 5.07 0 00-1.25 3 .5.5 0 00.16.379.5.5 0 00.39.131A4.48 4.48 0 0013-19.83z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 4521">
      <g
        data-name="Group 4519"
        clipPath={`url(#${prefixId}-apple-a)`}
        transform="translate(-.757 23.44)"
      >
        <path data-name="Path 4309" d="M-4.243-22.015H23.4V4.5H-4.243z" />
      </g>
      <g
        data-name="Group 4520"
        clipPath={`url(#${prefixId}-apple-b)`}
        transform="translate(-.757 23.44)"
      >
        <path data-name="Path 4311" d="M4.56-28.44h14.66v15.193H4.56z" />
      </g>
    </g>
  </svg>
);
