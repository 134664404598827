import React from 'react';
import styled from 'styled-components';

import Chat from '../chat/Chat';
import Header from '../chat/ChatHeader';

const MobileChat = () => (
  <Container>
    <Header isMobile />
    <Chat isMobile />
  </Container>
);

const Container = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #f7f7f2;
`;

export default MobileChat;
