import config from 'config';
import { axiosClient } from 'lib';

import { AffiliatePlatform } from '../types';

type AffiliateOfferPayload = {
  publicCode: string;
  emailVerificationCode: string | null;
  platform: AffiliatePlatform;
};

type AffiliateOfferCodeResponse = {
  id: string;
  code: string;
  url: string;
};

export const getAffiliateStoreOfferCode = async ({
  publicCode,
  emailVerificationCode,
  platform,
}: AffiliateOfferPayload): Promise<AffiliateOfferCodeResponse> => {
  const response = await axiosClient.put<{
    payload: { data: AffiliateOfferCodeResponse };
  }>(`/api/v${config.nextApiVersion}/affiliatecodes/${publicCode}`, {
    platform,
    emailVerificationCode,
  });

  return response.data.payload.data;
};

export const getAffiliateEmail = async ({
  emailVerificationCode,
}: {
  emailVerificationCode: string;
}): Promise<{ email: string }> => {
  const response = await axiosClient.get<{
    payload: { data: { email: string } };
  }>(
    `/api/v${config.nextApiVersion}/affiliatecodes/email?code=${emailVerificationCode}`
  );

  return response.data.payload.data;
};
