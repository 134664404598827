import config from 'config';
import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import SubmitButton from 'components/SubmitButton';
import ShidoDescription from 'modules/corporateOffer/components/ShidoDescription';
import { ShidoLink } from 'modules/corporateOffer/components/styledElements/StyledShidoLink';

export const InvitationIntro = () => (
  <>
    <MainContent>
      <ShidoLinkWrapper>
        <ShidoLink href={config.shidoURL}>
          <LogoIcon width={70} height={44} />
          <RegisterSymbol>®</RegisterSymbol>
        </ShidoLink>
      </ShidoLinkWrapper>
      <InvitationTitle>Lou has invited you to Shido!</InvitationTitle>
      <InvitationDescription>
        Sign up now and get 1 month free! No obligation, cancel anytime.
      </InvitationDescription>
      <SubmitButtonWrapper>
        <SubmitButton
          buttonSize="sm"
          fontSize="md"
          borderSize="sm"
          text="continue"
        />
      </SubmitButtonWrapper>
    </MainContent>
    <ShidoDescription isMobile />
  </>
);

const MainContent = styled.div<{ $background?: string }>`
  height: 470px;
  padding-top: 40px;
  background:
    center 156% no-repeat url('/images/mobile_background.png'),
    ${({ theme }) => theme.colors.primary} 50% 85% / contain no-repeat
      ${({ $background }) =>
        $background
          ? `url(${$background})`
          : "url('/images/desktop_background_2.png')"};
`;

const InvitationDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  width: 240px;
  margin: 0 auto;
  line-height: 23px;
`;

const InvitationTitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 14px;
`;

const SubmitButtonWrapper = styled.div`
  position: absolute;
  top: 440px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const RegisterSymbol = styled.div`
  position: absolute;
  top: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  left: 73px;
`;

export const ShidoLinkWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto 37px;
`;
