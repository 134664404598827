import { find } from 'lodash';

const OFFER_TERMS = [
  {
    id: '0',
    displayName: '1 month trial',
    BEformat: 'OneMonth',
    displayDownloadPageName: '1 month',
  },
  {
    id: '1',
    displayName: '3 months full access',
    BEformat: 'ThreeMonths',
    displayDownloadPageName: '3 months',
  },
  {
    id: '2',
    displayName: '6 months full access',
    BEformat: 'SixMonths',
    displayDownloadPageName: '6 months',
  },
  {
    id: '3',
    displayName: '12 months full access',
    BEformat: 'TwelveMonths',
    displayDownloadPageName: '12 months',
  },
];

export const selectMonthPeriod = (offerTerm: string) =>
  find(OFFER_TERMS, {
    BEformat: offerTerm,
  })?.displayDownloadPageName;
