import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isMobile } from 'helpers/getDeviceInfo';
import useCreateRefsForClick from 'modules/affiliateOffer/hooks/useCreateRefsForClick';
import Loader, {
  LoaderWrapper,
} from 'modules/corporateOffer/components/Loader';
import customLogoPromotion from 'modules/promotionOffer/images/customLogoPromotion.png';
import { AppRoutes } from 'router/models';

import DetailsSection from '../../modules/promotionOffer/components/DetailsSection';
import Footer from '../../modules/promotionOffer/components/Footer';
import IntroSection from '../../modules/promotionOffer/components/IntroSection';
import ShidoIsTheBestAssistantSection from '../../modules/promotionOffer/components/ShidoIsTheBestAssistantSection';
import { PROMO_COLOR_PICKER } from '../../modules/promotionOffer/constants';
import { convertPromoOffer } from '../../modules/promotionOffer/helpers';
import { useGetPromotionOffer } from '../../modules/promotionOffer/hooks';

const PROMO_PERIODS = ['7day', '1month', '3month'];
const PROMO_CODE_URL_PERIODS = ['sevendays', 'onemonth', 'threemonths'];

export const Promotion = () => {
  const { code, promotionTerm } = useParams<{
    promotionTerm: string;
    code: string;
  }>();

  const navigate = useNavigate();

  const {
    handleRefClick,
    refs: [detailsRef, carouselRef, footerRef],
  } = useCreateRefsForClick();

  const { data: offerData } = useGetPromotionOffer(convertPromoOffer(code));

  useEffect(() => {
    if (promotionTerm && !PROMO_PERIODS.includes(promotionTerm)) {
      navigate(AppRoutes.Base);

      return;
    }

    PROMO_CODE_URL_PERIODS.forEach((codeTerm, i) => {
      if (
        promotionTerm === PROMO_PERIODS[i] &&
        code &&
        code.split('-')[0].toLowerCase() !== codeTerm
      ) {
        navigate(AppRoutes.Base);
      }
    });
  }, [code, promotionTerm, navigate]);

  if (!offerData) {
    return (
      <LoaderWrapper>
        <Loader bigCircleSize={85} smallCircleSize={63} />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <Container $color={PROMO_COLOR_PICKER[offerData.backgroundColour].strong}>
        <IntroSection
          qrCodeUrl={offerData.qrCodeUrl}
          chosenColor={PROMO_COLOR_PICKER[offerData.backgroundColour]}
          trialPeriod={offerData.promotionTerm.term}
          title={offerData.title}
          goToNextSection={handleRefClick(detailsRef)}
        />
        <DetailsSection
          goToNextSection={handleRefClick(carouselRef)}
          subtitle={offerData.subtitle}
          body={offerData.bodyText}
          image={offerData.imageUrl || customLogoPromotion}
          ref={detailsRef}
          isMobile={isMobile}
        />
        <ShidoIsTheBestAssistantSection
          ref={carouselRef}
          goToNextSection={handleRefClick(footerRef)}
          isMobile={isMobile}
        />
        <Footer
          qrCodeUrl={offerData.qrCodeUrl}
          trialPeriod={offerData.promotionTerm.term}
          isMobile={isMobile}
          ref={footerRef}
        />
        <GraphBackground
          $color={PROMO_COLOR_PICKER[offerData.backgroundColour].light}
        />
      </Container>
    </Wrapper>
  );
};

const GraphBackground = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/layout.svg') no-repeat 50% 50%;
  mask-position: bottom -1070px right 50%;

  @media (width <= 600px) {
    background-color: ${({ $color }) => $color};
    mask: url('/images/mobileLayout.svg') no-repeat 50% 50%;
    mask-position: bottom -76px right 50%;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div<{ $color: string }>`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;
