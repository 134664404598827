export type ListDataItem = {
  id: string;
  displayName: string;
  displayNameBE: string;
};

export const transformDepartments = (obj: Record<string, string>) => {
  const arrayListData: ListDataItem[] = [];

  for (const item in obj) {
    arrayListData.push({
      id: String(arrayListData.length),
      displayName: obj[item],
      displayNameBE: item,
    });
  }

  return arrayListData;
};
