import React from 'react';

const BrainHealthIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_56491"
      data-name="Group 56491"
      transform="translate(-1264.182 -1264.182)"
    >
      <g
        id="Group_637"
        data-name="Group 637"
        transform="translate(1271.519 1271.519)"
      >
        <path
          id="Path_646"
          data-name="Path 646"
          d="M2024.075,2028.739a4.667,4.667,0,1,1,3.778-1.93.766.766,0,1,1-1.241-.9,3.131,3.131,0,1,0-2.538,1.3.766.766,0,0,1,0,1.532Z"
          transform="translate(-2019.412 -2019.412)"
          fill="#69b0c7"
        />
      </g>
      <g
        id="Group_638"
        data-name="Group 638"
        transform="translate(1267.827 1267.827)"
      >
        <path
          id="Path_647"
          data-name="Path 647"
          d="M1647.317,1655.672a8.325,8.325,0,0,1-4.672-1.427,8.378,8.378,0,0,1-3.026-3.676,8.348,8.348,0,0,1,1.147-8.439.766.766,0,0,1,1.2.952,6.75,6.75,0,0,0-1.473,4.235,6.814,6.814,0,1,0,4.836-6.529.766.766,0,1,1-.446-1.466,8.357,8.357,0,1,1,2.432,16.35Z"
          transform="translate(-1638.962 -1638.962)"
          fill="#69b0c7"
        />
      </g>
      <g
        id="Group_639"
        data-name="Group 639"
        transform="translate(1264.182 1264.182)"
      >
        <path
          id="Path_648"
          data-name="Path 648"
          d="M1276.182,1288.182a12.092,12.092,0,0,1-2.417-.244,11.938,11.938,0,0,1-4.292-1.806,12.03,12.03,0,0,1-4.347-5.279,11.936,11.936,0,0,1-.7-2.253,12.109,12.109,0,0,1,0-4.835,11.944,11.944,0,0,1,1.806-4.292,12.031,12.031,0,0,1,5.279-4.347,11.935,11.935,0,0,1,2.254-.7,12.1,12.1,0,0,1,5.127.064,11.947,11.947,0,0,1,4.7,2.252,12.082,12.082,0,0,1,1.851,1.807.767.767,0,1,1-1.183.976,10.42,10.42,0,1,0,1.95,3.647.767.767,0,0,1,1.469-.44,11.993,11.993,0,0,1-11.5,15.451Z"
          transform="translate(-1264.182 -1264.182)"
          fill="#69b0c7"
        />
      </g>
      <g
        id="Group_640"
        data-name="Group 640"
        transform="translate(1268.206 1275.416)"
      >
        <path
          id="Path_649"
          data-name="Path 649"
          d="M1682.613,2422.572h-3.308a.766.766,0,1,1,0-1.532h3.308a.766.766,0,1,1,0,1.532Z"
          transform="translate(-1678.539 -2421.039)"
          fill="#69b0c7"
        />
      </g>
      <g
        id="Group_641"
        data-name="Group 641"
        transform="translate(1275.416 1283.013)"
      >
        <path
          id="Path_650"
          data-name="Path 650"
          d="M2421.805,3208.161a.766.766,0,0,1-.766-.766v-3.513a.766.766,0,1,1,1.532,0v3.513A.766.766,0,0,1,2421.805,3208.161Z"
          transform="translate(-2421.039 -3203.115)"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default BrainHealthIcon;
