import config from 'config';
import { axiosClient } from 'lib';
import { AppleAuthResponse } from 'react-apple-signin-auth';

import { AuthResponse } from '../types';

type WebRegistrationPayload = {
  firstName: string;
  email: string;
};

export const registerNameAndEmail = async ({
  firstName,
  email,
}: WebRegistrationPayload) => {
  const response = await axiosClient.post(
    `/api/v${config.apiVersion}/Registration/web`,
    { firstName, email }
  );

  return response.data.payload;
};

type CompleteWebRegistrationPayload = {
  passcode: string;
  pincode: string;
  email: string;
  termsAndConditions: boolean;
  department: string | null;
};

export const registerWithFullCredentials = async ({
  passcode,
  pincode,
  termsAndConditions,
  email,
  department,
}: CompleteWebRegistrationPayload) => {
  const response = await axiosClient.post(
    `/api/v${config.apiVersion}/Registration/web/complete`,
    {
      passcode,
      pincode,
      termsAndConditions,
      email,
      department,
    }
  );

  return response.data.payload;
};

type ValidatePinCodePayload = {
  pincode: string;
  email: string;
};

export const checkValidPinCode = async ({
  pincode,
  email,
}: ValidatePinCodePayload) => {
  const response = await axiosClient.post(
    `/api/v${config.apiVersion}/Registration/web/validation/pincode`,
    { pincode, email }
  );

  return response.data.payload;
};

export const googleAuthRequest = async (
  accessToken: string
): Promise<AuthResponse> => {
  const response = await axiosClient.post<{ payload: AuthResponse }>(
    `/api/v6/Registration/google`,
    { accessToken }
  );

  return response.data.payload;
};

export type AppleAuthPayload = AppleAuthResponse & { redirectUri: string };

export const appleAuthRequest = async (
  data: AppleAuthPayload
): Promise<AuthResponse> => {
  const response = await axiosClient.post<{ payload: AuthResponse }>(
    `/api/v6/Registration/apple`,
    data
  );

  return response.data.payload;
};
