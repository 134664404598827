import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryUrl = () => {
  const { search } = useLocation();

  const values = queryString.parse(search, {
    decode: false,
  });

  return useMemo(() => values, [values]);
};
