import { codeFormKeyDownHandler } from 'helpers';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import SubmitIcon from 'components/Icons/SubmitIcon';
import SubmitButton from 'components/SubmitButton';
import { CodeStatus } from 'modules/corporateOffer/types';
import theme from 'theme/theme';

import ActionWarning from '../../components/ActionWarning';
import { SubmitButtonContainer } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsColContainer,
  FormCodeContainer,
  FormInput,
  FormInputCode,
  FormLabel,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

export const TEMPLATE_INPUT_ID = 'TEMPLATE_INPUT_ID';
export const FIELDS = ['1', '2', '3', '4', '5', '6'];
export const VALID_CODE = '000000';

type Props = {
  errorText: string;
  goToNextStep: (value: string) => void;
  clickHandler?: () => void;
};

const PinCodeAction = ({ errorText, goToNextStep, clickHandler }: Props) => {
  const { scrollChat } = useChatContext();

  const {
    register,
    watch,
    setValue,
    setFocus,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [active, setActive] = useState(false);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState<CodeStatus>(
    CodeStatus.Pending
  );

  const allFieldsValues = watch();

  useEffect(() => {
    if (errorText) {
      setVerifyCodeStatus(CodeStatus.Invalid);
    }
  }, [errorText]);

  const changeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const isNumber = !isNaN(Number(value));

    if ((isNumber || !value) && value !== ' ') {
      setValue(name, value);
    }

    if (value && isNumber && value !== ' ') {
      if (index !== FIELDS.length - 1) {
        setFocus(FIELDS[index + 1]);
      } else {
        e.target.blur();
      }
    }

    if (verifyCodeStatus === CodeStatus.Invalid) {
      setVerifyCodeStatus(CodeStatus.Pending);
    }
  };

  const requestCondition = useMemo(() => {
    return FIELDS.every(
      (field) =>
        !isNaN(Number(allFieldsValues[field])) &&
        allFieldsValues[field] !== '' &&
        verifyCodeStatus !== CodeStatus.Valid
    );
  }, [verifyCodeStatus, allFieldsValues]);

  const formSubmit = () => {
    if (requestCondition) {
      const code = FIELDS.map((field) => watch()[field]).join('');

      goToNextStep(code);
    }
  };

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  return (
    <>
      <Warning
        clickHandler={clickHandler}
        text={
          errorText
            ? errorText
            : 'Didn’t receive the PIN? Please allow 60 seconds for the email to arrive'
        }
        actionText={errorText ? '' : 'REQUEST A NEW PIN'}
      />
      <ChatActionsColContainer>
        <FormCodeContainer
          $isActive={active}
          onSubmit={handleSubmit(formSubmit)}
        >
          <FormLabel
            htmlFor={TEMPLATE_INPUT_ID}
            $active={active}
            $error={verifyCodeStatus === CodeStatus.Invalid}
          >
            Enter your PIN
          </FormLabel>
          {!active && (
            <FormInput
              id={TEMPLATE_INPUT_ID}
              type="email"
              {...register(TEMPLATE_INPUT_ID, {
                required: false,
              })}
              onFocus={() => {
                setActive(true);
              }}
              $error={false}
            />
          )}
          {active && (
            <>
              {FIELDS.map((field, i) => (
                <FormInputCode
                  data-testid="pincode-offer-value"
                  key={field}
                  id={field}
                  disabled={verifyCodeStatus === CodeStatus.Valid}
                  type="text"
                  autoComplete="off"
                  {...register(field, {
                    required: false,
                  })}
                  onKeyDown={(e) =>
                    codeFormKeyDownHandler(
                      e,
                      i,
                      setFocus,
                      FIELDS,
                      allFieldsValues[i + 1]
                    )
                  }
                  $verificationCodeState={verifyCodeStatus}
                  maxLength={1}
                  onSelect={(e) => e.currentTarget.select()}
                  value={
                    !isNaN(allFieldsValues[field]) ? allFieldsValues[field] : ''
                  }
                  autoFocus={i === 0}
                  onChange={(e) => changeInputHandler(e, i)}
                />
              ))}
            </>
          )}
          <SubmitButtonContainer>
            <ActionButton
              iconColor={
                !active || requestCondition
                  ? theme.colors.funtainBlue
                  : theme.colors.gray
              }
              backColor="none"
              borderColor="none"
              borderSize="sm"
              buttonSize="sm"
              disabled={!!errors[TEMPLATE_INPUT_ID] || !active}
              icon={SubmitIcon}
              isTypeSubmit
            />
          </SubmitButtonContainer>
        </FormCodeContainer>
        <SubmitButton
          buttonSize="md"
          borderSize="md"
          text="Next"
          fontSize="md"
          clickHandler={() => formSubmit()}
          isDisabled={!!errors[TEMPLATE_INPUT_ID] || !active}
        />
      </ChatActionsColContainer>
    </>
  );
};

const Warning = styled(ActionWarning)`
  position: static;
  transform: none;
`;

export default memo(PinCodeAction);
