import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

const Footer = ({ logo }: { logo?: string }) => {
  const [visible, setVisible] = useState(true);

  return (
    <ContentFooter>
      {logo && visible && (
        <>
          <Header>BROUGHT TO YOU BY</Header>
          <ReactSVG
            fallback={() => <img src={logo} alt="logo" />}
            src={logo}
            onError={() => setVisible(false)}
          />
        </>
      )}
    </ContentFooter>
  );
};

const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 70px;
  min-height: 48px;

  img,
  svg {
    object-fit: contain;
    margin-top: 10px;
    max-width: 200px;
    height: 60px;
  }
`;

const Header = styled.p`
  font-size: 12px;
  text-align: center;
  font-weight: 700;
`;

export default Footer;
