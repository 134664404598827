import React from 'react';

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <defs>
      <clipPath id="edit-clip-path">
        <path
          id="Path_263"
          data-name="Path 263"
          d="M0-7.71V-5H2.708l7.987-7.987L7.987-15.7Zm12.789-7.373a.719.719,0,0,0,0-1.018l-1.69-1.69a.719.719,0,0,0-1.018,0L8.759-16.47l2.708,2.708Z"
          transform="translate(0 18.003)"
          fill="#414e72"
        />
      </clipPath>
    </defs>
    <g id="Group_10908" data-name="Group 10908" transform="translate(0 18.003)">
      <g
        id="Group_276"
        data-name="Group 276"
        transform="translate(0 -18.003)"
        clipPath="url(#edit-clip-path)"
      >
        <path
          id="Path_262"
          data-name="Path 262"
          d="M-5-23H15.221V-2.781H-5Z"
          transform="translate(1.389 19.392)"
          fill="#414e72"
        />
      </g>
    </g>
  </svg>
);

export default EditIcon;
