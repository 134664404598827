import React from 'react';
import Confetti from 'react-confetti';

type ConfettiAnimationProps = {
  height: number;
};

const ConfettiAnimation = ({ height }: ConfettiAnimationProps) => {
  return (
    <Confetti
      width={2000}
      height={height}
      friction={0.94}
      confettiSource={{ y: 0, x: 0, w: 3000, h: 900 }}
      wind={-0.5}
      gravity={0.4}
      run
      numberOfPieces={250}
    />
  );
};

export default React.memo(ConfettiAnimation);
