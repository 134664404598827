import config from 'config';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import LogoWithRights from 'components/Icons/LogoWithRights';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

import StoreButtons from './StoreButtons';

const LOGO_WIDTH = 70;
const LOGO_HEIGHT = 44;
const CURR_YEAR = new Date().getFullYear();

type Props = {
  withGetAppSection?: boolean;
};

const WebsiteFooter = ({ withGetAppSection }: Props) => {
  const theme = useTheme();

  const { name, platform } = getDeviceInfo();
  const isDesktop = platform !== DeviceType.Mobile;

  return (
    <Container>
      {isDesktop && !!withGetAppSection && (
        <GetAppContainer>
          <GetAppTitle>Get the app</GetAppTitle>
          <StoresContainer>
            <StoreButtons />
          </StoresContainer>
        </GetAppContainer>
      )}
      <Content>
        <LogoWrapper>
          {isDesktop ? (
            <LogoIcon
              width={LOGO_WIDTH}
              height={LOGO_HEIGHT}
              fill={theme.colors.white}
            />
          ) : (
            <LogoWithRights color={theme.colors.white} />
          )}
        </LogoWrapper>
        {isDesktop ? (
          <>
            <DashedLine />
            <NavbarElements>
              <CompanyMark>
                Copyright © {CURR_YEAR} Shido Group Ltd.
              </CompanyMark>
              <FooterLink
                href={`${config.shidoWebsiteVersion}/contact`}
                target="_blank"
                rel="noreferrer"
              >
                Contact us.
              </FooterLink>
              <FooterLink
                href={`${config.shidoWebsiteVersion}/support`}
                target="_blank"
                rel="noreferrer"
              >
                Support.
              </FooterLink>
              <FooterLink
                href={`${config.shidoWebsiteVersion}/employers`}
                target="_blank"
                rel="noreferrer"
              >
                For employers.
              </FooterLink>
              <FooterLink
                href={`${config.shidoWebsiteVersion}/privacy-policy`}
                target="_blank"
                rel="noreferrer"
              >
                Privacy policy.
              </FooterLink>
              <FooterLink
                href={`${config.shidoWebsiteVersion}/terms-and-conditions`}
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </FooterLink>
            </NavbarElements>
          </>
        ) : (
          <>
            <Inscription>© {CURR_YEAR} Shido Group Ltd.</Inscription>
            <StoreButtons name={name} platform={platform} />
          </>
        )}
      </Content>
    </Container>
  );
};

const GetAppContainer = styled.div`
  width: 100%;
  padding: 70px 0 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blueBayoux};
`;

const StoresContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 19px;
`;

const GetAppTitle = styled.h3`
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 36.5px;
  color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  max-width: 1920px;
  margin: 0 auto;
`;

const Content = styled.article`
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 195px;
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 51px 0 100px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 71px;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 38px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 9px;
    padding-left: 15px;
    margin-bottom: 17px;
  }
`;

const DashedLine = styled.div`
  width: 1400px;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, white 50%);
  background-size: 8px 100%;
  opacity: 0.4;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 900px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100vw;
  }
`;

const NavbarElements = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 22px;
  justify-content: center;
  margin: 37px 0 28px;
`;

const CompanyMark = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
`;

const FooterLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

const Inscription = styled.p`
  font-size: 14px;
  font-weight: 400px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 26px;
`;

export default WebsiteFooter;
