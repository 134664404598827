import { redirectToMobileStore } from 'helpers';
import { useGetStoreButtonInfo } from 'hooks';
import { find } from 'lodash';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import theme from 'theme/theme';

import { PROMOTION_TERMS } from '../constants';

import { QrCodeContainer, QrCodeContainerInfo } from './IntroSection';
import MobileButton from './MobileButton';

const MOBILE_ICON_WIDTH = 48;
const MOBILE_ICON_HEIGHT = 29;

const DESKTOP_ICON_WIDTH = 70;
const DESKTOP_ICON_HEIGHT = 44;

type Props = {
  qrCodeUrl: string;
  isMobile: boolean;
  trialPeriod: string;
};

const Footer = (
  { isMobile, trialPeriod, qrCodeUrl }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const { variant } = useGetStoreButtonInfo();
  const trialTerm = find(PROMOTION_TERMS, {
    findBy: trialPeriod,
  })?.promoDisplay;

  return (
    <Container ref={ref}>
      <FreeTrial>Start a free {trialTerm} trial!</FreeTrial>
      <Subtitle>
        {isMobile
          ? 'Install the app to continue'
          : 'Scan the code with your mobile device to get the app!'}
      </Subtitle>
      {!isMobile && (
        <StyledQr>
          <QrCodeContainerInfo>
            <ReactSVG src={qrCodeUrl} />
          </QrCodeContainerInfo>
        </StyledQr>
      )}

      {isMobile && (
        <ButtonWrapper>
          <MobileButton
            color={theme.colors.funtainBlue}
            variant={variant}
            onClick={redirectToMobileStore}
            isMobile
          />
        </ButtonWrapper>
      )}

      <ShidoIconWrapper $isMobile={isMobile}>
        <ShidoTitle>
          <LogoIcon
            width={isMobile ? MOBILE_ICON_WIDTH : DESKTOP_ICON_WIDTH}
            height={isMobile ? MOBILE_ICON_HEIGHT : DESKTOP_ICON_HEIGHT}
          />
          <ShidoMark $isMobile={isMobile}>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
    </Container>
  );
};

const ButtonWrapper = styled.div`
  width: 158px;
  margin: 38px auto 32px;
  position: relative;
  z-index: 10;
`;

const Subtitle = styled.div`
  margin-top: 17px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  position: relative;

  @media (width <= 600px) {
    font-size: 14px;
    margin-top: 34px;
    right: -2px;
    position: relative;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 3;
  padding-top: 92px;

  @media (width <= 600px) {
    padding-top: 37px;
  }
`;

const ShidoMark = styled.div<{ $isMobile?: boolean }>`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: -2px;
  right: -21px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      right: -18px;
    `};
`;

const ShidoTitle = styled.div`
  position: relative;
`;

const ShidoIconWrapper = styled.div<{ $isMobile?: boolean }>`
  margin: 40px auto 162px;
  width: 70px;
  position: relative;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 41px auto 162px;
      width: 49px;
    `};

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledQr = styled(QrCodeContainer)`
  margin-top: 38px;
  position: relative;
`;

const FreeTrial = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  position: relative;

  @media (width <= 600px) {
    font-size: 20px;
  }
`;

export default React.forwardRef(Footer);
