import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

import Arrow from 'components/Icons/Arrow';

type Props = {
  image: string;
  body: string;
  subtitle: string;
  isDesktop?: boolean;
  isMobile?: boolean;
  goToNextSection: () => void;
};

const DetailsSection = (
  { image, body, subtitle, isMobile, goToNextSection }: Props,
  ref: React.Ref<HTMLDivElement>
) => (
  <Container $isMobile={isMobile} ref={ref}>
    <SubContainer $isMobile={isMobile}>
      <InfoContainer>
        <ParagraphWrapper $isMobile={isMobile}>
          <FirstParagraph $isMobile={isMobile}>{subtitle}</FirstParagraph>
          <SecondParagraph $isMobile={isMobile}>{body}</SecondParagraph>
        </ParagraphWrapper>
        <ImageWrapper $isMobile={isMobile}>
          <ReactSVG
            fallback={() => <img src={image} alt="promo-image" />}
            src={image}
          />
        </ImageWrapper>
      </InfoContainer>
      <ArrowWrapper data-testid="details-arrow" onClick={goToNextSection}>
        <Arrow />
      </ArrowWrapper>
    </SubContainer>
  </Container>
);

const ImageWrapper = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  width: 540px;
  height: 400px;

  @media (width <= 600px) {
    width: 100%;
    margin-top: 50px;
    height: 265px;
  }

  img,
  svg {
    position: absolute;
    height: 510px;
    object-fit: contain;
    top: -40px;
    right: -58px;
    width: 660px;

    @media (width <= 1510px) {
      right: 0;
    }

    @media (width <= 1380px) {
      height: 412px;
      width: 515px;
    }

    @media (width <= 1185px) {
      height: 412px;
      width: 465px;
      right: 0;
    }

    @media (width <= 1000px) {
      height: 445px;
      width: 100%;
    }

    ${({ $isMobile }) =>
      $isMobile &&
      css`
        @media (width <= 900px) {
          top: 0;
          width: 100%;
          right: 0;
          height: 410px;
        }
      `};

    @media (width <= 600px) {
      width: 100%;
      height: 300px;
      top: -40px;
    }

    @media (width <= 480px) {
      width: 100%;
      height: 322px;
      top: -55px;
    }
  }
`;

const ArrowWrapper = styled.div`
  z-index: 10;
  cursor: pointer;
  text-align: center;
  margin: 20px auto 0;
`;

const ParagraphWrapper = styled.div<{ $isMobile?: boolean }>`
  max-width: 535px;
  min-height: 400px;
  word-break: break-word;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 900px) {
        min-height: 235px;
      }
    `};

  @media (width <= 600px) {
    min-height: 216px;
  }
`;

const FirstParagraph = styled.div<{ $isMobile?: boolean }>`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  line-height: 50px;
  position: relative;
  top: -9px;
  left: 0;

  @media (width <= 1130px) {
    font-size: 25px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 900px) {
        font-size: 20px;
        text-align: center;
        max-width: 262px;
        line-height: 29px;
        margin: -2px auto 0;
        position: relative;
        left: -1px;
      }
    `};
`;

const SecondParagraph = styled.div<{ $isMobile?: boolean }>`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 45px;
  font-weight: 300;
  line-height: 50px;
  position: relative;
  top: 0;
  padding-right: 12px;
  left: 0;

  @media (width <= 1130px) {
    font-size: 25px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 900px) {
        font-size: 14px;
        font-weight: 300;
        max-width: 320px;
        line-height: 25px;
        text-align: center;
        margin-top: 16px;
        padding-right: 0;
      }
    `};
`;

const InfoContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @media (width <= 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SubContainer = styled.div<{ $isMobile?: boolean }>`
  border-bottom: 1.5px dashed rgb(216 218 224 / 25500%);
  padding-bottom: 81px;

  @media (width <= 600px) {
    padding-bottom: 39px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-bottom: none;
    `};
`;

const Container = styled.div<{ $isMobile?: boolean }>`
  max-width: 1420px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  margin: 36px auto 0;
  margin: auto;
  padding-top: 36px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-top: 44px;
      border-bottom: 1.5px dashed rgb(216 218 224 / 25500%);
    `};
`;

export default React.forwardRef(DetailsSection);
