import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import ActionButton from 'components/ActionButton';
import VolumeDown from 'components/Icons/VolumeDown';
import VolumeUpIcon from 'components/Icons/VolumeUpIcon';
import theme from 'theme/theme';

const VOLUME_INTERVAL = 0.05;

enum AdjustVolume {
  Up = 'UP',
  Down = 'DOWN',
}

type VolumeControlProps = {
  isOpenVolume?: boolean;
  volume: number;
  setVolume: (value: number) => void;
  isMobile?: boolean;
};

const VolumeControl = ({ volume, setVolume, isMobile }: VolumeControlProps) => {
  const volumePercentage = useMemo(() => volume * 100, [volume]);

  const adjustVolume = useCallback(
    (adjustVolume: AdjustVolume) => {
      const value =
        adjustVolume === AdjustVolume.Down
          ? Number((volume - VOLUME_INTERVAL).toFixed(2))
          : Number((volume + VOLUME_INTERVAL).toFixed(2));
      const verificationCondition =
        adjustVolume === AdjustVolume.Down ? volume !== 0 : volume !== 1;

      if (verificationCondition) {
        setVolume(value);
        localStorage.setItem('volume', JSON.stringify(value));
      }
    },
    [setVolume, volume]
  );

  return (
    <Wrapper>
      <ActionButton
        iconColor={theme.colors.funtainBlue}
        backColor={theme.colors.white}
        borderColor={theme.colors.white}
        borderSize="sm"
        clickHandler={() => adjustVolume(AdjustVolume.Down)}
        buttonSize="sm"
        icon={VolumeDown}
      />
      <VolumeFullState
        $isMobile={isMobile}
        $volumeOpen
        $volumePercentage={volumePercentage}
      >
        <input
          type="range"
          min="0"
          max="1"
          step="0.05"
          value={volume}
          onChange={(e) => {
            const value = Number.parseFloat(e.target.value);

            setVolume(value);
            localStorage.setItem('volume', JSON.stringify(value));
          }}
        />
      </VolumeFullState>
      <ActionButton
        iconColor={theme.colors.funtainBlue}
        backColor={theme.colors.white}
        borderColor={theme.colors.white}
        borderSize="sm"
        clickHandler={() => adjustVolume(AdjustVolume.Up)}
        buttonSize="sm"
        icon={VolumeUpIcon}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  column-gap: 5px;
  background-color: white;
  align-items: center;
  height: fit-content;
  border: 2px solid ${({ theme }) => theme.colors.darkYellow};
  border-radius: 25px;
  width: 100%;
`;

const trackStyling = css`
  height: 6px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlueV2};
  cursor: pointer;
  border-radius: 3px;
`;

const thumbStyling = css`
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.darkBlueV2};
  margin-top: -8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  cursor: pointer;
`;

const VolumeFullState = styled.div<{
  $volumeOpen: boolean;
  $volumePercentage: number;
  $isMobile?: boolean;
}>`
  display: flex;
  width: ${({ $volumeOpen }) => ($volumeOpen ? '100%' : 0)};
  overflow: hidden;

  input {
    width: ${({ $isMobile }) => ($isMobile ? '100%' : 'calc(100% - 40px)')};
    height: 34px;
    appearance: none;
    margin: 0 auto;

    &::-webkit-slider-runnable-track {
      ${trackStyling};
      background-image: ${({ $volumePercentage, theme }) =>
        `linear-gradient(90deg, ${theme.colors.white} ${$volumePercentage}%, ${theme.colors.darkBlueV2} ${$volumePercentage}%)`};
    }

    &::-moz-range-track {
      ${trackStyling};
      background-image: ${({ $volumePercentage, theme }) =>
        `linear-gradient(90deg, ${theme.colors.white} ${$volumePercentage}%, ${theme.colors.darkBlueV2} ${$volumePercentage}%)`};
    }

    &::-ms-track {
      ${trackStyling};
      background-image: ${({ $volumePercentage, theme }) =>
        `linear-gradient(90deg, ${theme.colors.white} ${$volumePercentage}%, ${theme.colors.darkBlueV2} ${$volumePercentage}%)`};
    }

    &::-webkit-slider-thumb {
      ${thumbStyling};
    }

    &::-moz-range-thumb {
      ${thumbStyling};
    }

    &::-ms-thumb {
      ${thumbStyling};
    }

    &:focus {
      outline: none;
    }
  }
`;

export default VolumeControl;
