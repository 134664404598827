import config from 'config';
import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { BetterHealthIcon } from 'components/Icons/BetterHealthIcon';
import StoreButton from 'components/StoreButton';
import theme from 'theme/theme';

const QR_VALUE = 'http://shido.me/betterhealth';

type DesktopAndTabletProps = {
  isiOSDevice: boolean;
};

const DesktopAndTablet = ({ isiOSDevice }: DesktopAndTabletProps) => (
  <>
    <MainContent>
      <TitleContainer>
        <Title>
          Scan the code with your mobile device to activate your 1 month free
          trial
        </Title>
      </TitleContainer>
      <QRContainer>
        <QRCode
          value={QR_VALUE}
          size={200}
          bgColor={theme.colors.primary}
          fgColor={theme.colors.white}
        />
      </QRContainer>
    </MainContent>
    <Footer>
      <OfferLabel>Offer brought to you by</OfferLabel>
      <LogoWrapper>
        <BetterHealthIcon />
      </LogoWrapper>
      <StoreButtonsWrapper>
        <StoreButton
          color={theme.colors.black}
          variant="appStore"
          href={config.appStoreLink}
        />
        {!isiOSDevice && (
          <StoreButton
            color={theme.colors.black}
            variant="googlePlay"
            href={config.googlePlayLink}
          />
        )}
      </StoreButtonsWrapper>
    </Footer>
  </>
);

const MainContent = styled.div`
  height: 780px;
  background:
    bottom no-repeat url('/images/desktop_background_2.svg'),
    ${({ theme }) => theme.colors.primary} 50% 87% / contain no-repeat
      url('/images/desktop_background_1.png'); /* stylelint-disable */
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 40px;
`;

const Title = styled.span`
  max-width: 670px;
  text-align: center;
  color: white;
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
`;

const Footer = styled.div`
  position: relative;
  top: -40px;
  display: grid;
  justify-content: center;
  background: white;
`;

const OfferLabel = styled.span`
  margin-top: 65px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

const LogoWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  justify-content: center;
`;

const StoreButtonsWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto;
  column-gap: 30px;
`;

const QRContainer = styled.div`
  margin-top: 42px;
  display: grid;
  justify-content: center;
  font-weight: bold;
`;

export default DesktopAndTablet;
