import React from 'react';

const VolumeUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11.376"
    viewBox="0 0 13 11.376"
  >
    <g
      id="Group_4326"
      data-name="Group 4326"
      transform="translate(-97 -2353.999)"
    >
      <path
        id="Path_4161"
        data-name="Path 4161"
        d="M116.655,2362.19h-1.917v-1.917a.274.274,0,1,0-.548,0v1.917h-1.917a.274.274,0,1,0,0,.548h1.917v1.917a.274.274,0,0,0,.548,0v-1.917h1.917a.274.274,0,1,0,0-.548Z"
        transform="translate(-6.928 -2.777)"
        fill="#69b0c7"
      />
      <path
        id="Path_4162"
        data-name="Path 4162"
        d="M103.651,2354.079l-3.753,3.713H98.1a1.091,1.091,0,0,0-1.1,1.083v1.625a1.091,1.091,0,0,0,1.1,1.083h1.8l3.753,3.712a.274.274,0,0,0,.468-.191V2354.27A.275.275,0,0,0,103.651,2354.079Z"
        fill="#69b0c7"
      />
    </g>
  </svg>
);

export default VolumeUpIcon;
