import config from 'config';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

type Props = {
  href: string;
  store: 'App Store' | 'Google Play';
  backgroundColor?: string;
};

const StoreButton = ({ href, store, backgroundColor }: Props) => {
  const isDesktop = getDeviceInfo().platform !== DeviceType.Mobile;

  if (isDesktop) {
    return (
      <StoreLink
        href={`${config.shidoWebsiteVersion}/get-app`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button $backgroundColor={backgroundColor}>
          {store === 'App Store' ? (
            <IconWrapper>
              <img
                src="/icons/appStore.svg"
                alt="App store icon"
                width="17.643"
                height="22.941"
              />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <img
                src="/icons/googlePlay.svg"
                alt="Google play icon"
                width="22.83"
                height="21.52"
              />
            </IconWrapper>
          )}
          <ButtonContent>
            <ButtonSubtitle>
              {store === 'App Store' ? 'DOWNLOAD ON' : 'GET IT ON'}
            </ButtonSubtitle>
            <ButtonTitle>
              {store === 'App Store' ? 'App Store' : 'Google Play'}
            </ButtonTitle>
          </ButtonContent>
        </Button>
      </StoreLink>
    );
  }

  return (
    <StoreLink href={href} target="_blank" rel="noreferrer">
      <Button $backgroundColor={backgroundColor}>
        {store === 'App Store' ? (
          <IconWrapper>
            <img
              src="/icons/appStore.svg"
              alt="App store icon"
              width="17.643"
              height="22.941"
            />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <img
              src="/icons/googlePlay.svg"
              alt="Google play icon"
              width="22.83"
              height="21.52"
            />
          </IconWrapper>
        )}
        <ButtonContent>
          <ButtonSubtitle>
            {store === 'App Store' ? 'DOWNLOAD ON' : 'GET IT ON'}
          </ButtonSubtitle>
          <ButtonTitle>
            {store === 'App Store' ? 'App Store' : 'Google Play'}
          </ButtonTitle>
        </ButtonContent>
      </Button>
    </StoreLink>
  );
};

const Button = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 142px;
  height: 44px;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 25%);
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  padding: 0 0 0 10px;
  position: relative;
  transition: box-shadow 0.5s ease-out;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : 'none'};

  &:hover {
    box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
  }
`;

const ButtonTextStyles = css`
  font-weight: 400;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'F37GingerRegular', sans-serif;
`;

const ButtonSubtitle = styled.div`
  position: relative;
  top: -2px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  ${ButtonTextStyles};
`;

const ButtonTitle = styled.p`
  font-size: 14px;
  margin-top: 2px;
  letter-spacing: 0.4px;
  ${ButtonTextStyles};
`;

const ButtonContent = styled.div`
  margin-left: 30px;
  text-align: left;
  line-height: 20px;
`;

const IconWrapper = styled.span`
  display: block;
  position: absolute;
  top: 9px;
`;

const StoreLink = styled.a`
  text-decoration: none;
`;

export default memo(StoreButton);
