import config from 'config';
import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import ShidoIcon from 'components/Icons/ShidoIcon';

const Header = () => (
  <Wrapper>
    <ShidoLink href={config.shidoURL}>
      <LogoIcon width={84} height={50} />
      <ShidoIcon width={130} height={43} />
      <RegisterSymbol>®</RegisterSymbol>
    </ShidoLink>
  </Wrapper>
);

const Wrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 49.2%;
  transform: translate(-50%, 0);
`;

const ShidoLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 25px;
  text-decoration: none;

  svg:nth-child(1) {
    margin-top: 3px;
  }

  svg:nth-child(2) {
    margin-top: -8px;
  }

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const RegisterSymbol = styled.div`
  position: absolute;
  top: 0;
  right: -16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export default Header;
