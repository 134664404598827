import Slider from 'rc-slider';
import React from 'react';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';

const DEFAULT_THUMB_WIDTH_PORTRAIT = 40;
const DEFAULT_THUMB_WIDTH_LANDSCAPE = 100;

type CustomScrollbarProps = {
  sliderValue: number;
  thumbWidth?: number;
  scrollbarWidth?: number;
  setSliderValue: (value: number) => void;
};

const CustomScrollbar = ({
  thumbWidth,
  sliderValue,
  setSliderValue,
  scrollbarWidth,
}: CustomScrollbarProps) => {
  return (
    <Container $thumbWidth={thumbWidth} $scrollbarWidth={scrollbarWidth}>
      <Slider
        max={100}
        min={0}
        value={sliderValue}
        onChange={(e) => {
          setSliderValue(Number(e));
        }}
      />
    </Container>
  );
};

const Container = styled.div<{
  $scrollbarWidth?: number;
  $thumbWidth?: number;
}>`
  position: relative;
  margin: 22px 20px 36px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }

  .rc-slider-rail {
    background: #e4e6e5;
    height: 6px;
    border-radius: 3px;
  }

  .rc-slider-handle {
    top: 10px;
    border: none;
    height: 6px;
    width: ${({ $thumbWidth }) =>
      $thumbWidth ? $thumbWidth : DEFAULT_THUMB_WIDTH_PORTRAIT}px;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.funtainBlue};
    cursor: pointer;

    @media (orientation: landscape) {
      width: ${({ $thumbWidth }) =>
        $thumbWidth ? $thumbWidth : DEFAULT_THUMB_WIDTH_LANDSCAPE}px;
    }
  }

  .rc-slider-step {
    display: none;
  }

  .rc-slider-track {
    display: none;
  }

  .rc-slider-handle::before {
    position: absolute;
    content: ' ';
    inset: -35px 0;
  }

  .rc-slider {
    width: 88%;
    margin: auto;

    @media (width <= 700px) {
      @media (orientation: landscape) {
        width: 84%;
      }
    }

    @media (width <= 600px) {
      @media (orientation: landscape) {
        width: 79%;
      }
    }
  }

  .rc-slider-rail::before {
    position: absolute;
    content: ' ';
    inset: -35px -100% -40px;
  }

  .rc-slider-rail::after {
    position: absolute;
    content: ' ';
    width: 113%;
    background: #e4e6e5;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    border-radius: 3px;

    @media (width <= 700px) {
      @media (orientation: landscape) {
        width: 119%;
      }
    }

    @media (width <= 600px) {
      @media (orientation: landscape) {
        width: 120%;
      }
    }
  }
`;

export default CustomScrollbar;
