import { useQuery } from 'react-query';

import { getPromotionOffer } from '../services';

export const useGetPromotionOffer = (
  publicCode: string,
  onError?: () => void
) =>
  useQuery(
    ['promotionOffer', publicCode],
    () => {
      if (publicCode) {
        return getPromotionOffer(publicCode);
      }

      return null;
    },
    {
      onError,
      enabled: !!publicCode,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
