import config from 'config';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import theme from 'theme/theme';

import { HI_CATEGORIES, TOPIC_ICON_MAP } from '../constants';
import { TopicType } from '../types';

type Props = {
  publicCode: string;
};

const CheckoutSection = (
  { publicCode }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const [activeTopic, setActiveTopic] = useState<TopicType>(TopicType.WEIGHT);

  return (
    <>
      <Container ref={ref}>
        <Title>What would you most like to improve?</Title>
        <Subtitle>Select a topic to activate your 1 month free trial</Subtitle>
        <CategoriesContainer>
          {HI_CATEGORIES.map(({ id, topicValue, text }) => {
            const Icon = TOPIC_ICON_MAP[topicValue];

            return (
              <CategoryItem
                key={id}
                $isActive={activeTopic === topicValue}
                onClick={() => setActiveTopic(topicValue)}
                target="_blank"
                rel="noreferrer"
                href={`${config.shidoWebsiteVersion}/topic/${topicValue}?publicCode=${publicCode}`}
              >
                <IconWrapper>
                  <Icon />
                </IconWrapper>
                <p>{text}</p>
              </CategoryItem>
            );
          })}
        </CategoriesContainer>
      </Container>
      <ShidoIconWrapper>
        <LogoIcon width={48} height={29} />
        <ShidoTitle>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
    </>
  );
};

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: 4px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 83px;
  position: relative;
  padding-bottom: 46px;
  z-index: 20;
  left: 12px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const IconWrapper = styled.div`
  display: inline;
  margin-top: 3px;
`;

const CategoryItem = styled.a<{ $isActive: boolean }>`
  width: 300px;
  height: 40px;
  background: ${({ theme }) => theme.colors.darkBlueV2};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  padding-left: 11px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  transition: 0.5s all;
  border: 2px solid ${({ theme }) => theme.colors.darkBlueV2};

  p {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;

    @media (width <= 480px) {
      font-size: 14px;
    }
  }

  @media (width <= 655px) {
    width: 335px;
  }

  svg path,
  g {
    fill: ${theme.colors.white} !important;
  }

  @media (width <= 355px) {
    width: 250px;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      box-shadow: none;
      background: ${theme.colors.white};

      p {
        color: ${({ theme }) => theme.colors.darkBlueV2};
      }

      svg path,
      g {
        fill: ${theme.colors.darkBlueV2} !important;
      }
    `};
`;

const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 79px auto 58px;
  column-gap: 20px;
  position: relative;
  max-width: 620px;
  justify-content: center;

  @media (width <= 655px) {
    margin: 38px auto 22px;
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 14px;

  @media (width <= 590px) {
    font-size: 14px;
    margin-top: 34px;
  }
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  @media (width <= 590px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  padding-bottom: 41px;
  padding-top: 38px;
  border-bottom: 1px dashed rgb(216 218 224);
  position: relative;
  z-index: 3;
  margin: 36px auto 0;

  @media (width <= 480px) {
    margin: 0 auto;
    padding-top: 38px;
    padding-bottom: 40px;
  }
`;

export default React.forwardRef(CheckoutSection);
