import React from 'react';

const EnergyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="15"
    viewBox="0 0 24 15"
  >
    <g
      data-name="Group 4273"
      transform="translate(-34.071 -232.949)"
      fill="#69b0c7"
    >
      <circle
        data-name="Ellipse 67"
        cx="2.129"
        cy="2.129"
        r="2.129"
        transform="translate(53.813 233.488)"
      />
      <path
        data-name="Path 623"
        d="M46.568 243.02a4.1 4.1 0 0 1-.877-2.692l-.945.834-6.94 5.437.72.866 7.961-4.362Zm7.632-3.172-1.651-4.417a12.144 12.144 0 0 0-2.541-2.179 35.272 35.272 0 0 0-5.479-.3v1.212l4.355.816 1.04 1.616-2.568 2.268-.144.155c-1.318 1.415-.63 2.72.462 4.129l1.166 1.448-4.689 1.962.491 1.395 6.529-1.81a.992.992 0 0 0 .6-1.454l-1.878-3.237 1.8-1.571s.752 1.821 1.172 1.991a49.87 49.87 0 0 0 5.052 0v-1.212Z"
      />
      <path data-name="Rectangle 195" d="M38.893 236.579h5.79v.937h-5.79z" />
      <path data-name="Rectangle 196" d="M36.528 239.298h5.79v.937h-5.79z" />
      <path data-name="Rectangle 197" d="M34.071 242.013h5.79v.937h-5.79z" />
    </g>
  </svg>
);

export default EnergyIcon;
