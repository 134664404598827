import React, { memo } from 'react';

type LogoProps = {
  color?: string;
  open?: boolean;
};

const LogoWithRights = ({ color, open }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.989"
    height="29.006"
    viewBox="0 0 64.989 29.006"
    fill={open ? '#fff' : color}
  >
    <text
      id="_"
      data-name="®"
      transform="translate(52.989 12)"
      fontSize="16"
      fontFamily="Helvetica"
    >
      <tspan x="0" y="0">
        ®
      </tspan>
    </text>
    <path
      id="Path_4337"
      data-name="Path 4337"
      d="M692.432,481.445a15.127,15.127,0,0,0-20.975,0l-6.882,6.7a4.884,4.884,0,0,0-1.41,4.429,4.706,4.706,0,0,0,.807,1.9,5.19,5.19,0,0,0,7.816.7l4.552-4.435a1.473,1.473,0,0,1,.891-.426,1.4,1.4,0,0,1,1.108.391,1.329,1.329,0,0,1,0,1.912l-6.882,6.705a11.342,11.342,0,0,1-15.726,0,10.65,10.65,0,0,1,0-15.321,11.356,11.356,0,0,1,12.578-2.148.225.225,0,0,0,.251-.042l1.637-1.594c.262-.256.533-.5.81-.735a.215.215,0,0,0-.032-.353,15.153,15.153,0,0,0-17.868,2.315,14.2,14.2,0,0,0,0,20.436,15.128,15.128,0,0,0,20.975,0l6.883-6.705a4.893,4.893,0,0,0-.5-7.458,5.1,5.1,0,0,0-2.453-.986,5.166,5.166,0,0,0-4.257,1.416l-4.552,4.434a1.473,1.473,0,0,1-.891.427,1.4,1.4,0,0,1-1.109-.391,1.327,1.327,0,0,1-.4-1.081,1.41,1.41,0,0,1,.438-.867L674.082,484a11.342,11.342,0,0,1,15.726,0,10.65,10.65,0,0,1,0,15.321,11.355,11.355,0,0,1-12.578,2.148.225.225,0,0,0-.251.042l-1.636,1.595q-.392.382-.809.735a.215.215,0,0,0,.032.353,15.142,15.142,0,0,0,7.38,1.91,14.987,14.987,0,0,0,10.487-4.225A14.2,14.2,0,0,0,692.432,481.445Z"
      transform="translate(-648.77 -477.1)"
    />
  </svg>
);

export default memo(LogoWithRights);
