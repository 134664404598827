import config from 'config';
import React from 'react';
import styled from 'styled-components';

import DefaultButton from 'components/DefaultButton';
import Arrow from 'components/Icons/Arrow';
import { LogoIcon } from 'components/Icons/LogoIcon';
import theme from 'theme/theme';

import Header from './Header';

type Props = {
  name: string;
  handle: string;
  image: string | null;
  chosenColor: {
    light: string;
    strong: string;
  };
  publicCode: string;
  isMobile?: boolean;
  goToNextSection: () => void;
};

const IntroSection = ({
  image,
  handle,
  name,
  isMobile,
  chosenColor,
  publicCode,
  goToNextSection,
}: Props) => (
  <Container $color={chosenColor.light}>
    <Header isMobile={isMobile} />
    <Title>Exclusive offer from {name}.</Title>
    {handle && (
      <TeamSubtitleContainer>
        <TeamSubtitle>{handle}</TeamSubtitle>
      </TeamSubtitleContainer>
    )}
    <ImageGraphContainer>
      <ImageInnerContainer>
        <AdditionalImageInner>
          {image ? (
            <img src={image} />
          ) : (
            <LogoIcon
              width={isMobile ? 100 : 153}
              height={isMobile ? 50 : 93}
            />
          )}
        </AdditionalImageInner>
      </ImageInnerContainer>
    </ImageGraphContainer>
    <FreeTrialText>Get your 1 month free trial today!</FreeTrialText>
    <ButtonLink
      target="_blank"
      rel="noreferrer"
      href={`${config.shidoWebsiteVersion}/topic/weight?publicCode=${publicCode}`}
    >
      <DefaultButton
        text="continue"
        textColor={theme.colors.white}
        isActive
        withShadow
        buttonColor={theme.colors.funtainBlue}
        isUppercase
        width={isMobile ? 158 : 300}
        height={isMobile ? 50 : 60}
      />
    </ButtonLink>
    <ContainerIcon $color={chosenColor.strong}>
      <ArrowIconWrapper
        data-testid="intro-arrow"
        $color={chosenColor.strong}
        onClick={goToNextSection}
      >
        <Arrow />
      </ArrowIconWrapper>
    </ContainerIcon>
    <Template $color={chosenColor.strong} />
  </Container>
);

const ButtonLink = styled.a`
  position: relative;
  z-index: 1;
  margin: 32px auto 38px;
  transition: 0.5s all;
  width: 300px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  pointer-events: unset;

  @media (width <= 450px) {
    margin: 14px auto 4px;
  }
`;

const Template = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/promoTemplateDesktop.svg') no-repeat 50% 50%;
  mask-position: bottom -2166px right 50%;

  @media (width <= 500px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -56px right 50%;
    mask-size: 600px;
  }

  @media (width <= 450px) {
    mask-position: bottom 11px right 50%;
    mask-size: 450px;
  }
`;

const AdditionalImageInner = styled.div`
  width: 262px;
  height: 262px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  @media (width <= 480px) {
    width: 109px;
    height: 109px;
  }

  img {
    width: 268px;
    height: 268px;
    object-fit: cover;

    @media (width <= 480px) {
      width: 116px;
      height: 116px;
    }
  }
`;

const ContainerIcon = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  padding: 50px 0 49px;
  display: flex;
  justify-content: center;

  @media (width <= 480px) {
    padding: 27px 0 5px;
  }
`;

const ArrowIconWrapper = styled.div<{ $color: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  background-color: ${({ $color }) => $color};
`;

const FreeTrialText = styled.div`
  font-size: 40px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 68px;
  position: relative;
  z-index: 3;

  @media (width <= 900px) {
    font-size: 30px;
  }

  @media (width <= 480px) {
    font-size: 16px;
    margin-top: 57px;
  }
`;

export const ImageGraphContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  border: 2px dashed ${({ theme }) => theme.colors.pinkLight};
  border-radius: 50%;
  margin: 55px auto 0;

  @media (width <= 480px) {
    width: 150px;
    height: 150px;
    margin: 41px auto 0;
  }
`;

const ImageInnerContainer = styled.div`
  width: 270px;
  height: 270px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 15px -4px grey;

  @media (width <= 480px) {
    width: 116px;
    height: 116px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const TeamSubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 5px;
`;

const TeamSubtitle = styled.p`
  font-size: 20px;
  display: inline-block;
  word-break: break-all;
  text-align: center;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pinkLight};
  box-sizing: border-box;
  font-weight: 700;
  position: relative;
  z-index: 3;
  margin: 40px auto 0;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-top: 27px;

  @media (width <= 480px) {
    font-size: 12px;
    line-height: 21px;
    padding: 3px 20px 2px;
    margin-top: 12px;
  }
`;

const Title = styled.h1`
  font-size: 60px;
  text-align: center;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;
  position: relative;
  z-index: 3;
  margin-top: 74px;

  @media (width <= 1050px) {
    font-size: 45px;
  }

  @media (width <= 480px) {
    font-size: 30px;
    margin-top: 0;
    padding: 0 20px;
    line-height: 37px;
  }
`;

const Container = styled.div<{ $color: string }>`
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;

export default IntroSection;
