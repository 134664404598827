import {
  Affiliate,
  AffiliateActivate,
  AffiliateInstruction,
  AffiliateQR,
  BetterHealth,
  CorporateOfferChat,
  CorporateOfferIntro,
  CorporateOfferSuccess,
  InvitationIntro,
  Promotion,
  PromotionActivation,
  ShidoQR,
} from 'pages';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop';

import { AppRoutes } from './models';
import PrivateChatRoute from './privateRoutes/PrivateChatRoute';
import PrivateSuccessRoute from './privateRoutes/PrivateSuccessRoute';

export const LocationDisplay = () => {
  const location = useLocation();

  return (
    <div style={{ display: 'none' }} data-testid="location-display">
      {location.pathname}
    </div>
  );
};

export default (
  <div>
    <ScrollToTop />
    <Routes>
      <Route
        path={AppRoutes.Base}
        element={<Navigate to={AppRoutes.BetterHealth} replace />}
      />
      <Route path={AppRoutes.BetterHealth} element={<BetterHealth />} />

      <Route path={AppRoutes.ShidoQR} element={<ShidoQR />} />

      <Route path={AppRoutes.Invitation} element={<InvitationIntro />} />

      <Route path={AppRoutes.Corporate} element={<CorporateOfferIntro />} />
      <Route path={AppRoutes.CorporateBase}>
        <Route
          index
          path={AppRoutes.CorporateChat}
          element={
            <PrivateChatRoute>
              <CorporateOfferChat />
            </PrivateChatRoute>
          }
        />
        <Route
          path={AppRoutes.CorporateSuccess}
          element={
            <PrivateSuccessRoute>
              <CorporateOfferSuccess />
            </PrivateSuccessRoute>
          }
        />
      </Route>

      <Route path={AppRoutes.PromotionBase}>
        <Route index path={AppRoutes.PromotionOffer} element={<Promotion />} />
        <Route
          path={AppRoutes.PromotionActivation}
          element={<PromotionActivation />}
        />
      </Route>

      <Route path={AppRoutes.AffiliateBase}>
        <Route index path={AppRoutes.AffiliateOffer} element={<Affiliate />} />
        <Route path=":publicCode">
          <Route
            index
            path={AppRoutes.AffiliateActivate}
            element={<AffiliateActivate />}
          />
          <Route path={AppRoutes.AffiliateQR} element={<AffiliateQR />} />
        </Route>
        <Route
          path={AppRoutes.AffiliateInstruction}
          element={<AffiliateInstruction />}
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to={AppRoutes.BetterHealth} replace />}
      />
    </Routes>
    <LocationDisplay />
  </div>
);
