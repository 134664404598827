import React from 'react';

const PauseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13.325"
    viewBox="0 0 12 20"
  >
    <g
      id="Group_4312"
      data-name="Group 4312"
      transform="translate(-53.667 -1777)"
    >
      <rect
        id="Rectangle_858"
        data-name="Rectangle 858"
        width="5"
        height="20"
        rx="0.5"
        transform="translate(53.667 1777)"
        fill="#fff"
      />
      <rect
        id="Rectangle_859"
        data-name="Rectangle 859"
        width="5"
        height="20"
        rx="0.5"
        transform="translate(60.667 1777)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default PauseIcon;
