import React, { SVGProps } from 'react';

type Props = {
  uniqueID: string;
} & SVGProps<SVGSVGElement>;

const CircleArrowIcon = ({ uniqueID, className }: Props) => (
  <svg
    id="Component_1_1"
    data-name="Component 1 – 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    className={className}
  >
    <defs>
      <clipPath id={`clip-path-${uniqueID}`}>
        <path
          id="Path_13164"
          data-name="Path 13164"
          d="M11.7-6.162a1,1,0,0,0-.924-.617H7.75a.249.249,0,0,1-.177-.073A.249.249,0,0,1,7.5-7.029V-22.5A1.5,1.5,0,0,0,6-24a1.5,1.5,0,0,0-1.5,1.5V-7.029a.25.25,0,0,1-.25.25H1.222A1,1,0,0,0,.3-6.162a1,1,0,0,0,.217,1.09L5.292-.294A1,1,0,0,0,6,0a1,1,0,0,0,.707-.293l4.778-4.778A1,1,0,0,0,11.7-6.162Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g
      id="Ellipse_774"
      data-name="Ellipse 774"
      fill="rgba(255,255,255,0.4)"
      stroke="#d7a6b0"
      strokeWidth="2"
      strokeDasharray="4 4"
    >
      <circle cx="20" cy="20" r="20" stroke="none" />
      <circle cx="20" cy="20" r="19" fill="none" />
    </g>
    <g id="Group_56581" data-name="Group 56581" transform="translate(14 32)">
      <g
        id="Group_15365"
        data-name="Group 15365"
        clipPath={`url(#clip-path-${uniqueID})`}
      >
        <path
          id="Path_13163"
          data-name="Path 13163"
          d="M-4.778-29H16.777V5H-4.778Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default CircleArrowIcon;
