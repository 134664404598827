import { createRandomId } from 'helpers';
import React from 'react';

type ChevronIconProps = {
  color: string;
  width?: number;
  height?: number;
};

const ChevronIcon = ({ color, width, height }: ChevronIconProps) => {
  const clipPathId = createRandomId('chevron-');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || '16'}
      height={height || '10'}
      viewBox="0 0 16 10"
    >
      <defs>
        <clipPath id={clipPathId}>
          <path
            id="Path_6875"
            data-name="Path 6875"
            d="M8.009-5.531A1.535,1.535,0,0,1,6.856-6.05L.316-13.469a1.227,1.227,0,0,1,.13-1.713,1.233,1.233,0,0,1,1.719.09L7.884-8.6a.167.167,0,0,0,.125.057A.167.167,0,0,0,8.134-8.6l5.719-6.488a1.233,1.233,0,0,1,1.176-.417,1.23,1.23,0,0,1,.937.822,1.227,1.227,0,0,1-.264,1.217L9.164-6.052A1.541,1.541,0,0,1,8.009-5.531Z"
            transform="translate(-0.028 15.531)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g
        id="Group_6060"
        data-name="Group 6060"
        transform="translate(-0.028 15.531)"
      >
        <g
          id="Group_6059"
          data-name="Group 6059"
          transform="translate(0.028 -15.531)"
          clipPath={`url(#${clipPathId})`}
        >
          <path
            id="Path_6874"
            data-name="Path 6874"
            d="M-4.972-20.531H17.694V-3.878H-4.972Z"
            transform="translate(1.639 17.204)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default ChevronIcon;
