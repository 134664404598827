import { useGetStoreButtonInfo } from 'hooks';
import { find } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import WarningIcon from 'components/Icons/WarningIcon';
import { isIOSDevice } from 'helpers/getDeviceInfo';
import Loader, {
  LoaderWrapper,
} from 'modules/corporateOffer/components/Loader';
import MobileButton from 'modules/promotionOffer/components/MobileButton';
import { PROMOTION_TERMS } from 'modules/promotionOffer/constants';
import { convertPromoOffer } from 'modules/promotionOffer/helpers';
import { useGetPromotionOffer } from 'modules/promotionOffer/hooks';
import { PromotionOffer } from 'modules/promotionOffer/types';
import theme from 'theme/theme';

import { getPromoStoreOfferCode } from '../../modules/promotionOffer/services';

export const PromotionActivation = () => {
  const { publicCode } = useParams<{ publicCode: string }>();

  const { variant } = useGetStoreButtonInfo();

  const { data: offerData, isLoading } = useGetPromotionOffer(
    convertPromoOffer(publicCode)
  );

  const {
    isLoading: isFetchingStoreCode,
    error,
    mutate,
  } = useMutation(getPromoStoreOfferCode, {
    onSuccess: (data) => {
      window.location.assign(data.url);
    },
  });

  const subtitle = getSubtitle(offerData, error as string | null);

  const redirectToMobileStore = useCallback(() => {
    if (publicCode) {
      mutate({
        platform: isIOSDevice ? 'ios' : 'android',
        publicCode: convertPromoOffer(publicCode),
      });
    }
  }, [mutate, publicCode]);

  useEffect(() => {
    document.body.style.background = theme.colors.sand;

    return () => {
      document.body.style.background = theme.colors.white;
    };
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader bigCircleSize={85} smallCircleSize={63} />
      </LoaderWrapper>
    );
  }

  return (
    <Main>
      <Container>
        <ShidoIconWrapper>
          <LogoIcon width={61} height={37} />
          <ShidoTitle>
            <ShidoText>shido</ShidoText>
            <ShidoMark>®</ShidoMark>
          </ShidoTitle>
        </ShidoIconWrapper>
        <Title>{offerData?.title || "Couldn't find the offer code"}</Title>
        <HeroWrapper>
          <Subtitle>{subtitle}</Subtitle>
          {!!error && (
            <WarningWrapper>
              <WarningIcon width={60} height={60} color={theme.colors.white} />
            </WarningWrapper>
          )}
        </HeroWrapper>
        <ButtonWrapper>
          <MobileButton
            isDisabled={!!error || !offerData}
            isLoading={isFetchingStoreCode}
            color={theme.colors.funtainBlue}
            variant={variant}
            onClick={redirectToMobileStore}
            isMobile
          />
        </ButtonWrapper>
        <ShidoIconWrapperFooter>
          <ShidoTitleFooter>
            <LogoIcon width={48} height={29} />
            <ShidoMarkFooter>®</ShidoMarkFooter>
          </ShidoTitleFooter>
        </ShidoIconWrapperFooter>
        <Image />
        <Template />
      </Container>
      <hr />
    </Main>
  );
};

const getSubtitle = (
  offerData: PromotionOffer | null | undefined,
  error: string | null
) => {
  if (error) {
    return "Couldn't find available offer codes";
  }
  if (!offerData) {
    return '';
  }

  const trialTerm =
    find(PROMOTION_TERMS, {
      findBy: offerData.promotionTerm.term,
    })?.promoDisplay || '';

  return `Download the app to activate a free ${trialTerm} trial`;
};

const HeroWrapper = styled.section`
  position: relative;
`;

const WarningWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  top: 75px;
  left: 0;
  right: 0;
`;

const Main = styled.main`
  position: relative;

  hr {
    background: ${({ theme }) => theme.colors.sand};
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 5px;
    bottom: -10px;
    border: none;
  }
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  transition: all 2s;
  transform: translate(-50%, 0);
  content: url('/images/default_mobile_background_image.png');
  bottom: 0;
  width: 100vw;
  min-width: 100%;

  @media (width <= 950px) {
    bottom: 100px;
    width: 100vw;
    max-width: 950px;
  }

  @media (width <= 920px) {
    bottom: 100px;
    width: 100vw;
    max-width: 920px;
  }

  @media (width <= 600px) {
    bottom: 100px;
    width: 100vw;
    max-width: 600px;
  }

  @media (width <= 500px) {
    bottom: 130px;
    width: 100vw;
    max-width: 500px;
  }

  @media (width <= 450px) {
    bottom: 145px;
    width: 100vw;
    max-width: 450px;
  }

  @media (width <= 376px) {
    bottom: 150px;
    width: 100vw;
    max-width: 375px;
  }
`;

const ShidoMarkFooter = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: -2px;
  right: -18px;
`;

const ShidoTitleFooter = styled.div`
  position: relative;
`;

const ShidoIconWrapperFooter = styled.div`
  position: relative;
  margin: 47px auto 43px;
  width: 49px;
  z-index: 2;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonWrapper = styled.div`
  width: 158px;
  margin: 287px auto 0;
  position: relative;
  z-index: 2;
`;

const Template = styled.div`
  position: absolute;
  overflow: hidden;
  inset: 0;
  mask: url('/images/promoTemplateDesktop.svg') no-repeat 50% 50%;
  mask-position: bottom -2418px right 50%;
  background-color: ${({ theme }) => theme.colors.sand};

  @media (width <= 920px) {
    mask-position: bottom -2424px right 50%;
  }

  @media (width <= 600px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -93px right 50%;
    mask-size: 600px;
  }

  @media (width <= 500px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -87px right 50%;
    mask-size: 600px;
  }

  @media (width <= 450px) {
    mask-position: bottom -33px right 50%;
    mask-size: 450px;
  }

  @media (width <= 376px) {
    mask-position: bottom 2px right 50%;
    mask-size: 375px;
  }

  @media (width <= 362px) {
    mask-position: bottom 0 right 50%;
    mask-size: 375px;
  }
`;

const Subtitle = styled.p`
  position: relative;
  z-index: 10;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  line-height: 21px;
  margin: 26px auto 0;
  font-weight: 300;
  font-size: 16px;
  right: -2px;

  @media (width <= 500px) {
    max-width: 231px;
  }
`;

const Title = styled.h1`
  position: relative;
  z-index: 2;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  font-size: 30px;
  line-height: 38px;
  margin-top: 33px;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 40px;
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  top: 0;
  right: -12px;
`;

const ShidoTitle = styled.div`
  position: relative;
  right: -3px;
  top: -5px;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin: 40px auto 0;
  position: relative;
  z-index: 2;
  width: 190px;
  right: -2px;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.primary};
`;
