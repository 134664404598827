import BrainHealthIcon from 'components/Icons/BrainHealthIcon';
import EnergyIcon from 'components/Icons/EnergyIcon';
import ImmuneIcon from 'components/Icons/ImmuneIcon';
import SleepIcon from 'components/Icons/SleepIcon';
import StressIcon from 'components/Icons/StressIcon';
import WeightIcon from 'components/Icons/WeightIcon';

import { TopicType } from '../types';

export const TOPIC_ICON_MAP: Record<TopicType, () => JSX.Element> = {
  [TopicType.BRAIN]: BrainHealthIcon,
  [TopicType.ENERGY]: EnergyIcon,
  [TopicType.WEIGHT]: WeightIcon,
  [TopicType.IMMUNE]: ImmuneIcon,
  [TopicType.STRESS]: StressIcon,
  [TopicType.SLEEP]: SleepIcon,
};
