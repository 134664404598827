import React from 'react';
import styled from 'styled-components';

import Arrow from 'components/Icons/Arrow';
import { LogoIcon } from 'components/Icons/LogoIcon';

type Props = {
  name: string;
  statement: string;
  handle: string;
  image: string | null;
  isCheckout: boolean;
  goToNextSection: () => void;
};

const ResponseSection = (
  { name, statement, handle, image, isCheckout, goToNextSection }: Props,
  ref: React.Ref<HTMLDivElement>
) => (
  <Container ref={ref}>
    <TitleName>{name}</TitleName>
    <Subtitle>{handle}</Subtitle>
    <ParagraphStatement>{statement}</ParagraphStatement>
    <Paragraph $isCheckout={isCheckout}>
      Join Shido today to start a 1 month free trial.
    </Paragraph>
    <Paragraph $isCheckout={isCheckout}>
      No commitments, cancel anytime.
    </Paragraph>

    <ImageContainer>
      {image ? <img src={image} /> : <LogoIcon width={40} height={20} />}
    </ImageContainer>
    <ArrowWrapper onClick={goToNextSection}>
      <Arrow />
    </ArrowWrapper>
  </Container>
);

const TitleName = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;

  @media (width <= 480px) {
    font-size: 20px;
  }
`;

const Subtitle = styled(TitleName)`
  margin-top: 13px;
  font-size: 20px;
  line-height: 18px;
  padding: 0 3px;

  @media (width <= 480px) {
    font-size: 14px;
    margin-top: 15px;
  }
`;

const Paragraph = styled(TitleName)<{ $isCheckout?: boolean }>`
  font-weight: ${({ $isCheckout }) => ($isCheckout ? '400' : '300')};
  font-size: 20px;
  margin-bottom: 9px;

  @media (width <= 480px) {
    font-size: 14px;
  }
`;

const ParagraphStatement = styled(Paragraph)`
  margin-bottom: 35px;
  margin-top: 28px;
  line-height: 30px;
  font-weight: 200;
  word-break: break-word;

  @media (width <= 480px) {
    padding: 0 10px;
    margin-bottom: 22px;
    margin-top: 43px;
    line-height: 22px;
    font-weight: 300;
    word-break: break-word;
  }
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 37px auto;
  box-shadow: -1px 1px 10px -4px grey;
  background: white;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;

const ArrowWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  margin: 19px auto 0;
`;

const Container = styled.div`
  max-width: 1400px;
  padding-bottom: 148px;
  padding-top: 42px;
  border-bottom: 1px dashed rgb(216 218 224);
  position: relative;
  z-index: 3;
  margin: 36px auto 0;

  @media (width <= 480px) {
    margin: 0 auto;
    padding-top: 38px;
    padding-bottom: 40px;
  }
`;

export default React.forwardRef(ResponseSection);
