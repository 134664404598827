import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import EmailAddress from './EmailAddress';
import FirstName from './FirstName';
import Passcode from './Passcode';
import PinCode from './PinCode';
import TermsAndConditionsAgreement from './TermsAndConditionsAgreement';

type Props = {
  isMobile?: boolean;
  isEditing: boolean;
};

export const CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION = 12.5;

const CreateAccount = ({ isMobile, isEditing }: Props) => {
  const [email, setEmail] = useState('');
  const [pinCode, setPinCode] = useState('');

  const [isTermsShown, setIsTermsShown] = useState(false);
  // const [isSignInMethodShown, setIsSignInMethodShown] = useState(false);
  const [isEmailShown, setIsEmailShown] = useState(false);
  const [isPinCodeShown, setIsPinCodeShown] = useState(false);
  const [isPassCodeShown, setIsPasscodeShown] = useState(false);

  const [isFirstNameEditing, setIsFirstNameEditing] = useState(false);
  const [isSignInEditing] = useState(false);
  const [isTermsEditing, setIsTermsEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isPinCodeEditing, setIsPinCodeEditing] = useState(false);
  const [isPassCodeEditing, setIsPassCodeEditing] = useState(false);

  const isTermsVisible = !isFirstNameEditing && !isSignInEditing;
  const isEmailVisible = isTermsVisible && !isTermsEditing;
  const isPinCodeVisible = isEmailVisible && !isEmailEditing;
  const isPasscodeVisible = isPinCodeVisible && !isPinCodeEditing;

  const toggleEmailEditingStatus = useCallback(() => {
    setIsEmailEditing(!isEmailEditing);
  }, [isEmailEditing]);

  const togglePinCodeEditingStatus = useCallback(() => {
    setIsPinCodeEditing(!isPinCodeEditing);
  }, [isPinCodeEditing]);

  const togglePassCodeEditingStatus = useCallback(() => {
    setIsPassCodeEditing(!isPassCodeEditing);
  }, [isPassCodeEditing]);

  return (
    <Container $isEditing={isEditing}>
      <FirstName
        isMobile={isMobile}
        goToNextStage={() => {
          // setIsSignInMethodShown(true);
          setIsTermsShown(true);
        }}
        toggleEditingStatus={() => setIsFirstNameEditing((prev) => !prev)}
        isEditing={isFirstNameEditing}
      />
      {/* {isSignInMethodShown && (
        <SignInMethod
          isMobile={isMobile}
          shown={!isFirstNameEditing}
          isEditing={isSignInEditing}
          toggleEditingStatus={() => setIsSignInEditing((prev) => !prev)}
          goToNextStage={(showNext) => {
            if (showNext) {
              setIsTermsShown(true);
            } else {
              setIsTermsShown(false);
              setIsEmailShown(false);
              setIsPinCodeShown(false);
              setIsPasscodeShown(false);
            }
          }}
        />
      )} */}
      {isTermsShown && (
        <TermsAndConditionsAgreement
          isMobile={isMobile}
          goToNextStage={() => setIsEmailShown(true)}
          toggleEditingStatus={() => setIsTermsEditing((prev) => !prev)}
          isEditing={isTermsEditing}
          shown={isTermsVisible}
        />
      )}
      {isEmailShown && (
        <EmailAddress
          isMobile={isMobile}
          goToNextStage={() => setIsPinCodeShown(true)}
          toggleEditingStatus={toggleEmailEditingStatus}
          isEditing={isEmailEditing}
          shown={isEmailVisible}
          email={email}
          setEmail={setEmail}
        />
      )}
      {isPinCodeShown && (
        <PinCode
          isMobile={isMobile}
          goToNextStage={() => setIsPasscodeShown(true)}
          toggleEditingStatus={togglePinCodeEditingStatus}
          isEditing={isPinCodeEditing}
          togglePassCodeEditingStatus={togglePassCodeEditingStatus}
          shown={isPinCodeVisible}
          email={email}
          pincode={pinCode}
          setPinCode={setPinCode}
        />
      )}
      {isPassCodeShown && (
        <Passcode
          isMobile={isMobile}
          togglePassCodeEditingStatus={togglePassCodeEditingStatus}
          isEditing={isPassCodeEditing}
          shown={isPasscodeVisible}
          email={email}
          pincode={pinCode}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isEditing: boolean }>`
  display: ${({ $isEditing }) => ($isEditing ? 'none' : 'block')};
`;

export default CreateAccount;
