import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import CloseIcon from 'components/Icons/CloseIcon';
import { LogoIcon } from 'components/Icons/LogoIcon';
import theme from 'theme/theme';

import { useChatContext } from './ChatContext';

const logoSizes = {
  sm: {
    w: 32,
    h: 20,
  },
  lg: {
    w: 60,
    h: 36,
  },
};

type ChatHeaderProps = {
  isMobile?: boolean;
};

const ChatHeader = ({ isMobile }: ChatHeaderProps) => {
  const { progress } = useChatContext();

  const navigate = useNavigate();

  return (
    <>
      <Container $isMobile={isMobile}>
        <LogoIcon
          width={isMobile ? logoSizes.sm.w : logoSizes.lg.w}
          height={isMobile ? logoSizes.sm.h : logoSizes.lg.h}
        />
        <Text $isMobile={isMobile}>ACTIVATE OFFER</Text>
        <ActionButton
          iconColor={theme.colors.white}
          backColor={theme.colors.primary}
          borderColor="none"
          borderSize="sm"
          clickHandler={() => navigate(-1)}
          buttonSize="sm"
          icon={CloseIcon}
        />
      </Container>
      <ProgressBar $isMobile={isMobile} $progressPercentage={progress} />
    </>
  );
};

const Container = styled.div<{ $isMobile?: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${({ $isMobile }) => ($isMobile ? '40px' : '80px')};
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 40;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Text = styled.p<{ $isMobile?: boolean }>`
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${({ $isMobile }) => ($isMobile ? '12px' : '14px')};
  color: ${({ theme }) => theme.colors.white};
`;

const ProgressBar = styled.div<{
  $progressPercentage: number;
  $isMobile?: boolean;
}>`
  position: fixed;
  top: ${({ $isMobile }) => ($isMobile ? '40px' : '80px')};
  width: 100%;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s;
  z-index: 30;
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.doveGray};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: ${({ theme }) => theme.colors.secondary};
    width: ${({ $progressPercentage }) => $progressPercentage}%;
    transition: 1s all;
    height: 4px;
    z-index: 15;
  }
`;

export default ChatHeader;
