import React from 'react';

type SubmitIconProps = {
  color: string;
};

const SubmitIcon = ({ color }: SubmitIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <path
      data-name="Path 8737"
      d="M19 0H4a4.012 4.012 0 0 0-4 4v15a4.011 4.011 0 0 0 4 4h15a4.011 4.011 0 0 0 4-4V4a4.012 4.012 0 0 0-4-4Zm-1 11a2 2 0 0 1-2 2h-5.75a.25.25 0 0 0-.25.25V15a1 1 0 0 1-.617.924 1 1 0 0 1-1.09-.217l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.09-.217A1 1 0 0 1 10 9v1.75a.249.249 0 0 0 .073.177.249.249 0 0 0 .177.073h5.5a.249.249 0 0 0 .177-.073.249.249 0 0 0 .073-.177V8a1 1 0 0 1 1-1 1 1 0 0 1 1 1Z"
      fill={color}
    />
  </svg>
);

export default SubmitIcon;
