import React from 'react';

const VolumeDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="11.375"
      viewBox="0 0 13 11.375"
    >
      <g
        id="Group_4325"
        data-name="Group 4325"
        transform="translate(-145 -2353.999)"
      >
        <path
          id="Path_4159"
          data-name="Path 4159"
          d="M164.654,2364h-4.381a.274.274,0,0,0,0,.548h4.381a.274.274,0,0,0,0-.548Z"
          transform="translate(-6.928 -4.587)"
          fill="#69b0c7"
        />
        <path
          id="Path_4160"
          data-name="Path 4160"
          d="M151.651,2354.079l-3.752,3.712h-1.8a1.091,1.091,0,0,0-1.1,1.083v1.625a1.091,1.091,0,0,0,1.1,1.083h1.8l3.752,3.712a.274.274,0,0,0,.468-.191v-10.833A.275.275,0,0,0,151.651,2354.079Z"
          transform="translate(0 0)"
          fill="#69b0c7"
        />
      </g>
    </svg>
  );
};

export default VolumeDown;
