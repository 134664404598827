import config from 'config';
import React from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';
import ChatContextProvider from 'modules/corporateOffer/chat/ChatContext';
import DesktopChat from 'modules/corporateOffer/components/DesktopChat';
import MobileChat from 'modules/corporateOffer/components/MobileChat';
import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';

export const CorporateOfferChat = () => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;
  const { isValidCode } = useCorporateContext();

  if (!isValidCode) return null;

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <ChatContextProvider>
        {isMobile ? <MobileChat /> : <DesktopChat />}
      </ChatContextProvider>
    </GoogleOAuthProvider>
  );
};
