import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';
import { registerNameAndEmail } from 'modules/corporateOffer/services';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import Email from '../../chatActions/Email';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION } from './CreateAccount';

type EmailAddressProps = {
  isMobile?: boolean;
  isEditing: boolean;
  shown: boolean;
  email: string;
  setEmail: (email: string) => void;
  goToNextStage: () => void;
  toggleEditingStatus: () => void;
};

const EmailAddress = ({
  isMobile,
  email,
  shown,
  isEditing,
  goToNextStage,
  setEmail,
  toggleEditingStatus,
}: EmailAddressProps) => {
  const { changeProgress } = useChatContext();
  const { userName } = useCorporateContext();

  const [isDelayShown, setShowDelay] = useState(false);
  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [isStatusBarIncreased, setIsStatusBarIncreased] = useState(false);
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const [errorResponse, setErrorResponse] = useState({ isError: false });
  const [errorEmail, setErrorEmail] = useState(false);

  const applyError = useCallback(() => {
    if (errorEmail) {
      setErrorResponse({ isError: true });
    }
  }, [errorEmail]);

  const checkEmail = useCallback(
    (newEmail: string) => {
      if (newEmail === email && !errorEmail) {
        if (isEditing) {
          toggleEditingStatus();
        }

        return;
      }

      applyError();
      setEmail(newEmail);
    },
    [applyError, email, errorEmail, isEditing, setEmail, toggleEditingStatus]
  );

  useQuery(
    ['registerNameAndEmail', userName, email],
    () => {
      return registerNameAndEmail({
        firstName: userName,
        email: email,
      });
    },
    {
      onSuccess: () => {
        setErrorEmail(false);
        setShowActionContainer(false);
        setErrorResponse({ isError: false });
        setIsSuccessRequest(true);
        if (isEditing) {
          toggleEditingStatus();
        }

        if (!isEditing && !isStatusBarIncreased) {
          changeProgress(CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION);
          setIsStatusBarIncreased(true);
        }
      },
      onError: () => {
        setErrorEmail(true);
        setErrorResponse({ isError: true });
      },
      refetchOnWindowFocus: false,
      enabled: !!email,
      cacheTime: 0,
      retry: 0,
    }
  );

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message="OK what’s your email address?"
        isMobile={isMobile}
        goToNextStep={() => {
          setShowDelay(true);
        }}
      />
      {isDelayShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {isSuccessRequest && email && !isEditing && (
        <ChatMessage
          isOutgoing
          message={email}
          isMobile={isMobile}
          goToNextStep={goToNextStage}
          toggleEditingStatus={toggleEditingStatus}
          lastMessage
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <Email
          setErrorResponse={setErrorResponse}
          errorResponse={errorResponse}
          isMobile={isMobile}
          goToNextStep={checkEmail}
          value={email}
          isEditing={isEditing}
        />
      )}
    </StageContainer>
  );
};

export default React.memo(EmailAddress);
