import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import OfferIntroContent from 'modules/corporateOffer/components/OfferIntroContent';
import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';
import { getDepartments, getPublicInfo } from 'modules/corporateOffer/services';

export const CorporateOfferIntro = () => {
  const { account } = useParams<{ account: string }>();
  const {
    setOfferTerm,
    setPublicCode,
    setUserImages,
    setAccountName,
    setIsShowDepartment,
    setDepartments,
  } = useCorporateContext();

  useQuery([], getDepartments, {
    onSuccess: (data) => {
      setDepartments(data);
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading } = useQuery(
    [account],
    () => {
      if (account) {
        return getPublicInfo(account);
      }

      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          setUserImages({ logo: data.logoUrl, background: data.backgroundUrl });
          setPublicCode(data.publicCode);
          setOfferTerm(data.offerTerm);
          setAccountName(data.corporateAccountName);
          setIsShowDepartment(data.includeDepartment);
        }
      },
      enabled: !!account,
      refetchOnWindowFocus: false,
    }
  );

  return <OfferIntroContent isLoading={isLoading} />;
};
