import { useGetStoreButtonInfo } from 'hooks';
import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { isIOSDevice } from 'helpers/getDeviceInfo';
import { getAffiliateStoreOfferCode } from 'modules/affiliateOffer/services';
import { AffiliatePlatform } from 'modules/affiliateOffer/types';
import MobileButton from 'modules/promotionOffer/components/MobileButton';
import { AppRoutes } from 'router/models';
import theme from 'theme/theme';

import { Subtitle } from './DesktopIntroSection';

type Props = {
  emailVerificationCode: string;
  email: string;
  publicCode: string | undefined;
};

export const MobileIntroSection = ({
  emailVerificationCode,
  email,
  publicCode,
}: Props) => {
  const navigate = useNavigate();

  const { variant } = useGetStoreButtonInfo();

  const { isLoading, mutate } = useMutation(getAffiliateStoreOfferCode, {
    onSuccess: (data) => {
      window.location.assign(data.url);
    },
    onError: () => {
      navigate(AppRoutes.Base);
    },
  });

  const redirectToMobileStore = useCallback(() => {
    if (emailVerificationCode && publicCode) {
      mutate({
        platform: isIOSDevice
          ? AffiliatePlatform.IOS
          : AffiliatePlatform.ANDROID,
        publicCode,
        emailVerificationCode,
      });
    }
  }, [emailVerificationCode, mutate, publicCode]);

  return (
    <>
      <Subtitle>
        {`Download the app and sign up \n
          with ${email} to start your \n
          1 month free trial.`}
      </Subtitle>
      <StoreButtonWrapper>
        <MobileButton
          isLoading={isLoading}
          color={theme.colors.funtainBlue}
          variant={variant}
          onClick={redirectToMobileStore}
          isMobile
        />
      </StoreButtonWrapper>
    </>
  );
};

const StoreButtonWrapper = styled.div`
  width: 158px;
  margin: 245px auto 15px;
  position: relative;
  z-index: 10;
  left: -2px;
`;
