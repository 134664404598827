import React from 'react';
import styled from 'styled-components';

export const mobileKeyboardItems = [
  {
    mobileBtnNumber: 1,
    text: '',
  },
  {
    mobileBtnNumber: 2,
    text: 'ABC',
  },
  {
    mobileBtnNumber: 3,
    text: 'DEF',
  },
  {
    mobileBtnNumber: 4,
    text: 'GHI',
  },
  {
    mobileBtnNumber: 5,
    text: 'JKL',
  },
  {
    mobileBtnNumber: 6,
    text: 'MNO',
  },
  {
    mobileBtnNumber: 7,
    text: 'PQRS',
  },
  {
    mobileBtnNumber: 8,
    text: 'TUV',
  },
  {
    mobileBtnNumber: 9,
    text: 'WXYZ',
  },
  {
    mobileBtnNumber: 0,
    text: '',
  },
];

type MobileKeyboardProps = {
  buttonClick: (number: number) => void;
};

const MobileKeyboard = ({ buttonClick }: MobileKeyboardProps) => (
  <Container>
    {mobileKeyboardItems.map((item) => {
      const { mobileBtnNumber, text } = item;

      return (
        <ButtonWrapper key={mobileBtnNumber}>
          <Button
            data-testid={`mobile-num-${mobileBtnNumber}`}
            onClick={() => buttonClick(mobileBtnNumber)}
          >
            <Wrapper>
              <h5>{mobileBtnNumber}</h5>
              <p>{text}</p>
            </Wrapper>
          </Button>
        </ButtonWrapper>
      );
    })}
  </Container>
);

const Container = styled.div`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 100px;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 8px rgb(0 0 0 / 20%);
`;

const Button = styled.button`
  position: relative;
  background: ${({ theme }) => theme.colors.funtainBlue};
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  user-select: none;
`;

const Wrapper = styled.div`
  h5 {
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    font-weight: 400;
    font-size: 8px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default MobileKeyboard;
