import { ListItemData } from 'helpers';
import React, { useState } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

import { ErrorMessage } from '@hookform/error-message';
import ChevronIcon from 'components/Icons/ChevronIcon';
import theme from 'theme/theme';

import {
  ErrorText,
  FormInput,
  FormLabel,
  InputContainer,
} from '../styledElements/StyledFormInputs';

import PreviewList from './PreviewList';

type StandardDropDownProps = {
  isMobile?: boolean;
  errors: FieldErrors;
  inputId: string;
  data: ListItemData[];
  textLabel: string;
  chosenText: string;
  register: UseFormRegister<FieldValues>;
  setChosenText: (id: string) => void;
};

const StandardDropDown = ({
  isMobile,
  inputId,
  data,
  errors,
  textLabel,
  chosenText,
  register,
  setChosenText,
}: StandardDropDownProps) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const hasError = !!errors[inputId];

  return (
    <InputContainer $isMobile={isMobile} $error={hasError}>
      <FormLabel
        $isMobile={isMobile}
        $active={Boolean(chosenText)}
        htmlFor={textLabel}
        $error={hasError}
      >
        {textLabel}
      </FormLabel>
      <FormInput
        data-testid="input-dropdown"
        $isMobile={isMobile}
        id={textLabel}
        type="text"
        readOnly
        autoComplete="off"
        onFocus={() => setIsListOpen(true)}
        {...register(inputId, {
          required: 'Please select your department from the list',
        })}
        value={chosenText}
      />
      <IconWrapper onClick={() => setIsListOpen(true)}>
        <ChevronIcon
          color={hasError ? theme.colors.red : theme.colors.funtainBlue}
        />
      </IconWrapper>
      {errors[inputId] && (
        <ErrorMessage
          name={inputId}
          errors={errors}
          render={({ message }) => (
            <ErrorText $error={!!errors[inputId]}>
              <img src="icons/warningIcon.svg" alt="warning" />
              {message}
            </ErrorText>
          )}
        />
      )}

      {isListOpen && (
        <PreviewList
          data={data}
          label={textLabel}
          closeList={() => setIsListOpen(false)}
          chooseId={setChosenText}
        />
      )}
    </InputContainer>
  );
};

const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export default StandardDropDown;
