import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { FormInput, FormLabel } from '../styledElements/StyledFormInputs';

const SEARCH_INPUT_ID = 'SEARCH_INPUT_ID';

type SearchingBlockProps = {
  label: string;
  searchingValue?: string;
  setSearchingValue: (value: string) => void;
};

const SearchingBlock = ({
  label,
  searchingValue,
  setSearchingValue,
}: SearchingBlockProps) => {
  const { register, setValue, watch } = useForm();
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(SEARCH_INPUT_ID, value, { shouldValidate: true });
    setSearchingValue(value);
  };

  useEffect(() => {
    if (!watch()[SEARCH_INPUT_ID] && searchingValue) {
      setValue(SEARCH_INPUT_ID, searchingValue, { shouldValidate: true });
      setIsSearchInputActive(true);
    }
  }, [searchingValue, setValue, watch]);

  return (
    <InputContainer>
      <FormLabelForSearch
        htmlFor={SEARCH_INPUT_ID}
        $active={isSearchInputActive}
      >
        {label}
      </FormLabelForSearch>
      <FormInput
        id={SEARCH_INPUT_ID}
        autoComplete="off"
        {...register(SEARCH_INPUT_ID)}
        onChange={changeInputHandler}
        onFocus={() => setIsSearchInputActive(true)}
      />
    </InputContainer>
  );
};

const FormLabelForSearch = styled(FormLabel)`
  z-index: -1;
`;

const InputContainer = styled.div`
  position: relative;
  height: 60px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  box-sizing: border-box;
  width: 100%;
`;

export default SearchingBlock;
