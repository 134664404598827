import React from 'react';
import styled from 'styled-components';

type ButtonSize = 'sm' | 'md' | 'lg';
type BorderSize = 'sm' | 'md' | 'lg';

const buttonSizes = {
  sm: 38,
  md: 50,
  lg: 60,
};

const borderSizes = {
  sm: 42,
  md: 56,
  lg: 68,
};

type props = {
  color: string;
};

type Icon = (props: props) => JSX.Element;

export type ActionsButtonProps = {
  icon: Icon;
  iconColor: string;
  backColor: string;
  borderColor: string;
  borderSize: BorderSize;
  withShadow?: boolean;
  buttonSize: ButtonSize;
  isTypeSubmit?: boolean;
  disabled?: boolean;
  clickHandler?: () => void;
};

const ActionButton = ({
  icon,
  iconColor,
  backColor,
  isTypeSubmit,
  borderColor,
  borderSize,
  clickHandler,
  withShadow,
  buttonSize,
  disabled,
}: ActionsButtonProps) => (
  <ButtonWrapper
    $size={borderSizes[borderSize]}
    $color={borderColor}
    $withShadow={withShadow}
  >
    <Button
      data-testid="action-button"
      $color={backColor}
      onClick={() => {
        if (clickHandler) clickHandler();
      }}
      $size={buttonSizes[buttonSize]}
      $withShadow={withShadow}
      type={isTypeSubmit ? 'submit' : 'button'}
      disabled={disabled}
    >
      {React.createElement(icon, { color: iconColor })}
    </Button>
  </ButtonWrapper>
);

const ButtonWrapper = styled.div<{
  $size: number;
  $color: string;
  $withShadow?: boolean;
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  background: ${({ $color }) => $color};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ $withShadow }) =>
    $withShadow ? '0 5px 8px rgb(0 0 0 / 20%)' : 'none'};
`;

const Button = styled.button<{
  $color: string;
  $withShadow?: boolean;
  $size: number;
}>`
  position: relative;
  background: ${({ $color }) => $color};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  box-shadow: ${({ $withShadow }) =>
    $withShadow ? '0 5px 5px rgb(0 0 0 / 10%)' : 'none'};
  cursor: pointer;
`;

export default ActionButton;
