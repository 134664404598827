import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'components/Icons/Arrow';

import custom from '../images/custom.png';

type DetailsSectionProps = {
  intro: string;
  bodyText: string;
  isMobile?: boolean;
  goToNextSection: () => void;
};

const DetailsSection = (
  { isMobile, intro, bodyText, goToNextSection }: DetailsSectionProps,
  ref: React.Ref<HTMLDivElement>
) => (
  <Container $isMobile={isMobile} ref={ref}>
    <InfoContainer>
      <ParagraphWrapper>
        <FirstParagraph>{intro}</FirstParagraph>
        <SecondParagraph>{bodyText}</SecondParagraph>
      </ParagraphWrapper>
      <ImageWrapper>
        <img src={custom} />
      </ImageWrapper>
    </InfoContainer>
    <ArrowWrapper data-testid="details-arrow" onClick={goToNextSection}>
      <Arrow />
    </ArrowWrapper>
  </Container>
);

const ImageWrapper = styled.div`
  position: relative;
  width: 540px;
  height: 400px;

  @media (width <= 900px) {
    margin-top: 20px;
  }

  @media (width <= 480px) {
    margin-top: 32px;
    height: 303px;
  }

  img {
    position: absolute;
    object-fit: contain;
    top: -80px;
    right: 6px;

    @media (width <= 1400px) {
      height: 500px;
      top: unset;
    }

    @media (width <= 1100px) {
      height: 400px;
    }

    @media (width <= 900px) {
      right: unset;
      transform: translateX(-50%);
      left: 50%;
    }

    @media (width <= 480px) {
      height: 390px;
      left: 53%;
    }

    @media (width <= 350px) {
      height: 300px;
      left: 51%;
    }
  }
`;

const ArrowWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  position: relative;
  margin: 41px auto 0;
`;

const ParagraphWrapper = styled.div`
  word-break: break-word;
`;

const FirstParagraph = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 640px;
  font-weight: 400;
  line-height: 50px;
  top: -9px;
  position: relative;
  left: 6px;

  @media (width <= 1230px) {
    font-size: 25px;
  }

  @media (width <= 900px) {
    text-align: center;
  }

  @media (width <= 690px) {
    font-size: 20px;
    left: unset;
  }

  @media (width <= 480px) {
    font-size: 20px;
    line-height: 30px;
    left: unset;
  }
`;

const SecondParagraph = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 640px;
  margin-top: 43px;
  font-weight: 300;
  line-height: 50px;
  top: 0;
  padding-right: 12px;
  position: relative;
  left: 6px;

  @media (width <= 1230px) {
    font-size: 25px;
  }

  @media (width <= 900px) {
    text-align: center;
  }

  @media (width <= 690px) {
    font-size: 20px;
    line-height: 40px;
    padding: 0 10px;
    left: unset;
  }

  @media (width <= 480px) {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    margin-top: 14px;
  }
`;

const InfoContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (width <= 900px) {
    flex-direction: column;
  }
`;

const Container = styled.div<{ $isMobile?: boolean }>`
  max-width: 1412px;
  padding-bottom: 84px;
  position: relative;
  padding-top: 161px;
  border-top: 1px dashed rgb(216 218 224);
  border-bottom: 1px dashed rgb(216 218 224);
  z-index: 3;
  margin: 36px auto 0;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 41px 13px 47px 11px;
    `};
`;

export default React.forwardRef(DetailsSection);
