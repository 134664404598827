import React from 'react';

const WeightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.995"
    height="22"
    viewBox="0 0 23.995 22"
  >
    <path
      id="Path_4023"
      data-name="Path 4023"
      d="M440.576,161.448h3.559a5.888,5.888,0,0,1,10.9,0h3.557a2.931,2.931,0,0,1,2.937,3.614l-2.139,11.847a3.333,3.333,0,0,1-3.678,2.886H443.278c-2.009,0-3.355-.925-3.679-2.886l-1.961-11.847a2.937,2.937,0,0,1,2.937-3.614Zm11.37-.864-1.423,2.467a1.016,1.016,0,0,1,.112.464,1.05,1.05,0,1,1-1.05-1.032,1.069,1.069,0,0,1,.225.023l2.137-1.923Zm-6.611,2.845v.111a4.249,4.249,0,0,0,8.5,0l0-.106h0a4.251,4.251,0,0,0-8.494,0Z"
      transform="translate(-437.587 -157.795)"
      fill="#69b0c7"
      fillRule="evenodd"
    />
  </svg>
);

export default WeightIcon;
