import React from 'react';

const DecreaseIcon = (pros: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.013"
    height="9.513"
    viewBox="0 0 17.013 9.513"
    {...pros}
  >
    <defs>
      <clipPath id="decrease-clip-path">
        <path
          id="Path_14441"
          data-name="Path 14441"
          d="M10.177-6.4A.25.25,0,0,0,10-6.47a.25.25,0,0,0-.177.073L7.914-4.487a2,2,0,0,1-2.828,0L1.293-8.281a1,1,0,0,1-.273-.973,1,1,0,0,1,.714-.714,1,1,0,0,1,.973.273L6.323-6.079a.25.25,0,0,0,.177.073.25.25,0,0,0,.177-.073L8.586-7.988a2,2,0,0,1,2.828,0l4.013,4.013a.25.25,0,0,0,.353,0l1.366-1.367a.5.5,0,0,1,.354-.146.489.489,0,0,1,.191.038A.5.5,0,0,1,18-4.987v4a.5.5,0,0,1-.5.5h-4A.5.5,0,0,1,13.037-.8a.5.5,0,0,1,.109-.545l.867-.866a.25.25,0,0,0,0-.353Z"
          transform="translate(-0.988 10)"
          fill="#404d73"
        />
      </clipPath>
    </defs>
    <g
      id="Group_56730"
      data-name="Group 56730"
      transform="translate(0)"
      opacity="0.8"
    >
      <g
        id="Group_15431"
        data-name="Group 15431"
        transform="translate(0 0)"
        clipPath="url(#decrease-clip-path)"
      >
        <path
          id="Path_14440"
          data-name="Path 14440"
          d="M-4.012,4.513H23V-15H-4.012Z"
          transform="translate(-0.988 10)"
          fill="#404d73"
        />
      </g>
    </g>
  </svg>
);

export default DecreaseIcon;
