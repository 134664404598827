import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ActionButtonProps = {
  icon: ReactNode;
  clickHandler?: () => void;
};

const ActionInputButton = ({ icon, clickHandler }: ActionButtonProps) => (
  <Container onClick={clickHandler} type="button">
    {icon}
  </Container>
);

const Container = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
`;

export default ActionInputButton;
