import Slider from 'rc-slider';
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

import ActionButton from 'components/ActionButton';
import CloseIcon from 'components/Icons/CloseIcon';
import PauseIcon from 'components/Icons/PauseIcon';
import PlayIcon from 'components/Icons/PlayIcon';
import VolumeIcon from 'components/Icons/VolumeIcon';
import theme from 'theme/theme';
import 'rc-slider/assets/index.css';

import VolumeControl from './VolumeControl';

const SECONDS_IN_MINUTE = 60;

type ControlsProps = {
  isMobile?: boolean;
  isVolumeOpened: boolean;
  setIsVolumeOpened: (value: boolean) => void;
  isPlaying: boolean;
  currentTime: number;
  videoDuration: number;
  setCurrentTime: (value: number) => void;
  playerRef: RefObject<ReactPlayer | null>;
  setIsPlaying: (value: boolean) => void;
  volume: number;
  setVolume: (value: number) => void;
  closeVideo: () => void;
  stopOnMobile: () => void;
};

const Controls = forwardRef<HTMLDivElement, ControlsProps>(
  (
    {
      isMobile,
      isVolumeOpened,
      setIsVolumeOpened,
      isPlaying,
      currentTime,
      videoDuration,
      setCurrentTime,
      playerRef,
      setIsPlaying,
      volume,
      setVolume,
      closeVideo,
      stopOnMobile,
    },
    ref
  ) => {
    const [isChangingValue, setChangingValue] = useState(false);
    const isPlayingBeforeChangeValue = useRef(false);

    const formatTimecode = useCallback((timecode: number) => {
      const timecodeInSeconds = Math.trunc(timecode);
      const minutes = Math.trunc(timecodeInSeconds / SECONDS_IN_MINUTE);
      const seconds = timecodeInSeconds % SECONDS_IN_MINUTE;

      return `${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
    }, []);

    const handleSlidingStart = useCallback(() => {
      setChangingValue(true);

      if (isPlaying !== isPlayingBeforeChangeValue.current) {
        isPlayingBeforeChangeValue.current = true;
      }
    }, [isPlaying]);

    const handleSliderValueChange = useCallback(
      (value: number) => {
        setCurrentTime(value);

        if (value > videoDuration) {
          playerRef.current?.seekTo(videoDuration);
          setCurrentTime(videoDuration);
        } else {
          playerRef.current?.seekTo(value);
          setCurrentTime(value);
        }
      },
      [playerRef, setCurrentTime, videoDuration]
    );

    const handleSlidingFinish = useCallback(() => {
      setChangingValue(false);

      isPlayingBeforeChangeValue.current
        ? setIsPlaying(true)
        : setIsPlaying(false);

      isPlayingBeforeChangeValue.current = false;
    }, [isPlayingBeforeChangeValue, setIsPlaying]);

    const handleStyle = useMemo(() => {
      if (isChangingValue) {
        return {
          display: 'block',
          width: 20,
          height: 20,
          marginTop: -7,
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.darkBlueV2}`,
          borderRadius: '50%',
        };
      }

      return { display: 'none' };
    }, [isChangingValue]);

    return (
      <>
        <WrapperTest
          $isMobile={isMobile}
          $isVolumeOpened={isVolumeOpened}
          ref={ref}
        >
          <VideoControl $isVolumeOpened={isVolumeOpened} $isMobile={isMobile}>
            <TimeCode $currentTime $isMobile={isMobile}>
              {formatTimecode(currentTime)}
            </TimeCode>
            <StyledSlider
              value={currentTime}
              max={Math.round(videoDuration)}
              step={0.5}
              onChange={handleSliderValueChange}
              onBeforeChange={handleSlidingStart}
              onAfterChange={handleSlidingFinish}
              handleStyle={handleStyle}
              trackStyle={{
                height: 6,
                backgroundColor: theme.colors.white,
                border:
                  currentTime === 0
                    ? 'none'
                    : `1px solid ${theme.colors.darkBlueV2}`,
              }}
              railStyle={{
                height: 6,
                backgroundColor: theme.colors.darkBlueV2,
              }}
            />
            <TimeCode $isMobile={isMobile}>
              {formatTimecode(videoDuration)}
            </TimeCode>
          </VideoControl>
          <PlayButton $isVolumeOpened={isVolumeOpened}>
            <ActionButton
              iconColor={theme.colors.white}
              backColor={theme.colors.funtainBlue}
              borderColor={theme.colors.white}
              borderSize="sm"
              clickHandler={stopOnMobile}
              withShadow
              buttonSize="sm"
              icon={isPlaying ? PauseIcon : PlayIcon}
            />
          </PlayButton>
          <VolumeButton $isVolumeOpened={isVolumeOpened}>
            <ActionButton
              iconColor={theme.colors.funtainBlue}
              backColor={theme.colors.white}
              borderColor={theme.colors.darkYellow}
              borderSize="sm"
              clickHandler={() => setIsVolumeOpened(true)}
              buttonSize="sm"
              icon={VolumeIcon}
            />
          </VolumeButton>
          {!isVolumeOpened && (
            <CloseButton $isMobile={isMobile}>
              <ActionButton
                iconColor={theme.colors.funtainBlue}
                backColor={theme.colors.white}
                borderColor={theme.colors.darkYellow}
                borderSize="sm"
                clickHandler={closeVideo}
                buttonSize="sm"
                icon={CloseIcon}
              />
            </CloseButton>
          )}
          {isVolumeOpened && (
            <VolumeControlWrapper $isVolumeOpened={isVolumeOpened}>
              <VolumeControl
                setVolume={setVolume}
                volume={volume}
                isOpenVolume={isVolumeOpened}
                isMobile={isMobile}
              />
            </VolumeControlWrapper>
          )}
        </WrapperTest>
      </>
    );
  }
);

const StyledSlider = styled(Slider)`
  position: relative;
`;

const TimeCode = styled.div<{ $currentTime?: boolean; $isMobile?: boolean }>`
  position: absolute;
  top: 40px;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.primary};
  user-select: none;
  ${({ $currentTime }) =>
    !$currentTime
      ? css`
          right: 82px;
        `
      : css`
          left: 82px;
        `}
  ${({ $currentTime, $isMobile }) =>
    !$currentTime &&
    $isMobile &&
    css`
      right: 20px;
    `}
  ${({ $currentTime, $isMobile }) =>
    $currentTime &&
    $isMobile &&
    css`
      left: 20px;
    `}
`;

const WrapperTest = styled.div<{
  $isMobile?: boolean;
  $isVolumeOpened: boolean;
}>`
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%, 0);
  column-gap: 20px;
  width: 90%;
  height: 60px;
  display: grid;
  grid-template-columns: 42px 1fr 42px;
  grid-template-areas: 'play-button video-control volume-button';
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 5px;
  ${({ $isVolumeOpened }) =>
    $isVolumeOpened &&
    css`
      grid-template-areas: 'volume-control volume-control volume-control';
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      top: 115%;
      max-width: calc(100% - 40px);
      width: 85vw;
      height: 150px;
      padding: 25px 20px 0;
      grid-template:
        'video-control video-control video-control video-control video-control'
        '. close-button play-button volume-button .'
        10px 1fr
        / 1fr 42px 42px 1fr;

      ${({ theme }) => theme.breakpoints.up('sm')} {
        top: 50%;
        height: 100px;
      }
    `}
  ${({ $isMobile, $isVolumeOpened }) =>
    $isMobile &&
    $isVolumeOpened &&
    css`
      grid-template-areas:
        'video-control video-control video-control video-control video-control'
        'volume-control volume-control volume-control volume-control volume-control';
    `}
`;

const VolumeControlWrapper = styled.div<{ $isVolumeOpened: boolean }>`
  display: ${({ $isVolumeOpened }) => ($isVolumeOpened ? 'block' : 'none')};
  grid-area: volume-control;
`;

const VideoControl = styled.div<{
  $isVolumeOpened: boolean;
  $isMobile?: boolean;
}>`
  display: ${({ $isVolumeOpened }) => ($isVolumeOpened ? 'none' : 'block')};
  grid-area: video-control;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: block !important;
    `}
`;

const PlayButton = styled.div<{ $isVolumeOpened: boolean }>`
  display: ${({ $isVolumeOpened }) => ($isVolumeOpened ? 'none' : 'block')};
  grid-area: play-button;
`;

const VolumeButton = styled.div<{ $isVolumeOpened: boolean }>`
  display: ${({ $isVolumeOpened }) => ($isVolumeOpened ? 'none' : 'block')};
  grid-area: volume-button;
`;

const CloseButton = styled.div<{ $isMobile?: boolean }>`
  display: ${({ $isMobile }) => ($isMobile ? 'block' : 'none')};
  grid-area: close-button;
`;

Controls.displayName = 'Controls';

export default Controls;
