import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';
import { chatServiceApi } from 'modules/corporateOffer/services';
// import { AuthType } from 'modules/corporateOffer/types';

import { useChatContext } from '../../ChatContext';

import CodeAlreadyApplied from './CodeAlreadyApplied';
import EmailAddress from './EmailAddress';
import EmailPasscodeNotRecognized from './EmailPasscodeNotRecognized';
import InactiveSubscription from './InactiveSubscription';
import NewOfferCode from './NewOfferCode';
import Passcode from './Passcode';
// import SignInMethod from './SignInMethod';

type ExistingAccountProps = {
  isMobile?: boolean;
  isEditing: boolean;
};

const BAD_REQUEST = 'BAD_REQUEST';

export const FULL_PROGRESS_BAR = 100;

const ExistingAccount = ({ isEditing, isMobile }: ExistingAccountProps) => {
  const { userName, codeValue, setUserName } = useCorporateContext();
  const { changeProgress } = useChatContext();

  // const [isEmailShown, setIsEmailShown] = useState(false);
  const [isPasscodeShown, setIsPasscodeShown] = useState(false);
  const [isNotRecognizedAccountShown, setIsNotRecognizedAccountShown] =
    useState(false);
  const [isInactiveSubscription, setIsInactiveSubscription] = useState(false);
  const [isCodeAlreadyApplied, setIsCodeAlreadyApplied] = useState(false);
  const [isNewOfferCode, setIsNewOfferCode] = useState(false);
  const [dataForReplaceOfferCode, setDataForReplaceOfferCode] = useState({
    userId: '',
    token: '',
  });
  const [codeEndDate, setCodeEndDate] = useState<null | string>('');
  const [email, setEmail] = useState('');

  const [isSignInEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isPasscodeEditing, setIsPasscodeEditing] = useState(false);
  const [isNotRecognizedAccountEditing, setIsNotRecognizedAccountEditing] =
    useState(false);

  const isEmailVisible = !isSignInEditing;
  const isSectionsVisible =
    isEmailVisible && !isEmailEditing && !isPasscodeEditing;

  // const handleSignInStage = (method: AuthType, token?: string) => {
  //   if (method === AuthType.Email) {
  //     setIsEmailShown(true);

  //     return;
  //   }

  //   if (!token) return;

  //   setIsEmailShown(false);
  //   setIsPasscodeShown(false);
  //   setIsNotRecognizedAccountShown(false);

  //   handleSocialAccount(token || '');
  // };

  const toggleNotRecognizedAccountEditingStatus = useCallback(() => {
    setIsNotRecognizedAccountEditing(!isNotRecognizedAccountEditing);
  }, [isNotRecognizedAccountEditing]);

  const handleEmailAccount = async (passcode: string) => {
    try {
      const { succeeded, token } = await chatServiceApi.getAuthUserData(
        passcode,
        email
      );

      if (!succeeded) {
        throw new Error(BAD_REQUEST);
      }

      await getOfferCodeStatus(token, codeValue);
    } catch (e) {
      setIsNewOfferCode(false);
      setIsCodeAlreadyApplied(false);
      setIsInactiveSubscription(false);
      setIsNotRecognizedAccountShown(true);
    }
  };

  // const handleSocialAccount = async (token: string) => {
  //   try {
  //     await getOfferCodeStatus(token, codeValue);
  //   } catch (e) {
  //     setIsNewOfferCode(false);
  //     setIsCodeAlreadyApplied(false);
  //     setIsInactiveSubscription(false);
  //     setIsNotRecognizedAccountShown(true);
  //   }
  // };

  const getOfferCodeStatus = async (token: string, offerCode: string) => {
    const [userInfo, { firstName }] =
      await chatServiceApi.getAllUserInfo(token);

    if (!userInfo || !firstName) {
      throw new Error(BAD_REQUEST);
    }
    setUserName(firstName);

    const offerCodes = await chatServiceApi.getOfferCodes(userInfo.id, token);

    if (!offerCodes) {
      throw new Error(BAD_REQUEST);
    }

    if (!offerCodes.data.userCorporateOffer) {
      const userCodesResponse = await chatServiceApi.applyOfferCode(
        userInfo.id,
        offerCode,
        token
      );

      if (userCodesResponse) {
        setIsNewOfferCode(false);
        setIsCodeAlreadyApplied(false);
        setIsNotRecognizedAccountShown(false);

        return setIsInactiveSubscription(true);
      }
      throw new Error(BAD_REQUEST);
    }

    if (offerCodes.data.userCorporateOffer.code === offerCode) {
      setIsInactiveSubscription(false);
      setIsNewOfferCode(false);
      setIsNotRecognizedAccountShown(false);
      changeProgress(FULL_PROGRESS_BAR, true);

      return setIsCodeAlreadyApplied(true);
    }

    setDataForReplaceOfferCode({ userId: userInfo.id, token });
    if (offerCodes.data.userCorporateOffer.code !== offerCode) {
      setIsCodeAlreadyApplied(false);
      setIsNotRecognizedAccountShown(false);
      setIsInactiveSubscription(false);
      setCodeEndDate(offerCodes.data.userCorporateOffer.endDateUTC);

      return setIsNewOfferCode(true);
    }
    throw new Error(BAD_REQUEST);
  };

  return (
    <Container $isEditing={isEditing}>
      {/* <SignInMethod
        shown
        isEditing={isSignInEditing}
        isMobile={isMobile}
        goToNextStage={handleSignInStage}
        hideNextStages={() => {
          setIsEmailShown(false);
          setIsPasscodeShown(false);
          setIsNotRecognizedAccountShown(false);
        }}
        toggleEditingStatus={() => setIsSignInEditing((prev) => !prev)}
      /> */}
      {/* {isEmailShown && ( */}
      <EmailAddress
        setEmail={setEmail}
        isMobile={isMobile}
        isEditing={isEmailEditing}
        goToNextStage={() => setIsPasscodeShown(true)}
        toggleEditingStatus={() => setIsEmailEditing((prev) => !prev)}
        togglePasscodeStatus={setIsPasscodeEditing}
        shown={isEmailVisible}
      />
      {/* )} */}
      {isPasscodeShown && (
        <Passcode
          isEditing={isPasscodeEditing}
          goToNextStage={handleEmailAccount}
          isMobile={isMobile}
          shown={!isEmailEditing && !isSignInEditing}
          toggleEditingStatus={() => setIsPasscodeEditing((prev) => !prev)}
        />
      )}
      {isNotRecognizedAccountShown && (
        <EmailPasscodeNotRecognized
          isMobile={isMobile}
          isEditing={isNotRecognizedAccountEditing}
          shown={isSectionsVisible}
          toggleEditingStatus={toggleNotRecognizedAccountEditingStatus}
        />
      )}

      {isInactiveSubscription && (
        <InactiveSubscription
          userName={userName}
          isMobile={isMobile}
          shown={isSectionsVisible}
        />
      )}
      {isCodeAlreadyApplied && (
        <CodeAlreadyApplied
          userName={userName}
          isMobile={isMobile}
          shown={isSectionsVisible}
        />
      )}
      {isNewOfferCode && (
        <NewOfferCode
          userName={userName}
          dataForReplaceOfferCode={{
            ...dataForReplaceOfferCode,
            code: codeValue,
            codeEndDate,
          }}
          isMobile={isMobile}
          shown={isSectionsVisible}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isEditing: boolean }>`
  display: ${({ $isEditing }) => ($isEditing ? 'none' : 'block')};
`;

export default ExistingAccount;
