import { useQueryUrl } from 'hooks';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import { isMobile } from 'helpers/getDeviceInfo';
import Loader, {
  LoaderWrapper,
} from 'modules/corporateOffer/components/Loader';
import ShidoIsTheBestAssistantSection from 'modules/promotionOffer/components/ShidoIsTheBestAssistantSection';
import { AppRoutes } from 'router/models';
import theme from 'theme/theme';

import { IntroSection } from '../../modules/affiliateOffer/components/affiliateActivation';
import { getAffiliateEmail } from '../../modules/affiliateOffer/services';

export const AffiliateActivate = () => {
  const navigate = useNavigate();
  const values = useQueryUrl();

  const emailVerificationCode = values.code as string;

  const { isLoading, data } = useQuery(
    ['affiliateEmail', emailVerificationCode],
    () =>
      getAffiliateEmail({
        emailVerificationCode: encodeURIComponent(emailVerificationCode),
      }),
    {
      onError: () => {
        navigate(AppRoutes.Base);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isMobile) {
      document.body.style.background = theme.colors.sand;
    } else {
      document.body.style.background = theme.colors.primary;
    }

    return () => {
      document.body.style.background = theme.colors.white;
    };
  }, []);

  if (isLoading || !data)
    return (
      <LoaderWrapper>
        <Loader bigCircleSize={85} smallCircleSize={63} />
      </LoaderWrapper>
    );

  return (
    <Wrapper>
      <Container>
        <IntroSection
          email={data.email}
          emailVerificationCode={emailVerificationCode}
        />
        {!isMobile && <ShidoIsTheBestAssistantSection isHideArrow />}
        <ShidoIconWrapper $isMobile={isMobile}>
          <LogoIcon width={isMobile ? 48 : 70} height={isMobile ? 28 : 44} />
          <ShidoTitle>
            <ShidoMark>®</ShidoMark>
          </ShidoTitle>
        </ShidoIconWrapper>
      </Container>
      <hr />
    </Wrapper>
  );
};

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 2px;
  right: -21px;

  @media (width <= 480px) {
    top: 3px;
    right: -18px;
  }
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoIconWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: center;
  padding-top: 83px;
  margin: 0 auto 203px;
  background: ${({ theme }) => theme.colors.sand};
  z-index: 2;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 920px) {
        padding-top: 34px;
        margin: 0 auto 43px;
        position: relative;
        top: -1px;
      }
    `}

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div`
  max-width: 1920px;
  background-color: ${({ theme }) => theme.colors.sand};
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  background-color: rgb(58 70 101);
  position: relative;

  hr {
    background: ${({ theme }) => theme.colors.sand};
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 5px;
    bottom: -9px;
    border: none;
    max-width: 1920px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
