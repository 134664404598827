import { usePageTracking } from 'hooks';
import { reactPlugin } from 'lib';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import routes from 'router/routes';
import { GlobalStyle } from 'theme/globalStyles';
import theme from 'theme/theme';

import './fonts/F37GingerBold.ttf';
import './fonts/F37GingerLight.ttf';
import './fonts/F37GingerRegular.ttf';
import 'theme/loadFonts.css';

import CorporateContextProvider from './modules/corporateOffer/CorporateContext';

const queryClient = new QueryClient();

// Initialize app with contexts, providers, and other clients
function App() {
  usePageTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <StyleSheetManager enableVendorPrefixes>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CorporateContextProvider>{routes}</CorporateContextProvider>
          </ThemeProvider>
        </AppInsightsContext.Provider>
      </StyleSheetManager>
    </QueryClientProvider>
  );
}

export default App;
