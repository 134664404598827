import React from 'react';
import styled from 'styled-components';

type PlayIconProps = {
  color: string;
};

const PlayIcon = ({ color }: PlayIconProps) => (
  <PlaySvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8.9">
    <path
      id="Path_4158"
      data-name="Path 4158"
      d="M17,16.5v6.877a.992.992,0,0,0,1.5.9l5.981-3.488a.947.947,0,0,0,0-1.694L18.5,15.6A1.062,1.062,0,0,0,17,16.5Z"
      transform="translate(-17 -15.511)"
      fill={color}
    />
  </PlaySvg>
);

const PlaySvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-54%, -50%);
  width: 35%;
`;

export default PlayIcon;
