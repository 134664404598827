import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import styled, { css } from 'styled-components';

import ActionButton from 'components/ActionButton';
import CloseIcon from 'components/Icons/CloseIcon';
import theme from 'theme/theme';

import Controls from './Controls';

const VIDEO_URL =
  'https://videos.ctfassets.net/m61lhx9gr8sv/D2dJUCu7L43t9HIFiW7TL/e85dc42dc758c1435d8a9288083e7d03/SHIDO_INTRODUCTION_ENG_V6_COMPRESSED.mp4';

type PlayerProps = {
  isMobile?: boolean;
  isOpen: boolean;
  closeVideo: () => void;
};

const Player = ({ isMobile, isOpen, closeVideo }: PlayerProps) => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<ReactPlayer>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const wrapperVideoContainer = useRef(null);
  const [isVolumeOpened, setIsVolumeOpened] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    setIsPlaying(isOpen);
  }, [isMobile, isOpen]);

  const exitFullScreen = useCallback(() => {
    const element = wrapperVideoContainer.current || undefined;

    if (element) {
      if (screenfull.isEnabled && screenfull.isFullscreen) {
        screenfull.toggle(element);
      }
    }
  }, []);

  useEffect(() => {
    if (isMobile && isOpen) {
      const element = wrapperVideoContainer.current || undefined;

      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        screenfull.request(element, { navigationUI: 'hide' });
      }
    }

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isMobile, isOpen]);

  useEffect(() => {
    const keyDownEvent = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeVideo();
        setIsVolumeOpened(false);
      }
    };

    const handleMouseClick = (event: MouseEvent) => {
      if (
        !videoWrapperRef?.current?.contains(event.target as Node) &&
        !isMobile
      ) {
        closeVideo();
        setIsVolumeOpened(false);
      } else if (
        !controlsRef?.current?.contains(event.target as Node) &&
        isMobile
      ) {
        if (!isVolumeOpened && isOpen) {
          setShowControls(!showControls);
        } else if (isVolumeOpened && isOpen) {
          setIsVolumeOpened(false);
        }
      }
    };

    const savedVolume = localStorage.getItem('volume');

    if (savedVolume) {
      setVolume(JSON.parse(savedVolume));
    }

    document.addEventListener('keydown', keyDownEvent);
    document.addEventListener('mousedown', handleMouseClick);

    return () => {
      document.removeEventListener('keydown', keyDownEvent);
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, [
    showControls,
    isVolumeOpened,
    isOpen,
    closeVideo,
    isMobile,
    exitFullScreen,
  ]);

  useEffect(() => {
    const changePlayingStatus = () => {
      if (document.visibilityState === 'visible') {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    };

    document.addEventListener('visibilitychange', changePlayingStatus);

    return () =>
      document.removeEventListener('visibilitychange', changePlayingStatus);
  }, []);

  const toggleVideoPLayer = useCallback(() => {
    if (isVolumeOpened) {
      setIsVolumeOpened(false);
    } else if (!isMobile) {
      setIsPlaying(!isPlaying);
    }
  }, [isMobile, isPlaying, isVolumeOpened]);

  const onVideoProgress = useCallback(
    ({ playedSeconds }: { playedSeconds: number }) => {
      if (isPlaying) {
        setCurrentTime(playedSeconds);
      }
    },
    [setCurrentTime, isPlaying]
  );

  const onDuration = useCallback(
    (duration: number) => setVideoDuration(duration),
    []
  );

  const onVideoEnd = useCallback(() => {
    videoRef.current?.seekTo(0);
    setIsPlaying(false);
  }, []);

  return (
    <Wrapper $isOpen={isOpen} $isMobile={isMobile}>
      <div ref={wrapperVideoContainer}>
        <VideoWrapper $isMobile={isMobile} ref={videoWrapperRef}>
          <div onClick={toggleVideoPLayer}>
            <ReactPlayer
              playing={isPlaying}
              url={VIDEO_URL}
              width="100%"
              height="100%"
              volume={volume}
              onProgress={onVideoProgress}
              onDuration={onDuration}
              onEnded={onVideoEnd}
              ref={videoRef}
              playsinline
              muted={!isOpen}
            />
          </div>
          {showControls && (
            <>
              {!isMobile && (
                <CloseButton>
                  <ActionButton
                    iconColor={theme.colors.funtainBlue}
                    backColor={theme.colors.white}
                    borderColor={theme.colors.darkYellow}
                    borderSize="sm"
                    clickHandler={closeVideo}
                    withShadow
                    buttonSize="sm"
                    icon={CloseIcon}
                  />
                </CloseButton>
              )}
              <Controls
                isMobile={isMobile}
                isVolumeOpened={isVolumeOpened}
                setIsVolumeOpened={setIsVolumeOpened}
                isPlaying={isPlaying}
                currentTime={currentTime}
                videoDuration={videoDuration}
                setCurrentTime={setCurrentTime}
                playerRef={videoRef}
                setIsPlaying={setIsPlaying}
                volume={volume}
                setVolume={setVolume}
                closeVideo={() => {
                  exitFullScreen();
                  closeVideo();
                }}
                ref={controlsRef}
                stopOnMobile={() => setIsPlaying(!isPlaying)}
              />
            </>
          )}
        </VideoWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isOpen: boolean; $isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ $isMobile }) =>
    $isMobile ? 'black' : 'rgba(0, 0, 0, 0.8)'};
  width: 100vw;
  height: 100vh;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
`;

const VideoWrapper = styled.div<{ $isMobile?: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1300px;
  width: calc(100vw - 100px);
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100vw;
      margin: 0;
    `}
`;

const CloseButton = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
`;

export default Player;
