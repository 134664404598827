import * as React from 'react';

export const LogoIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 46.41 28.667"
    {...props}
  >
    <g
      id="Group_4194"
      data-name="Group 4194"
      transform="translate(-2678.77 -471.54)"
    >
      <path
        id="Path_3964"
        data-name="Path 3964"
        d="M2720.986,481.413a14.356,14.356,0,0,0-20.28,0l-6.654,6.654a4.929,4.929,0,0,0-1.363,4.4,4.733,4.733,0,0,0,.78,1.881,4.936,4.936,0,0,0,7.557.7l4.4-4.4a1.411,1.411,0,0,1,.862-.423,1.342,1.342,0,0,1,1.072,2.286l-6.655,6.655a10.752,10.752,0,1,1-3.043-17.337.214.214,0,0,0,.242-.042l1.583-1.582c.253-.254.515-.5.783-.729a.216.216,0,0,0-.031-.351,14.334,14.334,0,1,0,3,22.578l6.655-6.655a4.938,4.938,0,0,0-.486-7.4,4.858,4.858,0,0,0-2.373-.978,4.915,4.915,0,0,0-4.115,1.406l-4.4,4.4a1.41,1.41,0,0,1-.861.423,1.342,1.342,0,0,1-1.459-1.461,1.41,1.41,0,0,1,.423-.861l6.618-6.618a10.751,10.751,0,1,1,3.043,17.337.214.214,0,0,0-.243.042l-1.582,1.582q-.379.379-.783.73a.216.216,0,0,0,.031.35,14.34,14.34,0,0,0,17.275-22.579Z"
        transform="translate(0 -5.68)"
      />
    </g>
  </svg>
);
