import { convertDate } from 'helpers';
import React, { useState } from 'react';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';
import { chatServiceApi } from 'modules/corporateOffer/services';
import { AnswerVariant } from 'modules/corporateOffer/types';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import ChoiceAnswer from '../../chatActions/ChoiceAnswer';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { FULL_PROGRESS_BAR } from './ExistingAccount';

type DataForReplaceOfferCode = {
  userId: string;
  token: string;
  code: string;
  codeEndDate: string | null;
};
type NewOfferCodeProps = {
  isMobile?: boolean;
  userName: string;
  shown: boolean;
  dataForReplaceOfferCode: DataForReplaceOfferCode;
};

const NewOfferCode = ({
  isMobile,
  userName,
  shown,
  dataForReplaceOfferCode,
}: NewOfferCodeProps) => {
  const { onChatSuccess } = useCorporateContext();
  const { changeProgress } = useChatContext();

  const [isFirstDelayShown, setFirstDelay] = useState(false);
  const [isSecondDelayShown, setSecondDelay] = useState(false);
  const [showActionContainer, setShowActionContainer] = useState(false);
  const [showUserAnswer, setShowUserAnswer] = useState(false);
  const [userAnswer, setUserAnswer] = useState(false);
  const [responseToUser, setResponseToUser] = useState(false);
  const [isThirdDelayShown, setThirdDelay] = useState(false);

  const keepOfferCodeHandler = () => {
    setShowActionContainer(false);
    setUserAnswer(true);
    setShowUserAnswer(true);
    changeProgress(FULL_PROGRESS_BAR, true);
  };

  const replaceOfferCodeHandler = async () => {
    setShowActionContainer(false);
    const { code, token, userId } = dataForReplaceOfferCode;

    await chatServiceApi.replaceOfferCode(userId, code, token);
    changeProgress(FULL_PROGRESS_BAR, true);
    setUserAnswer(false);
    setShowUserAnswer(true);
  };

  const activeOfferMessage = dataForReplaceOfferCode.codeEndDate
    ? `You already have an active offer code, this will expire on ${convertDate(
        dataForReplaceOfferCode.codeEndDate
      )}`
    : 'You already have an active offer code';

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message={`Hi ${userName}!`}
        isMobile={isMobile}
        goToNextStep={() => {
          setFirstDelay(true);
        }}
      />
      {isFirstDelayShown && (
        <ChatMessage
          message={activeOfferMessage}
          isMobile={isMobile}
          goToNextStep={() => setSecondDelay(true)}
        />
      )}
      {isSecondDelayShown && (
        <ChatMessage
          message="Would you like to replace it with this offer?"
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}

      {showUserAnswer && (
        <ChatMessage
          isOutgoing
          message={userAnswer ? 'Keep active offer' : 'Replace offer'}
          isMobile={isMobile}
          goToNextStep={() => setResponseToUser(true)}
        />
      )}
      {responseToUser && (
        <ChatMessage
          message={userAnswer ? 'OK, offer kept' : 'OK, offer replaced..'}
          isMobile={isMobile}
          goToNextStep={() => setThirdDelay(true)}
        />
      )}
      {isThirdDelayShown && (
        <ChatMessage
          withLongDelay
          isMobile={isMobile}
          goToNextStep={onChatSuccess}
        />
      )}
      {showActionContainer && (
        <ChoiceAnswer
          isMobile={isMobile}
          onClickFirstHandler={keepOfferCodeHandler}
          onClickSecondHandler={replaceOfferCodeHandler}
          choiceType={
            isMobile
              ? AnswerVariant.NewOfferCode
              : AnswerVariant.NewOfferCodeMobile
          }
        />
      )}
    </StageContainer>
  );
};

export default NewOfferCode;
