import { filterDataForDropdownList, ListItemData } from 'helpers';
import { useOutsideClick } from 'hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

import ChevronIcon from 'components/Icons/ChevronIcon';
import theme from 'theme/theme';

import ActionInputButton from './ActionInputButton';
import SearchingBlock from './SearchingBlock';

import 'simplebar-react/dist/simplebar.min.css';

type PreviewListProps = {
  label: string;
  data: ListItemData[];
  closeList: () => void;
  chooseId: (id: string) => void;
};

const PreviewList = ({
  label,
  data,
  closeList,
  chooseId,
}: PreviewListProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [searchingValue, setSearchingValue] = useState('');

  const setNewSearchingValue = useCallback(
    (value: string) => setSearchingValue(value),
    []
  );

  const filteredData = useMemo(
    () => filterDataForDropdownList(data, searchingValue),
    [data, searchingValue]
  );

  useOutsideClick(listRef, closeList);

  return (
    <PreviewContainer ref={listRef}>
      <SearchingBlockContainer>
        <SearchingBlock
          setSearchingValue={setNewSearchingValue}
          label={label}
        />
        <ActionButtonContainer>
          <ActionInputButton
            icon={<ChevronIcon color={theme.colors.funtainBlue} />}
            clickHandler={closeList}
          />
        </ActionButtonContainer>
      </SearchingBlockContainer>
      <SimpleBar autoHide={false} style={{ height: '240px' }}>
        {filteredData.map(({ displayName, id }) => (
          <PreviewListItem
            data-testid={`department-${id}`}
            key={id}
            onClick={() => {
              closeList();
              chooseId(id);
            }}
          >
            {displayName}
          </PreviewListItem>
        ))}
      </SimpleBar>
    </PreviewContainer>
  );
};

export const PreviewContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgb(0 0 0 / 60%);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
  z-index: 12;

  .simplebar-track.simplebar-vertical {
    width: 4px;
    top: 19px;
    bottom: 19px;
    background: rgb(235 235 235 / 80%);
    border-radius: 5px;
    right: 10px;
  }

  .simplebar-scrollbar.simplebar-visible {
    width: 4px;
    background: ${({ theme }) => theme.colors.funtainBlue};
    border-radius: 5px;
  }

  .simplebar-content-wrapper {
    border-radius: 5px;
  }
`;

const PreviewListItem = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  font-weight: 400;
  margin-right: 20px;
  transform: scale(1) !important;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 12px;

  &:hover {
    background-color: rgb(251 251 252);
  }
`;

const SearchingBlockContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
`;

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export default PreviewList;
