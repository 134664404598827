import React, { useState } from 'react';

import { AnswerVariant } from 'modules/corporateOffer/types';

import ChoiceAnswer from '../chatActions/ChoiceAnswer';
import { useChatContext } from '../ChatContext';
import ChatMessage from '../ChatMessage';

const CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION = 12.5;

type InitialStageProps = {
  isMobile?: boolean;
  isEditing: boolean;
  toggleEditingStatus: () => void;
  goToNextStage: (value: boolean) => void;
};

const InitialStage = ({
  isMobile,
  isEditing,
  goToNextStage,
  toggleEditingStatus,
}: InitialStageProps) => {
  const { changeProgress } = useChatContext();

  const [showQuestion, setShowQuestion] = useState(false);
  const [showDelay, setShowDelay] = useState(false);
  const [showActionContainer, setShowActionContainer] = useState(false);
  const [showUserAnswer, setShowUserAnswer] = useState(false);
  const [userAnswer, setUserAnswer] = useState<boolean>(false);

  return (
    <>
      <ChatMessage
        message="Hi, welcome to Shido!"
        isMobile={isMobile}
        goToNextStep={() => {
          changeProgress(CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION / 2);
          setShowQuestion(true);
        }}
      />
      {showQuestion && (
        <ChatMessage
          message="Do you already have a shido account?"
          isMobile={isMobile}
          goToNextStep={() => {
            setShowDelay(true);
            changeProgress(CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION / 2);
          }}
        />
      )}
      {showDelay && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {showUserAnswer && (
        <ChatMessage
          isOutgoing
          message={userAnswer ? 'Yes' : 'No'}
          isMobile={isMobile}
          goToNextStep={() => goToNextStage(userAnswer)}
          lastMessage
          toggleEditingStatus={toggleEditingStatus}
        />
      )}
      {(showActionContainer || isEditing) && (
        <ChoiceAnswer
          isMobile={isMobile}
          onClickFirstHandler={() => {
            if (isEditing && userAnswer !== true) {
              changeProgress(
                CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION * 2,
                true
              );
            }

            setShowActionContainer(false);
            setUserAnswer(true);
            setShowUserAnswer(true);
            if (isEditing) {
              goToNextStage(true);
              toggleEditingStatus();
            } else {
              changeProgress(CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION);
            }
          }}
          onClickSecondHandler={() => {
            if (isEditing && userAnswer !== false) {
              changeProgress(
                CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION * 2,
                true
              );
            }

            setShowActionContainer(false);
            setShowUserAnswer(true);
            setUserAnswer(false);
            if (isEditing) {
              goToNextStage(false);
              toggleEditingStatus();
            } else {
              changeProgress(CHAT_COMPLETION_PERCENTAGE_ACCOUNT_QUESTION);
            }
          }}
          choiceType={AnswerVariant.YesOrNo}
        />
      )}
    </>
  );
};

export default InitialStage;
