import React, { useEffect } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import styled, { css, useTheme } from 'styled-components';

import DashedCircle from 'components/DashedCircle';
import SlideSelector from 'modules/promotionOffer/components/SlideSelector';

import { DesktopCarouselData } from './carouselData';

const AUTOPLAY_INTERVAL = 6000;
const AMOUNT_OF_DASHED_CIRCLES = 7;

type Props = {
  isDesktop: boolean;
  slides: DesktopCarouselData[];
};

const YourPartnerCarousel = ({ slides, isDesktop }: Props) => {
  const theme = useTheme();

  const { scrollRef, activePageIndex, pages, snapPointIndexes, goTo } =
    useSnapCarousel();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activePageIndex === pages.length - 1) {
        goTo(0);
      } else {
        goTo(activePageIndex + 1);
      }
    }, AUTOPLAY_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [activePageIndex, pages, goTo]);

  return (
    <Container>
      <Scroll ref={scrollRef}>
        {slides.map((item, i) => (
          <Content key={item.id} $isSnapPoint={snapPointIndexes.has(i)}>
            <Wrapper>
              <Row>
                {item.items.firstRow.map((item) => (
                  <RowItem key={item.id}>
                    <ImageContainer>
                      <ImageWrapper>
                        <img src={item.imageSrc} alt={item.text} />
                      </ImageWrapper>
                    </ImageContainer>
                    <p>{item.text}</p>
                  </RowItem>
                ))}
              </Row>
              <Row $isContentRight>
                {item.items.secondRow.map((item) => (
                  <RowItem key={item.id} $isSecondRow>
                    <p>{item.text}</p>
                    <ImageContainer>
                      <ImageWrapper>
                        <img src={item.imageSrc} alt={item.text} />
                      </ImageWrapper>
                    </ImageContainer>
                  </RowItem>
                ))}
              </Row>
              {isDesktop && (
                <Circles>
                  {Array.from(Array(AMOUNT_OF_DASHED_CIRCLES).keys()).map(
                    (circle) => (
                      <DashedCircle
                        $size={40}
                        key={circle}
                        $borderColor={theme.colors.secondary}
                        $background
                      />
                    )
                  )}
                </Circles>
              )}
            </Wrapper>
          </Content>
        ))}
      </Scroll>
      <SelectorContainer aria-hidden>
        <SlideSelector
          slides={slides}
          activeIdx={activePageIndex}
          interval={AUTOPLAY_INTERVAL}
          clickHandler={goTo}
        />
      </SelectorContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  max-width: 1542px;
`;

const SelectorContainer = styled.div`
  margin-top: 68px;

  @media (width <= 1300px) {
    margin-top: 30px;
  }

  @media (width <= 1024px) {
    margin-bottom: 20px;
  }

  @media (width <= 450px) {
    margin-bottom: 0;
  }
`;

const Scroll = styled.ul`
  position: relative;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  box-sizing: border-box;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  width: 170px;
  height: 170px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    width: 100%;
    height: 100%;
  }

  @media (width <= 1300px) {
    top: -2px;
    width: 120px;
    height: 120px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 1542px;
  width: 100%;
  margin: 0 auto;
  padding: 0 69px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (width <= 1550px) {
    max-width: 1300px;
  }

  @media (width <= 1300px) {
    max-width: 1000px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 750px;
    padding: 0;
    overflow: hidden;
  }
`;

const Content = styled.div<{ $isSnapPoint: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  ${({ $isSnapPoint }) =>
    $isSnapPoint &&
    css`
      scroll-snap-align: start;
    `}
`;

const ImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  background: white;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border: 2px dashed ${({ theme }) => theme.colors.secondary};
    z-index: 25;

    @media (width <= 1300px) {
      width: 100px;
      height: 100px;
    }
  }

  @media (width <= 1300px) {
    width: 100px;
    height: 100px;
  }
`;

const Row = styled.div<{ $isContentRight?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 100px;
  align-items: center;
  justify-content: ${({ $isContentRight }) =>
    $isContentRight ? 'end' : 'start'};

  ${({ theme }) => theme.breakpoints.down('md')} {
    column-gap: 15px;
  }
`;

const RowItem = styled.div<{ $isSecondRow?: boolean }>`
  display: flex;
  position: relative;

  p {
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 170px;
    width: fit-content;
    transform: translate(0, -50%);

    @media (width <= 1300px) {
      font-size: 14px;
      left: 120px;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      left: 110px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      white-space: pre-line;
    }

    ${({ $isSecondRow }) =>
      $isSecondRow &&
      css`
        right: 170px;
        left: unset;

        @media (width <= 1300px) {
          left: unset;
          right: 120px;
        }

        ${({ theme }) => theme.breakpoints.down('md')} {
          right: 110px;
        }
      `};
  }

  div {
    margin-left: ${({ $isSecondRow: isSecondRow }) => isSecondRow && 'auto'};
  }
`;

const Circles = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  transform: translate(0, -50%);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, 40px);
  grid-column-gap: 210px;

  @media (width <= 1550px) {
    justify-content: center;
    grid-template-columns: repeat(4, 40px);
    grid-column-gap: 370px;

    div:nth-child(5),
    div:nth-child(6),
    div:nth-child(7) {
      display: none;
    }
  }

  @media (width <= 1300px) {
    grid-column-gap: 280px;

    div {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
  }
`;

export default YourPartnerCarousel;
