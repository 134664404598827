import React, { useState } from 'react';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import ChatMessage from '../../ChatMessage';

type EmailAddressProps = {
  isMobile?: boolean;
  userName: string;
  shown: boolean;
};

const CodeAlreadyApplied = ({
  isMobile,
  userName,
  shown,
}: EmailAddressProps) => {
  const [isFirstDelayShown, setFirstDelay] = useState(false);
  const [isSecondDelayShown, setSecondDelay] = useState(false);
  const { onChatSuccess } = useCorporateContext();

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message={`Hi ${userName}!`}
        isMobile={isMobile}
        goToNextStep={() => {
          setFirstDelay(true);
        }}
      />
      {isFirstDelayShown && (
        <ChatMessage
          message="This code has already been applied to your account..."
          isMobile={isMobile}
          goToNextStep={() => setSecondDelay(true)}
        />
      )}
      {isSecondDelayShown && (
        <ChatMessage
          withLongDelay
          isMobile={isMobile}
          goToNextStep={onChatSuccess}
        />
      )}
    </StageContainer>
  );
};

export default CodeAlreadyApplied;
