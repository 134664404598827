import config from 'config';
import React from 'react';
import QRCode from 'react-qr-code';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { AppRoutes } from 'router/models';
import theme from 'theme/theme';

type Props = {
  emailVerificationCode: string;
  email: string;
  publicCode: string | undefined;
};

export const DesktopIntroSection = ({
  emailVerificationCode,
  publicCode,
  email,
}: Props) => {
  const affiliateUrlQR = publicCode
    ? `${config.shidoMeVersion}${generatePath(
        `${AppRoutes.AffiliateBase}/:publicCode/${AppRoutes.AffiliateQR}`,
        {
          publicCode: encodeURIComponent(publicCode),
          emailVerificationCode: encodeURIComponent(emailVerificationCode),
        }
      )}`
    : null;

  if (!affiliateUrlQR) return null;

  return (
    <>
      <Subtitle>
        {`Scan the code with your mobile device and sign up with
          ${email} to start your 1 month free trial.`}
      </Subtitle>
      <QrCodeContainer>
        <QrCodeContainerInfo>
          <QRCode
            value={affiliateUrlQR}
            size={170}
            bgColor={theme.colors.primary}
            fgColor={theme.colors.white}
          />
        </QrCodeContainerInfo>
      </QrCodeContainer>
      <BackgroundMountains />
    </>
  );
};

const BackgroundMountains = styled.img`
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 0);
  content: url('/images/affiliateActivateBackground.png');
`;

export const QrCodeContainerInfo = styled.div`
  position: relative;
  z-index: 10;
  width: 300px;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.colors.pinkLight};
  align-items: center;
`;

export const QrCodeContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin: 80px auto 210px;
`;

export const Subtitle = styled.div`
  font-size: 20px;
  position: relative;
  z-index: 10;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
  margin-top: 28px;
  padding: 0 10px;

  @media (width <= 480px) {
    width: 317px;
    font-size: 20px;
    margin: 62px auto 0;
    line-height: 30px;
    word-break: break-word;
    padding: 0;
  }

  @media (width <= 350px) {
    padding: 0;
  }
`;
