import { axiosClient } from 'lib';

import { ProductDetails, SignUpPlan } from '../types';

export const getProducts = async (): Promise<ProductDetails[]> => {
  const response = await axiosClient.get(`/api/v6/Billing/Products`);
  const prices = await getProductPrices(
    response.data.payload.data.products[0].id
  );

  const [annualData, monthlyData] = prices;

  return [
    { ...annualData, signUpPlan: SignUpPlan.ANNUAL, isRecommended: true },
    { ...monthlyData, signUpPlan: SignUpPlan.MONTHLY, isRecommended: false },
  ];
};

const getProductPrices = async (
  productId: string
): Promise<ProductDetails[]> => {
  const response = await axiosClient.get(
    `/api/v6/Billing/Products/${productId}/prices`
  );

  return response.data.payload.data.prices;
};
