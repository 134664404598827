import React from 'react';
import { Navigate } from 'react-router-dom';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';

import { AppRoutes } from '../models';

type Props = {
  children: JSX.Element;
};

const PrivateChatRoute = ({ children }: Props) => {
  const { isValidCode } = useCorporateContext();

  return isValidCode ? children : <Navigate to={AppRoutes.Corporate} replace />;
};

export default PrivateChatRoute;
