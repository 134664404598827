import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { StageContainer } from 'modules/corporateOffer/components/styledElements/StyledStages';
import TermsAndConditionsPopup from 'modules/corporateOffer/components/TermsAndConditionsPopup';
import { AnswerVariant } from 'modules/corporateOffer/types';

import ChoiceAnswer from '../../chatActions/ChoiceAnswer';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION } from './CreateAccount';

type TermsAndConditionsAgreementProps = {
  isMobile?: boolean;
  isEditing: boolean;
  shown: boolean;
  goToNextStage: () => void;
  toggleEditingStatus: () => void;
};

const TermsAndConditionsAgreement = ({
  isMobile,
  isEditing,
  shown,
  goToNextStage,
  toggleEditingStatus,
}: TermsAndConditionsAgreementProps) => {
  const navigate = useNavigate();
  const { changeProgress } = useChatContext();

  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [isDelayShown, setShowDelay] = useState(false);
  const [userAnswer, setUserAnswer] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [isPopUpWasOpened, setIsPopUpWasOpened] = useState(!!isEditing);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(true);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(true);

  const toggleIsAgreeTermsAndConditions = () => {
    setAcceptedTermsAndConditions(!acceptedTermsAndConditions);
  };
  const toggleIsAgreePrivacePolicy = () => {
    setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy);
  };

  const isTotalAgree =
    acceptedTermsAndConditions && acceptedPrivacyPolicy && isPopUpWasOpened;

  const openOpenDocumentAgreement = () => {
    if (!isEditing) {
      toggleEditingStatus();
      setIsOpenPopup(true);
      setIsPopUpWasOpened(true);

      return;
    }
    if (isEditing) {
      setIsOpenPopup(true);
    }
  };

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message={
          <div>
            To proceed please confirm that you have read and agree to our&nbsp;
            <PopupLink
              data-testid="pop-up-link"
              onClick={openOpenDocumentAgreement}
            >
              terms and conditions
            </PopupLink>
          </div>
        }
        isMobile={isMobile}
        goToNextStep={() => setShowDelay(true)}
      />
      {isDelayShown && (
        <TypingContainer $isOpened={isPopUpWasOpened}>
          <ChatMessage
            isMobile={isMobile}
            goToNextStep={() =>
              !isPopUpWasOpened && setShowActionContainer(true)
            }
          />
        </TypingContainer>
      )}
      {userAnswer && !isEditing && (
        <ChatMessage
          isOutgoing
          message="I agree"
          isMobile={isMobile}
          goToNextStep={goToNextStage}
          toggleEditingStatus={toggleEditingStatus}
          lastMessage
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <ChoiceAnswer
          isDisabled={!isTotalAgree}
          isMobile={isMobile}
          onClickFirstHandler={() => navigate(-1)}
          onClickSecondHandler={() => {
            if (!isTotalAgree) {
              return;
            }
            setShowActionContainer(false);
            setUserAnswer(true);
            if (isEditing) {
              goToNextStage();
              toggleEditingStatus();
            }
            if (!isEditing) {
              changeProgress(
                CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION
              );
            }
          }}
          isEditing={isEditing}
          onClickThirdHandler={() => {
            setIsOpenPopup(true);
            setIsPopUpWasOpened(true);
          }}
          choiceType={AnswerVariant.TermsAndConditions}
        />
      )}
      {isOpenPopup && (
        <TermsAndConditionsPopup
          isMobile={isMobile}
          acceptedTermsAndConditions={acceptedTermsAndConditions}
          acceptedPrivacyPolicy={acceptedPrivacyPolicy}
          toggleIsAgreeTermsAndConditions={toggleIsAgreeTermsAndConditions}
          toggleIsAgreePrivacyPolicy={toggleIsAgreePrivacePolicy}
          closePopup={() => setIsOpenPopup(false)}
        />
      )}
    </StageContainer>
  );
};

const TypingContainer = styled.div<{ $isOpened?: boolean }>`
  display: ${({ $isOpened }) => ($isOpened ? 'none' : 'block')};
`;

const PopupLink = styled.span`
  color: ${({ theme }) => theme.colors.funtainBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default TermsAndConditionsAgreement;
