import styled, { css } from 'styled-components';

import { CodeStatus } from 'modules/corporateOffer/types';

export const FormCodeContainer = styled.form<{
  $isMobile?: boolean;
  $isActive: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ $isMobile }) => ($isMobile ? '335px' : '100%')};
  height: 60px;
  background: rgb(255 255 255 / 80%);
  margin: 0 20px;
  align-items: flex-end;
  border-bottom: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  ${({ $isActive }) =>
    $isActive &&
    css`
      border: none;
    `}
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      background: none;
      justify-content: center;
      border: none;
    `}
`;

export const FormInputCode = styled.input<{
  $verificationCodeState: CodeStatus;
  $isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 30px;
  text-align: center;
  font-size: 16px;
  outline: none;
  border: none;
  margin-right: 20px;
  border-bottom: 2px solid
    ${({ theme, $verificationCodeState }) =>
      $verificationCodeState === CodeStatus.Invalid
        ? theme.colors.red
        : theme.colors.funtainBlue};
  font-weight: 400;
  background: none;
  padding: 0;
  color: ${({ theme, $verificationCodeState }) =>
    $verificationCodeState === CodeStatus.Invalid
      ? theme.colors.red
      : theme.colors.funtainBlue};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      font-size: 20px;
    `}
`;

export const FormContainer = styled.form<{
  $isMobile?: boolean;
  $error: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ $isMobile }) => ($isMobile ? '335px' : '100%')};
  height: 50px;
  background: rgb(255 255 255 / 80%);
  margin: 0 20px;
  border-bottom: 2px solid
    ${({ theme, $error }) =>
      $error ? theme.colors.red : theme.colors.funtainBlue};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      background: none;
      margin: 0;
      width: 90%;
    `}
`;

export const FormInput = styled.input<{
  $error: boolean;
  $isMobile?: boolean;
}>`
  color: ${({ theme, $isMobile }) =>
    $isMobile ? theme.colors.darkBlue : theme.colors.funtainBlue};
  width: ${({ $isMobile }) => ($isMobile ? '335px' : '100%')};
  height: 100%;
  font-size: 16px;
  outline: none;
  padding: 5px 40px 0 0;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  ${({ $error, theme }) =>
    $error &&
    css`
      color: ${theme.colors.red};
    `};
`;

export const FormLabel = styled.label<{
  $error: boolean;
  $active: boolean;
  $isEditing?: boolean;
  $isMobile?: boolean;
}>`
  color: ${({ theme, $active, $isMobile }) =>
    $active && $isMobile ? theme.colors.funtainBlue : theme.colors.darkBlue};
  font-size: ${({ $active: active }) => (active ? '11px' : '16px')};
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  top: ${({ $active }) => ($active ? '4px' : '65%')};
  transform: translate(0, ${({ $active }) => ($active ? '0' : '-50%')});
  transition:
    top 0.5s,
    font-size 0.5s;
  cursor: ${({ $active }) => ($active ? 'default' : 'text')};
  ${({ $error: error, theme }) =>
    error &&
    css`
      color: ${theme.colors.red};
    `};
  ${({ $isEditing }) =>
    $isEditing &&
    css`
      transition: all 0s;
    `}
`;

export const ErrorText = styled.div<{ $error?: boolean }>`
  position: absolute;
  top: 110%;
  display: flex;
  column-gap: 5px;
  align-items: center;
  color: ${({ theme }) => theme.colors.red} !important;
  visibility: ${({ $error }) => ($error ? 'visible' : 'hidden')};
  font-weight: 400;
  font-size: 12px;
`;

export const ChatActionsContainer = styled.div<{
  $isMobile?: boolean;
  $isNotAbsolute?: boolean;
}>`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgb(0 0 0 / 10%);
  height: 90px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ $isMobile }) => ($isMobile ? '20px' : '40px')};
  background-color: white;
  z-index: 30;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border: none;
      background-color: #f7f7f2;
    `}
  ${({ $isNotAbsolute }) =>
    $isNotAbsolute &&
    css`
      position: relative;
    `}
`;

export const ChatActionsColContainer = styled(ChatActionsContainer)`
  flex-direction: column;
  gap: 14px;
  height: auto;
  padding: 0 20px 5px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 0;
    gap: 20px;
  }
`;
