import React, { memo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

type Props = {
  src: string | undefined;
  accountName: string;
};

const OfferLogo = ({ src, accountName }: Props) => {
  const [visible, setVisible] = useState(true);

  return (
    <LogoWrapper>
      {!!src && visible && (
        <ReactSVG
          fallback={() => (
            <img
              src={src}
              alt={accountName}
              onError={() => setVisible(false)}
            />
          )}
          src={src}
        />
      )}
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  overflow: hidden;

  img,
  svg {
    object-fit: contain;
    max-width: 200px;
    height: 60px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 50px;
    margin-top: 43px;

    img,
    svg {
      height: 50px;
    }
  }
`;

export default memo(OfferLogo);
