export type ListItemData = {
  id: string;
  icon?: string;
  displayName: string;
  displayNameBE?: string;
};

export const filterDataForDropdownList = (
  data: ListItemData[],
  value: string
) => {
  const priorityData: ListItemData[] = [];
  const otherData: ListItemData[] = [];

  data.forEach((item) => {
    const name = item.displayName.toLowerCase();

    if (name.includes(value.toLowerCase())) {
      if (item.displayName.startsWith(value)) {
        priorityData.push(item);
      } else {
        otherData.push(item);
      }
    }
  });

  return [...priorityData, ...otherData];
};
