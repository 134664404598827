export enum Currency {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
}

export type Currencies = {
  GBP: string;
  USD: string;
  EUR: string;
};

export type CurrencyCode = keyof Currencies;

export const CURRENCY_SYMBOLS: Record<Currency, string> = {
  [Currency.EUR]: '€',
  [Currency.USD]: '$',
  [Currency.GBP]: '£',
};
