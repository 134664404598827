const OFFER_PICKER = {
  sevendays: 'SevenDays',
  onemonth: 'OneMonth',
  threemonths: 'ThreeMonths',
};

export const convertPromoOffer = (code: string | undefined) => {
  if (!code) return '';

  const [offerName, offerCode] = code.toLowerCase().split('-');

  return `${OFFER_PICKER[offerName as keyof typeof OFFER_PICKER]}-${offerCode}`;
};
