import config from 'config';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import getDeviceInfo, { OS } from 'helpers/getDeviceInfo';

export const ShidoQR = () => {
  useEffect(() => {
    if (getDeviceInfo().name === OS.Ios) {
      window.location.replace(config.appStoreLink);
    } else {
      window.location.replace(config.googlePlayLink);
    }
  }, []);

  return (
    <Container>
      <p>Redirect...</p>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};

  p {
    font-size: 40px;
    font-weight: 400;
  }
`;
