import React from 'react';

type VolumeIconProps = {
  color: string;
};

const VolumeIcon = ({ color }: VolumeIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.983"
    height="13"
    viewBox="0 0 12.983 13"
  >
    <g id="Group_4313" data-name="Group 4313" transform="translate(-311 -748)">
      <path
        id="Path_4120"
        data-name="Path 4120"
        d="M248.519,2354.09l-4.242,4.243h-2.039a1.24,1.24,0,0,0-1.238,1.238v1.857a1.24,1.24,0,0,0,1.238,1.238h2.039l4.242,4.243a.31.31,0,0,0,.529-.219v-12.381A.31.31,0,0,0,248.519,2354.09Z"
        transform="translate(70 -1605.999)"
        fill={color}
      />
      <path
        id="Path_4121"
        data-name="Path 4121"
        d="M258.506,2358.071a.31.31,0,0,0-.394.478,4.358,4.358,0,0,1,0,6.951.31.31,0,0,0,.395.478A4.979,4.979,0,0,0,258.506,2358.071Z"
        transform="translate(63.524 -1607.524)"
        fill={color}
      />
      <path
        id="Path_4122"
        data-name="Path 4122"
        d="M256.491,2360.56a.309.309,0,1,0-.364.5,2.205,2.205,0,0,1,0,3.833.309.309,0,1,0,.364.5A2.82,2.82,0,0,0,256.491,2360.56Z"
        transform="translate(64.286 -1608.476)"
        fill={color}
      />
    </g>
  </svg>
);

export default VolumeIcon;
