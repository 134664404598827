import config from 'config';
import { axiosClient } from 'lib';

import { getToken } from '../helpers';

type UserInfo = {
  id: string;
};

type UserProfile = {
  firstName: string;
};

export const chatServiceApi = {
  getUserInfo: async (token: string): Promise<UserInfo> => {
    const userData = await axiosClient.get(
      `/api/v${config.apiVersion}/Account/UserInfo`,
      {
        headers: getToken(token),
      }
    );

    return userData.data.payload.data.userInfo;
  },
  getUserProfile: async (token: string): Promise<UserProfile> => {
    const { data } = await axiosClient.get(
      `/api/v${config.nextApiVersion}/Profile/MyProfile`,
      {
        headers: getToken(token, true),
      }
    );

    return data.payload.profile;
  },
  getAuthUserData: async (password: string, email: string) => {
    const authUserData = await axiosClient.post(
      `/api/v${config.apiVersion}/Token/PasswordToken`,
      {
        password,
        email,
      }
    );

    return authUserData.data.payload;
  },
  async getAllUserInfo(token: string) {
    const arrayOfData = await Promise.all([
      this.getUserInfo(token),
      this.getUserProfile(token),
    ]);

    return arrayOfData;
  },

  getOfferCodes: async (userId: string, token: string) => {
    const offerCodes = await axiosClient.get(
      `/api/v${config.nextApiVersion}/users/${userId}/OfferCodes`,
      {
        headers: getToken(token),
      }
    );

    return offerCodes.data.payload;
  },
  applyOfferCode: async (userId: string, offerCode: string, token: string) => {
    const { data } = await axiosClient.post(
      `/api/v${
        config.apiVersion
      }/users/${userId}/offerCode/${encodeURIComponent(offerCode)}/apply`,
      undefined,
      {
        headers: getToken(token, true),
      }
    );

    return data.payload;
  },
  async applyOfferCodeToSocialAccount(token: string, codeValue: string) {
    const userInfo = await this.getUserInfo(token);

    await this.applyOfferCode(userInfo.id, codeValue, token);
  },
  replaceOfferCode: async (
    userId: string,
    offerCode: string,
    token: string
  ) => {
    const response = await axiosClient.put(
      `/api/v${
        config.apiVersion
      }/users/${userId}/offerCode/${encodeURIComponent(offerCode)}/replace `,
      undefined,
      {
        headers: getToken(token, true),
      }
    );

    return response.data.payload;
  },
};

export const applyOfferCodeToNewAccount = async (
  password: string,
  email: string,
  codeValue: string
) => {
  const { token } = await chatServiceApi.getAuthUserData(password, email);
  const [userInfo] = await chatServiceApi.getAllUserInfo(token);

  chatServiceApi.applyOfferCode(userInfo.id, codeValue, token);
};
