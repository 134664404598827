import * as React from 'react';

export const DashedCircleIcon = ({
  width,
  height,
  color,
}: {
  width: number;
  height: number;
  color: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 141.364 141.364"
  >
    <g
      id="Group_5193"
      data-name="Group 5193"
      transform="translate(140.364 1) rotate(90)"
    >
      <path
        id="Path_688"
        data-name="Path 688"
        d="M69.682,0A69.682,69.682,0,1,1,0,69.682,69.682,69.682,0,0,1,69.682,0Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeDasharray="5 25 80"
      />
    </g>
  </svg>
);
