import { Colors } from 'types';

export const PROMO_COLOR_PICKER: Colors = {
  Sand: {
    light: 'white',
    strong: '#F7F7F2',
  },
  BurntSienna: {
    light: '#F0C9C4',
    strong: '#ECB8B1',
  },
  Sea: {
    light: '#E1EDF1',
    strong: '#D7E7ED',
  },
  Stone: {
    light: '#F2F3F3',
    strong: '#E8E9EB',
  },
  Blush: {
    light: '#F1DCE0',
    strong: '#ECD1D7',
  },
  Pistachio: {
    light: '#D0DCD2',
    strong: '#C0D1C5',
  },
  Sunlight: {
    light: '#FDF5DE',
    strong: '#FDEDC1',
  },
};
