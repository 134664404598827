import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

type DashedLineProps = ComponentPropsWithoutRef<'div'>;

const DashedLine = ({ style }: DashedLineProps) => (
  <DashedLineContainer style={style}>
    <hr />
  </DashedLineContainer>
);

const DashedLineContainer = styled.div`
  max-width: 450px;
  margin: auto;

  hr {
    display: block;
    margin: 0;
    height: 1px;
    border: 0;
    background-image: url('/images/dashedLine.svg');
  }
`;

export default DashedLine;
