export type SuccessCarouselData = {
  id: number;
  name: string;
  text: string;
  mobileText: string;
  imageSrc: string;
};

const ASSISTANT_IMAGE_PATH = '/images/shidoIsTheBestAssistant';

export const SuccessStoriesCarouselData: SuccessCarouselData[] = [
  {
    id: 1,
    name: 'John Skelly - User since 2021',
    text: 'I’ve always struggled with weight loss, but Shido has been a game-changer for me. The personalised plans, expert advice,\nand daily motivation have helped me achieve my goals while feeling supported. Now I feel healthier and more confident!',
    mobileText:
      'I’ve always struggled with weight loss, but Shido has been a game-changer for me. The personalised plans, expert advice,\nand daily motivation have helped me achieve my goals while feeling supported. Now I feel healthier and more confident!',
    imageSrc: `${ASSISTANT_IMAGE_PATH}/john-skelly.png`,
  },
  {
    id: 2,
    name: 'Mille Gallo - User since 2022',
    text: "As a busy entrepreneur, stress and lack of sleep used to be part of my daily routine. Then I found Shido. With its relaxation techniques\nand sleep guidance, I've improved my sleep quality and reduced my stress levels significantly. Couldn't recommend it more!",
    mobileText:
      "As a busy entrepreneur, stress and lack of sleep used to be part of my daily routine. Then I found Shido. With its relaxation techniques\nand sleep guidance, I've improved my sleep quality and reduced my stress levels significantly. Couldn't recommend it more!",
    imageSrc: `${ASSISTANT_IMAGE_PATH}/millie-gallo.png`,
  },
  {
    id: 3,
    name: 'Kailia Balewa - User since 2023',
    text: "Shido is my daily companion for maintaining my energy levels and focus. The insights I get from the app on my lifestyle\nhabits have significantly boosted my productivity. It's like having a personal wellness coach in my pocket!",
    mobileText:
      "Shido is my daily companion for maintaining my energy levels and focus. The insights I get from the app on my lifestyle\nhabits have significantly boosted my productivity. It's like having a personal wellness coach in my pocket!",
    imageSrc: `${ASSISTANT_IMAGE_PATH}/kailia-balewa.png`,
  },
];
