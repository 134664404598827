import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';
import {
  checkValidPinCode,
  registerNameAndEmail,
} from 'modules/corporateOffer/services';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import PinCodeAction from '../../chatActions/PinCode';
import PinCodeMobileAction from '../../chatActions/PinCodeMobile';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION } from './CreateAccount';

const REMOVE_ERROR_DELAY = 2000;

type PinCodeProps = {
  pincode: string;
  email: string;
  isMobile?: boolean;
  shown: boolean;
  isEditing: boolean;
  goToNextStage: () => void;
  setPinCode: (pincode: string) => void;
  toggleEditingStatus: () => void;
  togglePassCodeEditingStatus: () => void;
};

const PinCode = ({
  pincode,
  email,
  isMobile,
  shown,
  isEditing,
  goToNextStage,
  toggleEditingStatus,
  togglePassCodeEditingStatus,
  setPinCode,
}: PinCodeProps) => {
  const { changeProgress } = useChatContext();
  const { userName } = useCorporateContext();

  const [isDelayShown, setShowDelay] = useState(false);
  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);

  const goToPassCodeStage = useCallback(() => {
    togglePassCodeEditingStatus();
    toggleEditingStatus();
  }, [toggleEditingStatus, togglePassCodeEditingStatus]);

  const { mutate } = useMutation(checkValidPinCode, {
    onSuccess: () => {
      setIsSuccessRequest(true);
      setShowActionContainer(false);
      if (isEditing) {
        toggleEditingStatus();
      }

      if (!isEditing) {
        changeProgress(CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION);
      }
    },
    onError: () => {
      setErrorText('You entered wrong pincode, please repeat again');
      setTimeout(() => {
        setErrorText('');
      }, REMOVE_ERROR_DELAY);
    },
  });

  const applyPincode = useCallback(
    (pin: string) => {
      setPinCode(pin);
      mutate({
        pincode: pin,
        email,
      });
    },
    [email, mutate, setPinCode]
  );

  const sendPinCode = useCallback(() => {
    registerNameAndEmail({
      firstName: userName,
      email,
    });
  }, [email, userName]);

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message="We sent a PIN to your email, please enter it now to continue."
        isMobile={isMobile}
        goToNextStep={() => {
          setShowDelay(true);
        }}
      />
      {isDelayShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {pincode && !isEditing && isSuccessRequest && (
        <ChatMessage
          isOutgoing
          message={pincode.split('').join(' ')}
          isMobile={isMobile}
          goToNextStep={goToNextStage}
          toggleEditingStatus={goToPassCodeStage}
          lastMessage
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <>
          {isMobile ? (
            <PinCodeMobileAction
              errorText={errorText}
              clickHandler={sendPinCode}
              goToNextStep={applyPincode}
            />
          ) : (
            <PinCodeAction
              clickHandler={sendPinCode}
              errorText={errorText}
              goToNextStep={applyPincode}
            />
          )}
        </>
      )}
    </StageContainer>
  );
};

export default PinCode;
