import React from 'react';

type SmallWarningIconProps = {
  color: string;
};

const SmallWarningIcon = ({ color }: SmallWarningIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="12.998"
    height="12.501"
    viewBox="0 0 12.998 12.501"
  >
    <defs>
      <clipPath id="small-warn-clip-path">
        <path
          id="Path_4463"
          data-name="Path 4463"
          d="M12.836-2.1,7.78-11.73A1.448,1.448,0,0,0,6.5-12.5a1.448,1.448,0,0,0-1.28.771L.164-2.1A1.428,1.428,0,0,0,.212-.691,1.452,1.452,0,0,0,1.444,0H11.556a1.453,1.453,0,0,0,1.232-.685,1.428,1.428,0,0,0,.048-1.41ZM5.75-8.5a.75.75,0,0,1,.75-.75.75.75,0,0,1,.75.75v3a.75.75,0,0,1-.75.75.75.75,0,0,1-.75-.75ZM6.5-1.75a1,1,0,0,1-1-1,1,1,0,0,1,1-1,1,1,0,0,1,1,1,1,1,0,0,1-1,1Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_10914"
      data-name="Group 10914"
      transform="translate(-0.001 12.501)"
    >
      <g
        id="Group_4674"
        data-name="Group 4674"
        clipPath="url(#small-warn-clip-path)"
      >
        <path
          id="Path_4462"
          data-name="Path 4462"
          d="M-5-17.5H18V5H-5Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default SmallWarningIcon;
