import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'router/models';

import { getAffiliateOffer } from '../services';
import { AffiliateOffer } from '../types';

export const useGetAffiliateOffer = (
  publicCode: string | undefined,
  setOfferData: (data: AffiliateOffer) => void
) => {
  const navigate = useNavigate();

  return useQuery(
    ['affiliateOffer', publicCode],
    () => {
      if (publicCode) {
        return getAffiliateOffer(publicCode);
      }

      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          setOfferData(data);
        }
      },
      onError: () => {
        navigate(AppRoutes.Base);
      },
      enabled: !!publicCode,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};
