import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isMobile } from 'helpers/getDeviceInfo';
import Loader, {
  LoaderWrapper,
} from 'modules/corporateOffer/components/Loader';
import { PROMO_COLOR_PICKER } from 'modules/promotionOffer/constants';

import CheckoutSection from '../../modules/affiliateOffer/components/CheckoutSection';
import DetailsSection from '../../modules/affiliateOffer/components/DetailsSection';
import EmailSection from '../../modules/affiliateOffer/components/EmailSection';
import IntroSection from '../../modules/affiliateOffer/components/IntroSection';
import ResponseSection from '../../modules/affiliateOffer/components/ResponseSection';
import { useGetAffiliateOffer } from '../../modules/affiliateOffer/hooks';
import useCreateRefsForClick from '../../modules/affiliateOffer/hooks/useCreateRefsForClick';
import {
  AffiliateOffer,
  ConversionFunnel,
} from '../../modules/affiliateOffer/types';

export const Affiliate = () => {
  const {
    handleRefClick,
    refs: [detailsRef, responseRef, footerRef],
  } = useCreateRefsForClick();

  const { code } = useParams<{ code: string }>();

  const [offerData, setOfferData] = useState<AffiliateOffer>();

  useGetAffiliateOffer(code, setOfferData);

  if (!offerData) {
    return (
      <LoaderWrapper>
        <Loader bigCircleSize={85} smallCircleSize={63} />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <Container $color={PROMO_COLOR_PICKER[offerData.backgroundColour].strong}>
        <IntroSection
          name={offerData.affiliateName}
          image={offerData.imageUrl}
          handle={offerData.affiliateHandle}
          chosenColor={PROMO_COLOR_PICKER[offerData.backgroundColour]}
          publicCode={offerData.publicCode}
          goToNextSection={handleRefClick(detailsRef)}
          isMobile={isMobile}
        />
        <DetailsSection
          ref={detailsRef}
          goToNextSection={handleRefClick(responseRef)}
          bodyText={offerData.bodyText}
          intro={offerData.intro}
          isMobile={isMobile}
        />
        <ResponseSection
          ref={responseRef}
          name={offerData.affiliateName}
          image={offerData.imageUrl}
          handle={offerData.affiliateHandle}
          statement={offerData.affiliateStatement}
          isCheckout={offerData.conversionFunnel === ConversionFunnel.Web}
          goToNextSection={handleRefClick(footerRef)}
        />
        {offerData.conversionFunnel === ConversionFunnel.App ? (
          <EmailSection
            color={offerData.backgroundColour}
            image={offerData.imageUrl}
            ref={footerRef}
            isMobile={isMobile}
            publicCode={code}
          />
        ) : (
          <CheckoutSection ref={footerRef} publicCode={offerData.publicCode} />
        )}
        <GraphBackground
          $isCheckout={offerData.conversionFunnel === ConversionFunnel.Web}
          $color={PROMO_COLOR_PICKER[offerData.backgroundColour].light}
        />
      </Container>
    </Wrapper>
  );
};

const Container = styled.div<{ $color: string }>`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const GraphBackground = styled.div<{ $color: string; $isCheckout: boolean }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/layout.svg') no-repeat 50% 50%;
  mask-position: ${({ $isCheckout }) =>
      $isCheckout ? 'bottom -575px' : 'bottom -1670px'}
    right 50%;

  @media (width <= 600px) {
    background-color: ${({ $color }) => $color};
    mask: url('/images/mobileLayout.svg') no-repeat 50% 50%;
    mask-position: bottom -24px right 50%;
  }
`;
