export enum AppRoutes {
  Base = '/',
  BetterHealth = '/betterhealth',
  ShidoQR = '/shido-qr',
  Invitation = '/invite/:month',
  Corporate = '/:account',
  CorporateBase = '/corporate',
  CorporateChat = 'chat',
  CorporateSuccess = 'success',
  PromotionBase = '/promotion',
  PromotionActivation = ':publicCode/activate',
  PromotionOffer = ':promotionTerm/:code',
  AffiliateBase = '/affiliate',
  AffiliateOffer = ':code',
  AffiliateActivate = 'activate',
  AffiliateQR = ':emailVerificationCode',
  AffiliateInstruction = 'offer/instruction',
}
