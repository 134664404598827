import React from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import CloseIcon from 'components/Icons/CloseIcon';
import theme from 'theme/theme';

import {
  DeleteValueButton,
  ErrorText,
  FormInput,
  FormLabel,
  InputContainer,
} from '../styledElements/StyledFormInputs';

// const NOT_VALID_ERROR = 'NOT_VALID_ERROR';

type Props = {
  active: boolean;
  isMobile?: boolean;
  label: string;
  inputId: string;
  errors: FieldErrors;
  inputValue?: string;
  value: string;
  setActive: () => void;
  setFocus: UseFormSetFocus<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  enterValue: (value: string) => void;
};

const StandardInput = ({
  active,
  isMobile,
  label,
  errors,
  inputId,
  value,
  setActive,
  setFocus,
  register,
  setValue,
  enterValue,
}: Props) => {
  const hasError = !!errors[inputId];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    enterValue(value);
  };

  const deleteInputValue = () => {
    setValue(inputId, '');
    setFocus(inputId);
  };

  return (
    <InputContainer $isMobile={isMobile} $error={hasError}>
      <FormLabel
        $isMobile={isMobile}
        htmlFor={inputId}
        $active={active}
        $error={hasError}
      >
        {label}
      </FormLabel>
      <FormInput
        $isMobile={isMobile}
        id={inputId}
        type="text"
        autoComplete="off"
        {...register(inputId, {
          required: 'This field is required',
        })}
        onFocus={setActive}
        $error={hasError}
        onChange={changeInputHandler}
        value={value}
      />
      {hasError && (
        <DeleteValueButton onClick={deleteInputValue}>
          <CloseIcon color={theme.colors.red} />
        </DeleteValueButton>
      )}

      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <ErrorText $error={!!errors[inputId]}>
            <img src="icons/warningIcon.svg" alt="warning" />
            {message}
          </ErrorText>
        )}
      />
    </InputContainer>
  );
};

export default StandardInput;
