import Bowser from 'bowser';

export enum DeviceType {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}

export enum OS {
  Android = 'Android',
  Ios = 'iOS',
}

const getDeviceInfo = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return {
    platform: browser.getPlatform().type,
    name: browser.getOS().name,
  };
};

export const isMobile = getDeviceInfo().platform === DeviceType.Mobile;
export const isIOSDevice = getDeviceInfo().name === OS.Ios;
export const isAndroid = getDeviceInfo().name === OS.Android;

export default getDeviceInfo;
