import config from 'config';
import { axiosClient } from 'lib';

import { AffiliateOffer } from '../types';

export const getAffiliateOffer = (
  publicCode: string
): Promise<AffiliateOffer> =>
  axiosClient
    .get(
      `/api/v${config.nextApiVersion}/AffiliateOffers?publicCode=${publicCode}`
    )
    .then((response) => response.data.payload.data);

type AffiliateEmailPayload = {
  publicCode: string;
  email: string;
};

export type FailedAffiliateEmail = {
  payload: { Errors: string };
};

export const sendAffiliateEmail = ({
  publicCode,
  email,
}: AffiliateEmailPayload) =>
  axiosClient
    .post(
      `/api/v${config.nextApiVersion}/affiliatecodes/${publicCode}/sendemail`,
      {
        email,
      }
    )
    .then((response) => response.data.payload);
