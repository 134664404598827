import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        // eslint-disable-next-line camelcase
        page_path: location.pathname,
      });
    }
  }, [location.pathname]);
};
