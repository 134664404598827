import React from 'react';

import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';
import DesktopSuccess from 'modules/corporateOffer/components/DesktopSuccess';
import MobileSuccess from 'modules/corporateOffer/components/MobileSuccess';
import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';

export const CorporateOfferSuccess = () => {
  const { userName, images } = useCorporateContext();
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  return (
    <>
      {isMobile ? (
        <MobileSuccess userName={userName} images={images} />
      ) : (
        <DesktopSuccess userName={userName} images={images} />
      )}
    </>
  );
};
