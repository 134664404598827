import styled from 'styled-components';

type Props = {
  $size: number;
  $borderColor: string;
  $background?: boolean;
};

const DashedCircle = styled.div<Props>`
  width: ${({ $size: size }) => size}px;
  height: ${({ $size: size }) => size}px;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 100;
  border: 2px dashed ${({ $borderColor }) => $borderColor};
  background: ${({ $background, theme }) =>
    $background ? theme.colors.white : 'none'};
`;

export default DashedCircle;
