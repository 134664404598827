import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { useGetCurrency } from '../hooks';
import { CURRENCY_SYMBOLS } from '../types';

type Props = {
  children: ReactNode;
  isFreemium?: boolean;
  isTopic?: boolean;
  hideOnMobile?: boolean;
  isMobile?: boolean;
};

const PlanPrice = ({
  children,
  hideOnMobile,
  isFreemium,
  isTopic,
  isMobile,
}: Props) => {
  const { clientCurrency } = useGetCurrency();

  return (
    <Container
      $isTopic={isTopic}
      $hideOnMobile={hideOnMobile}
      $isFreemium={isFreemium}
      $isMobile={isMobile}
    >
      {clientCurrency !== 'EUR' && (
        <span>{CURRENCY_SYMBOLS[clientCurrency]}</span>
      )}
      {children}
      {clientCurrency === 'EUR' && (
        <span>{CURRENCY_SYMBOLS[clientCurrency]}</span>
      )}
    </Container>
  );
};

const Container = styled.div<{
  $isFreemium?: boolean;
  $isTopic?: boolean;
  $hideOnMobile?: boolean;
  $isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $isTopic }) => ($isTopic ? '116px' : '96px')};
  height: ${({ $isTopic }) => ($isTopic ? '116px' : '96px')};
  border-radius: 50%;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ $isFreemium, theme }) =>
    $isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 52px;
      height: 52px;
    `}

  & span {
    font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '20px')};
    line-height: 24px;
    color: ${({ $isFreemium: isFreemium, theme }) =>
      isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
    margin-bottom: 15px;
  }

  & p {
    font-size: ${({ $isMobile }) => ($isMobile ? '30px' : '40px')};
    font-weight: 700;
    line-height: 40px;
    color: ${({ $isFreemium, theme }) =>
      $isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
  }

  & small {
    font-size: ${({ $isMobile }) => ($isMobile ? '10px' : '14px')};
    line-height: 40;
    font-weight: 700;
    color: ${({ $isFreemium, theme }) =>
      $isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

export default PlanPrice;
