import React, { useCallback, useState } from 'react';

import { AnswerVariant } from 'modules/corporateOffer/types';

import { StageContainer } from '../../../components/styledElements/StyledStages';
import ChoiceAnswer from '../../chatActions/ChoiceAnswer';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';
import CreateAccount, {
  CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION,
} from '../createAccount/CreateAccount';

import ExistingAccount from './ExistingAccount';

const TRY_AGAIN = 'Try again';
const CREATE_NEW_ACCOUNT = 'Create new account';

type EmailPasscodeNotRecognizedProps = {
  shown: boolean;
  isMobile?: boolean;
  isEditing: boolean;
  toggleEditingStatus: () => void;
};

const EmailPasscodeNotRecognized = ({
  shown,
  isMobile,
  isEditing,
  toggleEditingStatus,
}: EmailPasscodeNotRecognizedProps) => {
  const { changeProgress } = useChatContext();

  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [isDelayShown, setShowDelay] = useState(false);
  const [isUserCreateShown, setShowUserCreate] = useState(false);
  const [isUserExistShown, setShowUserExist] = useState(false);

  const chooseAnswer = useCallback(
    (answer: string, showUserCreate: boolean, showUserExist: boolean) => {
      changeProgress(
        CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION * 2,
        true
      );
      setUserAnswer(answer);
      setShowActionContainer(false);
      if (isEditing) {
        setShowUserCreate(showUserCreate);
        setShowUserExist(showUserExist);
        toggleEditingStatus();
      }
    },
    [changeProgress, isEditing, toggleEditingStatus]
  );

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        isMobile={isMobile}
        message="Email & passcode not recognised"
        goToNextStep={() => setShowDelay(true)}
      />
      {isDelayShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {userAnswer && !isEditing && (
        <ChatMessage
          isOutgoing
          message={userAnswer}
          isMobile={isMobile}
          goToNextStep={() => {
            if (userAnswer === TRY_AGAIN) {
              setShowUserExist(true);
            } else {
              setShowUserCreate(true);
            }
          }}
          toggleEditingStatus={toggleEditingStatus}
          lastMessage
        />
      )}
      <CreateAccount
        isMobile={isMobile}
        isEditing={isEditing || !isUserCreateShown}
      />
      <ExistingAccount
        isMobile={isMobile}
        isEditing={isEditing || !isUserExistShown}
      />
      {(isActionContainerShown || isEditing) && (
        <ChoiceAnswer
          withWarning
          textWarning={
            isMobile
              ? 'Please confirm your account in the Shido app'
              : 'Please confirm your account details in the Shido app'
          }
          isMobile={isMobile}
          choiceType={
            isMobile
              ? AnswerVariant.NotRecognizedAccountMobile
              : AnswerVariant.NotRecognizedAccount
          }
          onClickFirstHandler={() =>
            chooseAnswer(CREATE_NEW_ACCOUNT, true, false)
          }
          onClickSecondHandler={() => chooseAnswer(TRY_AGAIN, false, true)}
          isEditing={isEditing}
        />
      )}
    </StageContainer>
  );
};

export default EmailPasscodeNotRecognized;
