import { Colors } from 'types';

export enum AffiliateStorageKeys {
  Image = 'affiliateImage',
  Email = 'affiliateEmail',
  Color = 'affiliateColor',
}

export enum ConversionFunnel {
  App = 'App',
  Web = 'Web',
}

export type AffiliateOffer = {
  id: string;
  publicCode: string;
  qrCodeUrl: string;
  backgroundColour: keyof Colors;
  affiliateStatement: string;
  affiliateHandle: string;
  affiliateName: string;
  intro: string;
  bodyText: string;
  redemptions: string;
  promotionTermId: string;
  imageUrl: string | null;
  conversionFunnel: ConversionFunnel;
  promotionTerm: {
    id: string;
    term: string;
    termDescription: string;
    termLength: number;
    termUnit: string;
  };
};
