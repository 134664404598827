import styled from 'styled-components';

export const DeleteButton = styled.button`
  position: absolute;
  right: 30px;
  top: 400px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  background: none;
  border: none;
  text-transform: uppercase;
`;

export const SubmitButtonContainer = styled.div`
  position: absolute;
  top: 60%;
  right: -10px;
  transform: translate(0, -50%);
`;
