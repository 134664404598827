import React from 'react';
import styled from 'styled-components';

import { DashedCircleIcon } from 'components/Icons/DashedCircleIcon';
import theme from 'theme/theme';

import { DashedCircleAnimate } from './CircleContent';

type ActivateButtonProps = {
  fetchData: () => void;
  pending: boolean;
};

const ActivateButton = ({ fetchData, pending }: ActivateButtonProps) => (
  <ActivateButton.Container>
    <ActivateButton.StyledButton disabled={pending} onClick={fetchData}>
      {pending ? (
        <DashedCircleAnimate>
          <DashedCircleIcon width={35} height={35} color={theme.colors.white} />
        </DashedCircleAnimate>
      ) : (
        <>Activate now</>
      )}
    </ActivateButton.StyledButton>
  </ActivateButton.Container>
);

ActivateButton.Container = styled.div`
  position: absolute;
  top: -85px;
  width: 100%;
  display: grid;
  justify-content: center;
`;

ActivateButton.StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 158px;
  border: 2px solid rgb(255 255 255 / 50%);
  border-radius: 25px;
  color: white;
  background: padding-box ${({ theme }) => theme.colors.funtainBlue};
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
`;

export default ActivateButton;
