import React from 'react';
import { Navigate } from 'react-router-dom';

import { useCorporateContext } from 'modules/corporateOffer/CorporateContext';

import { AppRoutes } from '../models';

type Props = {
  children: JSX.Element;
};

const PrivateSuccessRoute = ({ children }: Props) => {
  const { isChatSuccess } = useCorporateContext();

  return isChatSuccess ? (
    children
  ) : (
    <Navigate to={AppRoutes.Corporate} replace />
  );
};

export default PrivateSuccessRoute;
