import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (!ref?.current?.contains(event.target as Node)) {
        const htmlElement = event.target as HTMLElement;

        if (htmlElement.nodeName !== 'HTML') {
          callback();
        }
      }
    };

    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [callback, ref]);
};
