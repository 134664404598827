import { TopicType } from '../types';

type HealthImprovementCategory = {
  id: number;
  text: string;
  topicValue: TopicType;
};

export const HI_CATEGORIES: HealthImprovementCategory[] = [
  {
    id: 1,
    text: 'Weight loss',
    topicValue: TopicType.WEIGHT,
  },
  {
    id: 2,
    text: 'Stress, anxiety & low mood',
    topicValue: TopicType.STRESS,
  },
  {
    id: 3,
    text: 'Sleep & relaxation',
    topicValue: TopicType.SLEEP,
  },
  {
    id: 4,
    text: 'Energy & vitality',
    topicValue: TopicType.ENERGY,
  },
  {
    id: 5,
    text: 'Immune & gut health',
    topicValue: TopicType.IMMUNE,
  },
  {
    id: 6,
    text: 'Brain health, focus & memory',
    topicValue: TopicType.BRAIN,
  },
];
