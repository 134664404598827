import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { isMobile } from 'helpers/getDeviceInfo';

import { DesktopIntroSection } from './DesktopIntroSection';
import { Header } from './Header';
import { MobileIntroSection } from './MobileIntroSection';

type Props = {
  email: string;
  emailVerificationCode: string;
};

export const IntroSection = ({ email, emailVerificationCode }: Props) => {
  const { publicCode } = useParams<{ publicCode: string }>();

  return (
    <Container>
      <Header isMobile={isMobile} />
      <ActivateTitle $isMobile={isMobile}>
        Your code is activated!
      </ActivateTitle>
      {isMobile ? (
        <MobileIntroSection
          email={email}
          emailVerificationCode={emailVerificationCode}
          publicCode={publicCode}
        />
      ) : (
        <DesktopIntroSection
          email={email}
          emailVerificationCode={emailVerificationCode}
          publicCode={publicCode}
        />
      )}
      <ImageAffiliate $isMobile={isMobile} />
      <Template $isMobile={isMobile} />
    </Container>
  );
};

const ImageAffiliate = styled.img<{ $isMobile: boolean }>`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  content: url('/images/affiliateActivateImage.png');
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 1050px) {
        bottom: 45px;
        width: 100vw;
        max-width: 1050px;
        content: url('/images/default_mobile_background_image.png');
      }

      @media (width <= 930px) {
        bottom: 45px;
        width: 100vw;
        max-width: 930px;
        content: url('/images/default_mobile_background_image.png');
      }

      @media (width <= 600px) {
        bottom: 45px;
        width: 100vw;
        max-width: 600px;
        content: url('/images/default_mobile_background_image.png');
      }

      @media (width <= 500px) {
        bottom: 40px;
        width: 100vw;
        max-width: 500px;
      }

      @media (width <= 450px) {
        bottom: 38px;
        width: 100vw;
        max-width: 450px;
      }

      @media (width <= 376px) {
        bottom: 42px;
        width: 100vw;
        max-width: 375px;
      }
    `}
`;

const Template = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.sand};
  mask: url('/images/affiliateTemplate.svg') no-repeat 50% 50%;
  mask-position: bottom -1042px right 50%;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 1050px) {
        mask-position: bottom -998px right 50%;
      }

      @media (width <= 920px) {
        mask-position: bottom -997px right 50%;
      }

      @media (width <= 600px) {
        mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
        mask-position: bottom -193px right 50%;
        mask-size: 600px;
      }

      @media (width <= 500px) {
        mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
        mask-position: bottom -193px right 50%;
        mask-size: 600px;
      }

      @media (width <= 450px) {
        mask-position: bottom -138px right 50%;
        mask-size: 450px;
      }

      @media (width <= 376px) {
        mask-position: bottom -105px right 50%;
        mask-size: 375px;
      }

      @media (width <= 362px) {
        mask-size: 375px;
      }
    `}
`;

const ActivateTitle = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  z-index: 2;
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  margin-top: 79px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 30px;
      margin-top: 2px;
    `}
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  background: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
`;
