import { capitalizeString } from 'helpers';
import React, { useState } from 'react';

import { useCorporateContext } from '../../../CorporateContext';
import Name from '../../chatActions/Name';
import { useChatContext } from '../../ChatContext';
import ChatMessage from '../../ChatMessage';

import { CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION } from './CreateAccount';

type FirstNameProps = {
  isMobile?: boolean;
  isEditing: boolean;
  goToNextStage: () => void;
  toggleEditingStatus: () => void;
};

const FirstName = ({
  isMobile,
  goToNextStage,
  isEditing,
  toggleEditingStatus,
}: FirstNameProps) => {
  const { changeProgress } = useChatContext();
  const { setUserName: setNameToContext } = useCorporateContext();

  const [isDelayShown, setShowDelay] = useState(false);
  const [isActionContainerShown, setShowActionContainer] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  return (
    <>
      <ChatMessage
        message="OK what’s your first name?"
        isMobile={isMobile}
        goToNextStep={() => {
          setShowDelay(true);
        }}
      />
      {isDelayShown && (
        <ChatMessage
          isMobile={isMobile}
          goToNextStep={() => setShowActionContainer(true)}
        />
      )}
      {userAnswer && !isEditing && (
        <ChatMessage
          isOutgoing
          message={capitalizeString(userAnswer)}
          isMobile={isMobile}
          goToNextStep={goToNextStage}
          toggleEditingStatus={toggleEditingStatus}
          lastMessage
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <Name
          isMobile={isMobile}
          goToNextStep={(name: string) => {
            if (!isEditing) {
              changeProgress(
                CHAT_COMPLETION_PERCENTAGE_CREATE_ACCOUNT_QUESTION
              );
            }
            setUserAnswer(name);
            setNameToContext(name);
            setShowActionContainer(false);
            if (isEditing) {
              toggleEditingStatus();
            }
          }}
          value={userAnswer}
          isEditing={isEditing}
        />
      )}
    </>
  );
};

export default FirstName;
