import config from 'config';

import { isIOSDevice } from 'helpers/getDeviceInfo';

export const redirectToMobileStore = () => {
  if (isIOSDevice) {
    window.location.assign(config.appStoreLink);

    return;
  }

  window.location.assign(config.googlePlayLink);
};
