import { redirectToMobileStore } from 'helpers';
import { useGetStoreButtonInfo } from 'hooks';
import { find } from 'lodash';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

import Arrow from 'components/Icons/Arrow';
import { isMobile } from 'helpers/getDeviceInfo';
import theme from 'theme/theme';

import { PROMOTION_TERMS } from '../constants';

import Header from './Header';
import MobileButton from './MobileButton';

type Props = {
  qrCodeUrl: string;
  title: string;
  chosenColor: {
    light: string;
    strong: string;
  };
  trialPeriod: string;
  goToNextSection: () => void;
};

const IntroSection = ({
  qrCodeUrl,
  title,
  chosenColor,
  trialPeriod,
  goToNextSection,
}: Props) => {
  const { variant } = useGetStoreButtonInfo();

  const trialTerm = find(PROMOTION_TERMS, {
    findBy: trialPeriod,
  })?.promoDisplay;

  return (
    <Container $isMobile={isMobile} $color={chosenColor.light}>
      <Header />
      <Title $isMobile={isMobile}>{title}</Title>
      <Subtitle>
        {isMobile
          ? `Start a free ${trialTerm} trial`
          : `Scan the code with your mobile device to start a free ${trialTerm} trial`}
      </Subtitle>
      {!isMobile && (
        <QrCodeContainer>
          <QrCodeContainerInfo>
            <ReactSVG src={qrCodeUrl} />
          </QrCodeContainerInfo>
        </QrCodeContainer>
      )}
      {isMobile && (
        <ButtonWrapper>
          <MobileButton
            onClick={redirectToMobileStore}
            color={theme.colors.funtainBlue}
            variant={variant}
            isMobile
          />
        </ButtonWrapper>
      )}
      {isMobile && (
        <ArrowWrapper data-testid="intro-arrow" onClick={goToNextSection}>
          <Arrow />
        </ArrowWrapper>
      )}
      <Template $color={chosenColor.strong} />
    </Container>
  );
};

const ArrowWrapper = styled.div`
  z-index: 10;
  cursor: pointer;
  text-align: center;
  margin: 17px auto 39px;
  position: relative;
`;

const Template = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/promoTemplateDesktop.svg') no-repeat 50% 50%;
  mask-position: bottom -2418px right 50%;

  @media (width <= 920px) {
    mask-position: bottom -2464px right 50%;
  }

  @media (width <= 600px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -108px right 50%;
    mask-size: 600px;
  }

  @media (width <= 500px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -128px right 50%;
    mask-size: 600px;
  }

  @media (width <= 450px) {
    mask-position: bottom -72px right 50%;
    mask-size: 450px;
  }

  @media (width <= 376px) {
    mask-position: bottom -33px right 50%;
    mask-size: 375px;
  }

  @media (width <= 362px) {
    mask-position: bottom -45px right 50%;
    mask-size: 375px;
  }
`;

const ButtonWrapper = styled.div`
  width: 158px;
  margin: 40px auto 0;
  position: relative;
  z-index: 10;
`;

export const QrCodeContainerInfo = styled.div`
  position: relative;
  z-index: 10;
  width: 300px;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 200px;
    height: 200px;

    rect {
      fill: ${({ theme }) => theme.colors.primary};
    }

    path {
      fill: white;
    }
  }
`;

export const QrCodeContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin: 76px auto 0;
`;

const Subtitle = styled.div`
  font-size: 20px;
  position: relative;
  z-index: 10;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 300;
  margin-top: 27px;

  @media (width <= 600px) {
    font-size: 16px;
    font-weight: 300;
    margin-top: 29px;
    right: -2px;
  }
`;

const Title = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  z-index: 2;
  font-size: 60px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;
  margin-top: 74px;

  @media (width <= 820px) {
    font-size: 40px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      @media (width <= 900px) {
        font-size: 30px;
        line-height: 38px;
        margin-top: 15px;
      }
    `};

  @media (width <= 600px) {
    font-size: 30px;
    line-height: 38px;
    margin-top: 35px;
  }
`;

const Container = styled.div<{ $color: string; $isMobile?: boolean }>`
  position: relative;
  z-index: 2;
  background: ${({ $color }) => $color};
  overflow: hidden;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-bottom: 1.5px dashed rgb(216 218 224 / 25500%);
    `};
`;

export default IntroSection;
