import { useQuery } from 'react-query';

import { getProducts } from '../services';

const GET_PRODUCTS_KEY = '/Billing/Products';

export const useGetProductPlanDetails = () => {
  const { data } = useQuery(GET_PRODUCTS_KEY, getProducts, {
    refetchOnWindowFocus: false,
  });

  return { data };
};
