import config from 'config';
import { axiosClient } from 'lib';

import { transformDepartments } from '../helpers';

export const getDepartments = async () => {
  const { data } = await axiosClient.get<Record<string, string>>(
    `/api/v${config.apiVersion}/Departments`
  );

  return transformDepartments(data);
};
