import React, { useCallback, useState } from 'react';

import Activate from './ActivateMobileContent';
import ExpiredMobileContent from './ExpiredMobileContent';

type MobileProps = {
  isiOSDevice: boolean;
};

const Mobile = ({ isiOSDevice }: MobileProps) => {
  const [isExpired, setIsExpired] = useState(false);

  const setCodesExpired = useCallback(() => {
    setIsExpired(true);
  }, []);

  return (
    <>
      {!isExpired ? (
        <Activate isiOSDevice={isiOSDevice} setCodesExpired={setCodesExpired} />
      ) : (
        <ExpiredMobileContent isiOSDevice={isiOSDevice} />
      )}
    </>
  );
};

export default Mobile;
