import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useCorporateContext } from '../CorporateContext';
import { selectMonthPeriod } from '../helpers';

import { useChatContext } from './ChatContext';
import CreateAccount from './chatStages/createAccount/CreateAccount';
import ExistingAccount from './chatStages/existingAccount/ExistingAccount';
import InitialStage from './chatStages/InitialStage';

type ChatProps = {
  isMobile?: boolean;
};

const Chat = ({ isMobile }: ChatProps) => {
  const { setNewChatRef } = useChatContext();
  const { offerTerm } = useCorporateContext();

  const [isHaveAccountShown, setShowHaveAccount] = useState(false);
  const [isNoAccount, setIsNoAccount] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const chatRef = useRef<HTMLDivElement>(null);

  const monthPeriod = selectMonthPeriod(offerTerm);

  const showHaveOrNotAccount = useCallback((value: boolean) => {
    if (value) {
      setShowHaveAccount(true);
      setIsNoAccount(false);
    } else {
      setIsNoAccount(true);
      setShowHaveAccount(false);
    }
  }, []);

  const toggleEditingStatus = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  useEffect(() => {
    setNewChatRef(chatRef);
  }, [chatRef, setNewChatRef]);

  return (
    <Container ref={chatRef} $isMobile={isMobile}>
      <ChatHeader $isMobile={isMobile}>
        <IconContainer>
          <img src="/icons/lock.svg" alt="lock" />
        </IconContainer>
        <Title>
          {isMobile
            ? `Receive ${monthPeriod} full access!`
            : `Receive ${monthPeriod} full access to Shido`}
        </Title>
      </ChatHeader>
      <InitialStage
        toggleEditingStatus={toggleEditingStatus}
        isMobile={isMobile}
        goToNextStage={showHaveOrNotAccount}
        isEditing={isEditing}
      />
      {isHaveAccountShown && !isEditing && (
        <ExistingAccount isEditing={isEditing} isMobile={isMobile} />
      )}
      {isNoAccount && (
        <CreateAccount isEditing={isEditing} isMobile={isMobile} />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: 215px;
  height: 100%;
  overflow-y: auto;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      box-sizing: border-box;
      height: 100%;
      min-height: 100vh;
      padding-bottom: 250px;
    `}

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
`;

const ChatHeader = styled.div<{ $isMobile?: boolean }>`
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '40px' : '50px')};
  padding-top: 90px;
`;

export default Chat;
