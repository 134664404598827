import config from 'config';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import * as smoothscroll from 'smoothscroll-polyfill';

import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './fonts/F37GingerBold.ttf';
import './fonts/F37GingerLight.ttf';
import './fonts/F37GingerRegular.ttf';

smoothscroll.polyfill();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.gaTrackingId}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${config.gaTrackingId}');`}
      </script>
    </Helmet>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
