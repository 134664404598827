import React from 'react';
import styled, { css } from 'styled-components';

import Loader from 'modules/corporateOffer/components/Loader';

const BORDER_SIZE = 5;

export type DefaultButtonProps = {
  text: string;
  textColor: string;
  isActive: boolean;
  width: number;
  height: number;
  isSubmit?: boolean;
  withShadow: boolean;
  withBorder?: boolean;
  buttonColor: string;
  isUppercase?: boolean;
  isLoading?: boolean;
  clickHandler?: () => void;
};

const DefaultButton = ({
  text,
  isLoading = false,
  isActive,
  isSubmit,
  textColor,
  withShadow,
  withBorder = true,
  width,
  height,
  buttonColor,
  clickHandler,
  isUppercase,
}: DefaultButtonProps) => (
  <Container
    $isActive={isActive}
    $withShadow={withShadow}
    $withBorder={withBorder}
    $width={width + BORDER_SIZE}
    $height={height + BORDER_SIZE}
  >
    <Button
      $isActive={isActive}
      disabled={!isActive}
      $textColor={textColor}
      onClick={clickHandler}
      $withShadow={withShadow}
      $buttonColor={buttonColor}
      $isUppercase={isUppercase}
      $width={width}
      $height={height}
      type={isSubmit ? 'submit' : 'button'}
    >
      {isLoading ? (
        <LoaderWrapper $isLoading={isLoading} data-testid="loader-wrapper">
          <Loader bigCircleSize={30} smallCircleSize={20} />
        </LoaderWrapper>
      ) : (
        text
      )}
    </Button>
  </Container>
);

const LoaderWrapper = styled.div<{ $isLoading?: boolean }>`
  position: absolute;
  width: 30px;
  margin-top: -81px;
  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      visibility: hidden;
    `};
`;

const Container = styled.div<{
  $width: number;
  $height: number;
  $isActive: boolean;
  $withShadow: boolean;
  $withBorder: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  box-shadow: ${({ $isActive }) =>
    $isActive ? '0 5px 20px rgb(0 0 0 / 10%)' : 'none'};
  border-radius: 60px !important;
  text-decoration: none;
  ${({ $withBorder, $isActive }) =>
    $withBorder &&
    css`
      background-color: ${({ theme }) =>
        $isActive ? 'rgba(255, 255, 255, 0.2)' : theme.colors.gray};
    `}
  ${({ $withShadow }) =>
    !$withShadow &&
    css`
      box-shadow: none;
    `}
  ${({ $isActive }) =>
    !$isActive &&
    css`
      opacity: 0.6;
    `}
`;

const Button = styled.button<{
  $width: number;
  $height: number;
  $isActive: boolean;
  $textColor: string;
  $buttonColor: string;
  $withShadow: boolean;
  $isUppercase?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width}px !important;
  height: ${({ $height }) => $height}px;
  border-radius: 60px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${({ $buttonColor }) => $buttonColor};
  box-shadow: ${({ $isActive }) =>
    $isActive ? '0 5px 5px rgb(0 0 0 / 10%)' : 'none'};
  text-decoration: none;
  color: ${({ $textColor, theme }) =>
    $textColor ? $textColor : theme.colors.funtainBlue};
  cursor: pointer;
  border: none;
  transition: all 0.5s;

  &:hover {
    box-shadow: ${({ $isActive }) =>
      $isActive ? '0 5px 10px rgb(0 0 0 / 20%)' : 'none'};
  }

  ${({ $withShadow }) =>
    !$withShadow &&
    css`
      box-shadow: none;
    `}
  ${({ $isUppercase }) =>
    $isUppercase &&
    css`
      text-transform: uppercase;
    `}

  @media (width <= 480px) {
    font-size: 14px;
  }
`;

export default DefaultButton;
