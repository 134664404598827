import React from 'react';

type WarningIconProps = {
  width: number;
  height: number;
  color: string;
};

const WarningIcon = ({ width, height, color }: WarningIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 120 119.881"
  >
    <path
      id="Path_4091"
      data-name="Path 4091"
      d="M1152.72,2470.131l-57.389-114.778a2.713,2.713,0,0,0-4.664,0l-57.388,114.778a2.609,2.609,0,0,0,2.332,3.776h114.777A2.609,2.609,0,0,0,1152.72,2470.131Zm-62.335-73.687a2.609,2.609,0,0,1,5.217,0v39.927a2.609,2.609,0,0,1-5.217,0ZM1093,2458.82a4.99,4.99,0,1,1,4.988-4.988A4.985,4.985,0,0,1,1093,2458.82Z"
      transform="translate(-1032.999 -2354.026)"
      fill={color}
    />
  </svg>
);

export default WarningIcon;
