import config from 'config';
import React from 'react';

import { DeviceType, OS } from 'helpers/getDeviceInfo';

import StoreButton from './StoreButton';

type Props = {
  name?: string;
  platform?: DeviceType;
  backgroundColor?: string;
};

const StoreButtons = ({ name, platform, backgroundColor }: Props) => {
  if (platform === DeviceType.Tablet || platform === DeviceType.Mobile) {
    if (name !== OS.Ios) {
      return (
        <StoreButton
          store={'Google Play'}
          href={config.googlePlayLink}
          backgroundColor={backgroundColor}
        />
      );
    }

    return (
      <StoreButton
        store={'App Store'}
        href={config.appStoreLink}
        backgroundColor={backgroundColor}
      />
    );
  }

  return (
    <>
      <StoreButton
        store={'App Store'}
        href={config.appStoreLink}
        backgroundColor={backgroundColor}
      />
      <StoreButton
        store={'Google Play'}
        href={config.googlePlayLink}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

export default StoreButtons;
