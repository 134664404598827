import React from 'react';

const SleepIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.002"
    viewBox="0 0 24 24.002"
  >
    <g data-name="Group 4278" fill="#69b0c7">
      <path
        data-name="Path 3920"
        d="M13.545 22.972a12.468 12.468 0 0 1 0-21.943.5.5 0 0 0-.159-.945 12 12 0 1 0 0 23.836.5.5 0 0 0 .161-.948Z"
      />
      <path
        data-name="Path 3921"
        d="M22.51 18c-2.4-.049-3.517-1.16-3.51-3.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5c-.01 2.355-1.123 3.471-3.507 3.5a.5.5 0 0 0 .012 1c2.4 0 3.474 1.08 3.495 3.5a.5.5 0 0 0 .489.5h.011a.5.5 0 0 0 .5-.475C19.12 20.119 20.224 19 22.5 19a.5.5 0 0 0 .011-1Z"
      />
      <path
        data-name="Path 3922"
        d="M16 11a.5.5 0 0 0 .012-1c-1.4-.03-2.016-.647-2.011-2a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5c-.005 1.364-.624 1.981-2.007 2a.5.5 0 0 0 .011 1c1.387 0 1.983.6 2 2a.5.5 0 0 0 .489.5h.011a.5.5 0 0 0 .5-.475C14.069 11.625 14.681 11 16 11Z"
      />
      <path
        data-name="Path 3923"
        d="M23.51 4c-1.741-.035-2.515-.806-2.51-2.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5c-.006 1.707-.78 2.479-2.507 2.5a.5.5 0 0 0 .012 1c1.711 0 2.48.771 2.495 2.5a.5.5 0 0 0 .489.5h.011a.5.5 0 0 0 .5-.475C21.087 5.779 21.853 5 23.5 5a.5.5 0 0 0 .011-1Z"
      />
    </g>
  </svg>
);

export default SleepIcon;
