import config from 'config';
import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import StoreButton from 'components/StoreButton';
import getDeviceInfo, { DeviceType, OS } from 'helpers/getDeviceInfo';
import theme from 'theme/theme';

type ExpiredBlockProps = {
  isMobile?: boolean;
};

const ExpiredBlock = ({ isMobile }: ExpiredBlockProps) => {
  const isTablet = getDeviceInfo().platform === DeviceType.Tablet;
  const isIOSDevice = getDeviceInfo().name === OS.Ios;

  const isIphone = isMobile && isIOSDevice;
  const isAndroid = isMobile && !isIOSDevice;
  const isNotIpad = isTablet && !isIOSDevice;
  const isDesktop = !isMobile && !isTablet;

  return (
    <Container>
      <NoAppQuestion $isMobile={isMobile}>
        Don’t have the app installed?
      </NoAppQuestion>
      {!isMobile ? (
        <>
          <QrDescription>Scan the code to download the app</QrDescription>
          <QrWrapper>
            <QRCode
              value={config.shidoQr}
              size={120}
              bgColor={theme.colors.white}
              fgColor={theme.colors.primary}
            />
          </QrWrapper>
        </>
      ) : (
        <DownloadDescription>Follow the link below</DownloadDescription>
      )}
      <StoreButtonsWrapper>
        {isIphone && (
          <StoreButton
            color={theme.colors.black}
            variant="appStore"
            href={config.appStoreLink}
            isMobile={isMobile}
          />
        )}
        {isAndroid && (
          <StoreButton
            color={theme.colors.black}
            variant="googlePlay"
            href={config.googlePlayLink}
            isMobile={isMobile}
          />
        )}
        {isTablet && (
          <StoreButton
            color={theme.colors.black}
            variant="appStore"
            href={config.appStoreLink}
          />
        )}
        {isNotIpad && (
          <StoreButton
            color={theme.colors.black}
            variant="googlePlay"
            href={config.googlePlayLink}
          />
        )}
        {isDesktop && (
          <>
            <StoreButton
              color={theme.colors.black}
              variant="appStore"
              href={config.appStoreLink}
            />
            <StoreButton
              color={theme.colors.black}
              variant="googlePlay"
              href={config.googlePlayLink}
            />
          </>
        )}
      </StoreButtonsWrapper>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const NoAppQuestion = styled.h3<{ $isMobile?: boolean }>`
  font-weight: 700;
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '30px')};
  color: ${({ theme }) => theme.colors.primary};
`;

const QrDescription = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 10px;
  padding: 4px 10px;
`;

const QrWrapper = styled.div`
  margin: 37px auto 46px;
`;

const StoreButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto;
  column-gap: 21px;
  margin-bottom: 20px;
`;

const DownloadDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default ExpiredBlock;
