import React from 'react';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';

type Header = {
  isMobile?: boolean;
};

const Header = ({ isMobile }: Header) => (
  <Container $isMobile={isMobile}>
    <ShidoIconWrapper>
      <LogoIcon width={61} height={37} />
      <ShidoTitle>
        <ShidoText>shido</ShidoText>
        <ShidoMark>®</ShidoMark>
      </ShidoTitle>
    </ShidoIconWrapper>
  </Container>
);

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: -14px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 38px;
  font-weight: 700;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div<{ $isMobile?: boolean }>`
  max-width: 1415px;
  width: 100%;
  height: 123px;
  margin: 0 auto;
  padding: 43px 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(216 218 224);
  position: relative;
  z-index: 4;

  @media (width <= 480px) {
    height: 116px;
    padding: 43px 21px 0 10px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-bottom: none;
      justify-content: center;
    `}
`;

export default React.memo(Header);
