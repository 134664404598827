import config from 'config';
import { axiosClient } from 'lib';

import { PromotionOfferCode } from '../types';

type PromotionOfferPayload = {
  publicCode: string;
  platform: 'ios' | 'android';
};

export const getPromoStoreOfferCode = async ({
  publicCode,
  platform,
}: PromotionOfferPayload): Promise<PromotionOfferCode> => {
  const response = await axiosClient.get<{
    payload: { data: PromotionOfferCode };
  }>(
    `/api/v${config.nextApiVersion}/PromotionCodes?publicCode=${publicCode}&platform=${platform}`
  );

  return response.data.payload.data;
};
