import config from 'config';
import { useMemo } from 'react';

import { isIOSDevice } from 'helpers/getDeviceInfo';

export const useGetStoreButtonInfo = (): {
  variant: 'appStore' | 'googlePlay';
  href: string;
} => {
  return useMemo(() => {
    if (isIOSDevice) {
      return {
        variant: 'appStore',
        href: config.appStoreLink,
      };
    }

    return {
      variant: 'googlePlay',
      href: config.googlePlayLink,
    };
  }, []);
};
