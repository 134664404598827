import React from 'react';
import styled from 'styled-components';

import InfoIcon from 'components/Icons/InfoIcon';

type Props = {
  text: string;
  isMobile?: boolean;
  actionText?: string;
  className?: string;
  clickHandler?: () => void;
};

const ActionWarning = ({
  text,
  isMobile,
  actionText,
  className,
  clickHandler,
}: Props) => (
  <Container $isMobile={isMobile} className={className}>
    <InfoIcon />
    <Text $isMobile={isMobile}>{text}</Text>
    {actionText && <ActionText onClick={clickHandler}>{actionText}</ActionText>}
  </Container>
);

const Container = styled.div<{ $isMobile?: boolean }>`
  position: absolute;
  left: 50%;
  top: -100px;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  background: ${({ $isMobile, theme }) =>
    $isMobile ? '#f7f7f2' : theme.colors.white};
  padding-top: 10px;
`;

const ActionText = styled.p`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.funtainBlue};
  cursor: pointer;
`;

const Text = styled.p<{ $isMobile?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primary};
  width: ${({ $isMobile }) => ($isMobile ? 'auto' : '500px')};
`;

export default ActionWarning;
