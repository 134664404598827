export const PROMOTION_TERMS = [
  {
    id: '0',
    findBy: 'SevenDays',
    promoDisplay: '7 day',
  },
  {
    id: '1',
    findBy: 'OneMonth',
    promoDisplay: '1 month',
  },
  {
    id: '2',
    findBy: 'ThreeMonths',
    promoDisplay: '3 months',
  },
];
