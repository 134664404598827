const { REACT_APP_API_URL, REACT_APP_ENVIRONMENT } = process.env;

// App config, API URL populated from '.env' file
export default {
  apiUrl: REACT_APP_API_URL,
  apiVersion: 4,
  nextApiVersion: 5,
  gaTrackingId:
    REACT_APP_ENVIRONMENT === 'production' ? 'G-VN7Y42FGGJ' : 'G-D6BWD51PE4',
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID || '',
  appStoreLink: 'https://apps.apple.com/gb/app/shido/id1513137174',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.shido',
  instrumentationKey: 'a67e8921-1ade-436b-9d15-1a2da21981e9',
  contentfulSpaceId: 'm61lhx9gr8sv',
  contentfulAccessToken: 'KY3uhMz5sMlu0JFvOHRWUE63YH-fo5i21_WZwJkBogk',
  shidoURL: 'https://shido.com',
  shidoQr: 'https://shido.me/shido-qr',
  shidoWebsiteVersion:
    REACT_APP_ENVIRONMENT === 'production'
      ? 'https://shido.com'
      : 'https://shido-website-dev-west-europe.azurewebsites.net',
  shidoMeVersion:
    REACT_APP_ENVIRONMENT === 'production'
      ? 'https://shido.me'
      : 'https://shido-me-dev.azurewebsites.net',
};
