import React, { useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'types';

import { LogoIcon } from 'components/Icons/LogoIcon';
import { isMobile } from 'helpers/getDeviceInfo';
import { PROMO_COLOR_PICKER } from 'modules/promotionOffer/constants';
import theme from 'theme/theme';

import Header from '../../modules/affiliateOffer/components/Header';
import { AffiliateStorageKeys } from '../../modules/affiliateOffer/types';

export const AffiliateInstruction = () => {
  const image = localStorage.getItem(AffiliateStorageKeys.Image);
  const email = localStorage.getItem(AffiliateStorageKeys.Email) || '';
  const backgroundColor =
    PROMO_COLOR_PICKER[
      localStorage.getItem(AffiliateStorageKeys.Color) as keyof Colors
    ] || PROMO_COLOR_PICKER.Sand;

  useLayoutEffect(() => {
    if (isMobile) {
      document.body.style.background = backgroundColor.strong;
    } else {
      document.body.style.background = theme.colors.primary;
    }

    return () => {
      document.body.style.background = theme.colors.primary;
    };
  }, [backgroundColor]);

  return (
    <Container $color={backgroundColor.strong}>
      <IntroContainer $color={backgroundColor.light}>
        <Header isMobile={isMobile} />
        <Title $isMobile={isMobile}>Thank you!</Title>
        <SubTitle $isMobile={isMobile}>Your code has been sent.</SubTitle>
        <ImageGraphContainer>
          <ImageInnerContainer>
            <AdditionalImageInner $isMobile={isMobile}>
              {image ? (
                <img src={image} />
              ) : (
                <LogoIcon
                  width={isMobile ? 100 : 153}
                  height={isMobile ? 50 : 93}
                />
              )}
            </AdditionalImageInner>
          </ImageInnerContainer>
        </ImageGraphContainer>
        <Template $color={backgroundColor.strong} />
      </IntroContainer>
      <InstructionTitle>
        {`Please follow the link in your email & sign up using ${email}
          to activate 1 month free!`}
      </InstructionTitle>
      <ShidoIconWrapper $isMobile={isMobile}>
        <LogoIcon width={48} height={29} />
        <ShidoTitle>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
      {!isMobile && <GraphBackground $color={backgroundColor.light} />}
    </Container>
  );
};

const ImageInnerContainer = styled.div`
  width: 270px;
  height: 270px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 15px -4px grey;

  @media (width <= 480px) {
    width: 120px;
    height: 120px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const AdditionalImageInner = styled.div<{ $isMobile: boolean }>`
  width: 262px;
  height: 262px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border: none;
      border-radius: unset;
      overflow: unset;
    `}

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  @media (width <= 480px) {
    width: 116px;
    height: 112px;
  }

  img {
    width: 268px;
    height: 268px;
    object-fit: cover;

    @media (width <= 480px) {
      width: 116px;
      height: 116px;
    }
  }
`;

const Template = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/promoTemplateDesktop.svg') no-repeat 50% 50%;
  mask-position: bottom -2418px right 50%;

  @media (width <= 500px) {
    mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
    mask-position: bottom -150px right 50%;
    mask-size: 600px;
  }

  @media (width <= 450px) {
    mask-position: bottom -97px right 50%;
    mask-size: 450px;
  }
`;

export const ImageGraphContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  border: 2px dashed ${({ theme }) => theme.colors.pinkLight};
  border-radius: 50%;
  margin: 77px auto 0;

  @media (width <= 480px) {
    width: 150px;
    height: 150px;
    margin: 89px auto 0;
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: 4px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoIconWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 416px;
  position: relative;
  z-index: 4;
  margin-bottom: 105px;
  left: 12px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 72px;
      margin-bottom: 75px;
    `}
`;

const InstructionTitle = styled.div`
  font-size: 30px;
  line-height: 50px;
  position: relative;
  word-break: break-word;
  z-index: 4;
  max-width: 704px;
  margin: 67px auto 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  left: -2px;

  @media (width <= 480px) {
    max-width: 256px;
    font-size: 16px;
    line-height: 21px;
    margin: 60px auto 0;
    left: 2px;
  }
`;

const Title = styled.div<{ $isMobile: boolean }>`
  font-size: 60px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 70px;
  position: relative;
  z-index: 4;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 30px;
      margin-top: 4px;
    `}
`;

const SubTitle = styled.div<{ $isMobile: boolean }>`
  font-size: 40px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 10px;
  position: relative;
  z-index: 4;
  left: 7px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 16px;
      margin-top: 14px;
      left: 0;
    `}
`;

const IntroContainer = styled.div<{ $color: string }>`
  position: relative;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;

const Container = styled.div<{ $color: string }>`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;

const GraphBackground = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/layout.svg') no-repeat 50% 50%;
  mask-position: bottom -1875px right 50%;
  z-index: 3;

  @media (width <= 600px) {
    background-color: ${({ $color }) => $color};
    mask: url('/images/mobileLayout.svg') no-repeat 50% 50%;
    mask-position: bottom -24px right 50%;
  }
`;
