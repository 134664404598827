import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import config from '../config';

const reactPlugin: ReactPlugin = new ReactPlugin();
const appInsights: ApplicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.instrumentationKey,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
