import React from 'react';

import theme from 'theme/theme';

const ShidoIcon = ({
  width,
  height,
  fill = theme.colors.primary,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 130.574 43.367"
    {...props}
  >
    <g id="Group_4310" data-name="Group 4310" transform="translate(0 0)">
      <path
        id="Path_4084"
        data-name="Path 4084"
        d="M948.52,529.924q3.331,0,3.3-2.006,0-1.1-1.428-1.808a17.505,17.505,0,0,0-3.443-1.215,33.4,33.4,0,0,1-4.076-1.3,8.275,8.275,0,0,1-3.471-2.572,7.047,7.047,0,0,1-1.439-4.577,8.25,8.25,0,0,1,2.59-6.285,9.932,9.932,0,0,1,7.1-2.441q6.306,0,10.1,3.442l-3.848,5.057a10.678,10.678,0,0,0-6.03-2.091q-2.967,0-2.966,1.752,0,1.074,1.4,1.752a24.476,24.476,0,0,0,3.415,1.3q2.015.621,4.1,1.469a8.247,8.247,0,0,1,3.5,2.712,7.206,7.206,0,0,1,1.446,4.549,7.854,7.854,0,0,1-2.783,6.236q-2.783,2.405-8.264,2.433a17.02,17.02,0,0,1-10.5-3.844l3.738-5.389A11.261,11.261,0,0,0,948.52,529.924Z"
        transform="translate(-937.221 -492.965)"
        fill={fill}
      />
      <path
        id="Path_4085"
        data-name="Path 4085"
        d="M1021.977,512.584V497.969q0-6.436-5.649-6.464-5.649,0-5.621,6.352v14.727h-7.083V469.976h7.083v17.285a11.856,11.856,0,0,1,7.195-2.53,11.224,11.224,0,0,1,8.038,2.979q3.146,2.979,3.12,9.106v15.767Z"
        transform="translate(-977.665 -469.976)"
        fill={fill}
      />
      <path
        id="Path_4086"
        data-name="Path 4086"
        d="M1085.629,483.487a4.349,4.349,0,0,1-3.2-1.293,4.548,4.548,0,0,1,0-6.352,4.4,4.4,0,0,1,3.2-1.264,4.249,4.249,0,0,1,3.176,1.264,4.36,4.36,0,0,1,1.237,3.176,4.454,4.454,0,0,1-1.237,3.2A4.111,4.111,0,0,1,1085.629,483.487Zm-3.569,31.9V488.04h7.083v27.346Z"
        transform="translate(-1024.874 -472.779)"
        fill={fill}
      />
      <path
        id="Path_4087"
        data-name="Path 4087"
        d="M1134.144,512.584v-1.762a11.684,11.684,0,0,1-7.588,2.521,14.071,14.071,0,0,1-10.287-4.2,14.262,14.262,0,0,1,0-20.247,14.149,14.149,0,0,1,10.287-4.161,11.731,11.731,0,0,1,7.588,2.53V469.976h7.083v42.608ZM1119,499.023a7.33,7.33,0,0,0,2.219,5.322,7.166,7.166,0,0,0,5.369,2.224,7.39,7.39,0,0,0,5.368-2.224,7.454,7.454,0,0,0-.056-10.615,7.522,7.522,0,0,0-10.68,0A7.238,7.238,0,0,0,1119,499.023Z"
        transform="translate(-1043.706 -469.976)"
        fill={fill}
      />
      <path
        id="Path_4088"
        data-name="Path 4088"
        d="M1211.481,507.721a14,14,0,0,1,10.3,4.132,14.274,14.274,0,0,1-.056,20.3,14.7,14.7,0,0,1-20.553-.029,14.272,14.272,0,0,1,0-20.247A14.164,14.164,0,0,1,1211.481,507.721Zm-7.574,14.291a7.329,7.329,0,0,0,2.22,5.322,7.165,7.165,0,0,0,5.369,2.224,7.391,7.391,0,0,0,5.368-2.224,7.454,7.454,0,0,0-.056-10.615,7.521,7.521,0,0,0-10.68,0A7.237,7.237,0,0,0,1203.907,522.012Z"
        transform="translate(-1095.423 -492.965)"
        fill={fill}
      />
    </g>
  </svg>
);

export default ShidoIcon;
