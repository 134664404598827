import config from 'config';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import DashedCheckMark from 'components/Icons/DashedCheckMark';
import { LogoIcon } from 'components/Icons/LogoIcon';
import getDeviceInfo, { DeviceType, isMobile } from 'helpers/getDeviceInfo';
import { populatePriceTokens } from 'modules/corporateOffer/helpers';
import {
  useGetCurrency,
  useGetProductPlanDetails,
} from 'modules/corporateOffer/hooks';
import theme from 'theme/theme';

import PlanPrice from '../PlanPrice';

const PREMIUM_PLAN_TITLE = 'Premium plan';
const PREMIUM_BUTTON = 'Free 14 day trial';
const BASIC_BUTTON = 'Get started';
const BASIC_PLAN_DESC = `No payment card required.\nNo Ads. Free forever.`;

const PLAN_COMPARISON = [
  { label: 'Health assessment', isFreemium: true },
  { label: 'Daily guide', isFreemium: true },
  {
    label: isMobile
      ? 'Allergy & air analysis'
      : 'Allergy & air quality analysis',
    isFreemium: true,
  },
  { label: 'Food scanning & insights', isFreemium: true },
  { label: 'Weight tracker', isFreemium: true },
  { label: 'Expert advice ', isFreemium: false },
  { label: 'Virtual coach', isFreemium: false },
  { label: 'Food & activity diary', isFreemium: false },
  { label: 'Nutriscore analysis', isFreemium: false },
  { label: 'Meal plans', isFreemium: false },
  { label: 'Courses & podcasts', isFreemium: false },
  {
    label: isMobile ? 'Meditation & fitness' : 'Meditation & fitness series',
    isFreemium: false,
  },
];

type Props = {
  title?: string;
  isTopic?: boolean;
};

const ComparisonChartSection = (
  {
    title = 'Jumpstart your health transformation today for free',
    isTopic = false,
  }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  const { clientCurrency } = useGetCurrency();
  const { data: rawData } = useGetProductPlanDetails();

  const data = rawData?.map((product) =>
    populatePriceTokens(product, clientCurrency, false)
  );

  if (!data) return null;

  return (
    <Container ref={ref}>
      <Title>{title}</Title>
      <Subtitle $hideOnMobile>
        Experience Shido for free. No commitment. Cancel anytime.
      </Subtitle>
      <PlansWrapper>
        {/* What's included */}
        <PlanColumn style={{ width: 358 }}>
          <SecondaryHeader $isTopic={isTopic}>
            <LogoWrapper $hideOnMobile>
              <LogoIcon width={63} height={38} fill={theme.colors.topicBlue} />
            </LogoWrapper>
            <SecondaryText
              $hideOnMobile
              style={{ marginBottom: isTopic ? 70 : 34 }}
            >
              {`Your journey towards better\nhealth starts here & now.`}
            </SecondaryText>
            <QuoteText $hideOnMobile>
              {`Experience the combination\nof expert-driven coaching\n& smart AI with Shido.`}
            </QuoteText>
            <SecondaryText $marginTop={isTopic ? 61 : 47}>
              What’s included?
            </SecondaryText>
          </SecondaryHeader>
          {PLAN_COMPARISON.map(({ label }, idx) => (
            <ColumnText key={label} $isOdd={idx % 2 !== 0}>
              {label}
            </ColumnText>
          ))}
        </PlanColumn>
        {/* Freemium plan */}
        <PlanColumn $isPlan $isFreemium $isTopic={isTopic}>
          <PlanHeader $insideBorder $isTopic={isTopic}>
            <h5>{isMobile ? 'BASIC' : 'Starter'}</h5>
            {isTopic && (
              <PlanSubtitle $hideOnMobile>
                No payment card required
              </PlanSubtitle>
            )}
            <PlanPrice isFreemium isTopic={isTopic} hideOnMobile>
              <p>0</p>
            </PlanPrice>
            <PlanDescription $isTopic={isTopic} $hideOnMobile>
              {isTopic
                ? `Limited feature set.\nNo Ads. Free forever.`
                : BASIC_PLAN_DESC}
            </PlanDescription>
            <PlanButton
              href={`${config.shidoWebsiteVersion}/sign-up?plan=free`}
              target="_blank"
              rel="noreferer"
              $isFilled={isTopic}
              $hideOnMobile
            >
              {BASIC_BUTTON}
            </PlanButton>
          </PlanHeader>
          {PLAN_COMPARISON.map(({ label, isFreemium }) => (
            <PlanCell key={label}>
              {isFreemium && <DashedCheckMark width={24} height={24} />}
            </PlanCell>
          ))}
        </PlanColumn>
        {/* Placeholder column for spacing */}
        <PlanColumn>
          <PlanHeader $isPlaceholder $isTopic={isTopic} />
          {PLAN_COMPARISON.map(({ label }, idx) => (
            <ColumnText key={label} $isOdd={idx % 2 !== 0} $isPlaceholder />
          ))}
        </PlanColumn>
        {/* Premium plan column */}
        <PlanColumn $isPlan $isPremium>
          {isTopic && (
            <TryForFree $hideOnMobile>TRY PREMIUM FOR FREE</TryForFree>
          )}
          <PlanHeader $insideBorder $isTopic={isTopic} $isPremium>
            <h5>{isMobile ? 'PREMIUM' : PREMIUM_PLAN_TITLE}</h5>
            {isTopic && (
              <PlanSubtitle $hideOnMobile>Unlock all features</PlanSubtitle>
            )}
            <PlanPrice isTopic={isTopic} hideOnMobile>
              <p>
                {data?.[0].metadata.trial_option_monthly_price.split('.')[0]}
              </p>
              <small>
                .{data?.[0].metadata.trial_option_monthly_price.split('.')[1]}
              </small>
            </PlanPrice>
            <PlanDescription
              $isTopic={isTopic}
              $hideOnMobile
            >{`Per month, billed annually*\nor ${data?.[1].metadata.trial_option_monthly_price} billed monthly.`}</PlanDescription>
            <PlanButton
              href={`${config.shidoWebsiteVersion}/sign-up`}
              target="_blank"
              rel="noreferer"
              $isFilled
              $hideOnMobile
            >
              {PREMIUM_BUTTON}
            </PlanButton>
          </PlanHeader>
          {PLAN_COMPARISON.map(({ label }) => (
            <PlanCell key={label} $isPremium>
              <DashedCheckMark width={24} height={24} />
            </PlanCell>
          ))}
        </PlanColumn>
      </PlansWrapper>
      <MobileCardsWrapper>
        <MobileSubtitle>{`Experience Shido for free.\nNo commitment. Cancel anytime.`}</MobileSubtitle>
        <MobileCard $isFreemium>
          <MobileCardInfo>
            <MobileCardHeader $isFreemium>
              <h6>Basic plan</h6>
              <p>{BASIC_PLAN_DESC}</p>
            </MobileCardHeader>
            <PlanPrice isFreemium isMobile>
              <p>0</p>
            </PlanPrice>
          </MobileCardInfo>
          <MobilePlanButton
            href={`${config.shidoWebsiteVersion}/sign-up?plan=free`}
            target="_blank"
            rel="noreferer"
          >
            {BASIC_BUTTON}
          </MobilePlanButton>
        </MobileCard>
        <MobileCard $isPremium>
          <MobileCardInfo>
            <MobileCardHeader $isFreemium={false}>
              <h6>{PREMIUM_PLAN_TITLE}</h6>
              <p>{`Per month, billed annually\nor ${data?.[1].metadata.trial_option_monthly_price} billed monthly.`}</p>
            </MobileCardHeader>
            <PlanPrice isMobile>
              <p>
                {data?.[0].metadata.trial_option_monthly_price.split('.')[0]}
              </p>
              <small>
                .{data?.[0].metadata.trial_option_monthly_price.split('.')[1]}
              </small>
            </PlanPrice>
          </MobileCardInfo>
          <MobilePlanButton
            href={`${config.shidoWebsiteVersion}/sign-up`}
            target="_blank"
            rel="noreferer"
            $isFilled
          >
            {PREMIUM_BUTTON}
          </MobilePlanButton>
        </MobileCard>
      </MobileCardsWrapper>
    </Container>
  );
};

const MobileCardHeader = styled.header<{ $isFreemium: boolean }>`
  & h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ $isFreemium, theme }) =>
      $isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
    margin-bottom: 6px;
  }

  & p {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    white-space: pre-line;
    color: ${({ $isFreemium, theme }) =>
      $isFreemium ? theme.colors.darkBlueV2 : theme.colors.secondary};
  }
`;

const MobileCardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(65 78 113 / 25%);
  margin: 0 17px 23px;
  padding: 15px 0;

  & div:last-child {
    margin-right: 5px;
  }
`;

const MobileCard = styled.article<{
  $isFreemium?: boolean;
  $isPremium?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  background-color: white;
  width: 335px;
  padding-bottom: 23px;
  filter: drop-shadow(0 1px 10px rgb(0 0 0 / 10%));
  ${({ $isFreemium, theme }) =>
    $isFreemium &&
    css`
      border-color: ${theme.colors.darkBlueV2};
    `}
  ${({ $isPremium, theme }) =>
    $isPremium &&
    css`
      border-color: ${theme.colors.secondary};
    `}
`;

const MobileSubtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 29px;
`;

const MobileCardsWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 30px 22px;
  display: none;

  & article:last-child {
    margin-top: 21px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
  }
`;

const LogoWrapper = styled.div<{ $hideOnMobile: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    css`
      ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
      }
    `}
`;

const TryForFree = styled.div<{ $hideOnMobile: boolean }>`
  position: absolute;
  text-transform: uppercase;
  top: -12px;
  left: 50%;
  width: 164px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  text-align: center;
  transform: translate(-50%, 0);
  padding: 4px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const SecondaryHeader = styled.div<{ $isTopic?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 70px 0 31px;
  border-bottom: 1px solid rgb(65 78 113 / 25%);
  ${({ $isTopic }) =>
    $isTopic &&
    css`
      padding-bottom: 38px;
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 11px 0 9px;
  }
`;

const QuoteText = styled.p<{ $hideOnMobile: boolean }>`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.topicBlue};
  position: relative;
  white-space: pre-line;
  padding-left: 37px;
  padding-top: 9px;
  padding-bottom: 9px;

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 2px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const SecondaryText = styled.p<{
  $hideOnMobile?: boolean;
  $marginTop?: number;
}>`
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.secondary};
  white-space: pre-line;
  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${$marginTop}px;
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    margin-top: 0;
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const PlanButton = styled.a<{
  $isFilled?: boolean;
  $hideOnMobile?: boolean;
}>`
  width: 267px;
  border: 2px solid ${({ theme }) => theme.colors.darkBlueV2};
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 54px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: ${({ $isFilled, theme }) =>
    $isFilled ? 'white' : theme.colors.darkBlueV2};
  background-color: ${({ $isFilled, theme }) =>
    $isFilled ? theme.colors.darkBlueV2 : 'white'};
  filter: drop-shadow(0 10px 20px rgb(0 0 0 / 20%));

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const MobilePlanButton = styled(PlanButton)`
  align-self: center;
  border-radius: 25px;
  width: 168px;
  font-size: 14px;
  line-height: 44px;
`;

const PlanDescription = styled.p<{
  $isTopic?: boolean;
  $hideOnMobile?: boolean;
}>`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.darkBlueV2};
  white-space: pre-line;
  text-align: center;
  margin-top: ${({ $isTopic }) => ($isTopic ? '22px' : '34px')};
  margin-bottom: ${({ $isTopic }) => ($isTopic ? '28px' : '16px')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const PlanSubtitle = styled.p<{ $hideOnMobile: boolean }>`
  font-size: 16px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.darkBlueV2};
  margin-bottom: 14px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const PlanHeader = styled.div<{
  $insideBorder?: boolean;
  $isTopic?: boolean;
  $isPlaceholder?: boolean;
  $isPremium?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 34px 23px 25px;
  border-bottom: 1px solid rgb(65 78 113 / 25%);
  ${({ $insideBorder }) =>
    $insideBorder &&
    css`
      margin-left: 5px;
      margin-right: 5px;
    `}
  ${({ $isTopic }) =>
    $isTopic &&
    css`
      padding-top: 26px;
      padding-bottom: 46px;
    `}
  ${({ $isPlaceholder, $isTopic }) =>
    $isPlaceholder &&
    css`
      height: ${$isTopic ? '380px' : '336px'};

      ${({ theme }) => theme.breakpoints.down('md')} {
        height: 32px;
        padding-left: 0;
        padding-right: 0;
      }
    `}

  & h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.darkBlueV2};
    margin-bottom: ${({ $isTopic }) => ($isTopic ? '0' : '30px')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: ${({ $isPlaceholder }) => ($isPlaceholder ? '10px' : '66px')};
    padding: 10px 0 8px;

    & h5 {
      font-size: 12px;
      line-height: 30px;
      margin-bottom: 0;
      ${({ $isPremium }) =>
        $isPremium &&
        css`
          color: ${({ theme }) => theme.colors.careysPink};
        `}
    }
  }
`;

const PlanCell = styled.div<{ $isPremium?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 59px;
  border-bottom: 1px dashed rgb(65 78 113 / 25%);
  margin: 0 5px;

  &:last-child {
    border-color: transparent;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 49px;

    & svg {
      width: 20px;
      height: 20px;
      ${({ $isPremium }) =>
        $isPremium &&
        css`
          path {
            fill: ${({ theme }) => theme.colors.careysPink};
          }
        `}
    }
  }
`;

const ColumnText = styled.span<{ $isOdd: boolean; $isPlaceholder?: boolean }>`
  font-size: 20px;
  font-weight: 300;
  line-height: 59px;
  border-bottom: 1px solid rgb(65 78 113 / 25%);
  color: ${({ theme }) => theme.colors.topicBlue};
  ${({ $isOdd }) =>
    !$isOdd &&
    css`
      background-color: rgb(255 255 255 / 40%);
    `}
  ${({ $isPlaceholder }) =>
    $isPlaceholder &&
    css`
      width: 44px;
      height: 59px;

      ${({ theme }) => theme.breakpoints.down('md')} {
        width: 10px;
        height: 49px;
      }
    `}

  &:last-child {
    border-color: transparent;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 49px;
  }
`;

const PlanColumn = styled.div<{
  $isPlan?: boolean;
  $isFreemium?: boolean;
  $isPremium?: boolean;
  $isTopic?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-style: solid;
  ${({ $isPlan }) =>
    $isPlan &&
    css`
      background-color: white;
      border-width: 4px;
      filter: drop-shadow(0 20px 40px rgb(0 0 0 / 20%));

      ${({ theme }) => theme.breakpoints.down('md')} {
        border-width: 2px;
        border-radius: 4px;
      }
    `}
  ${({ $isFreemium, theme }) =>
    $isFreemium &&
    css`
      border-color: ${theme.colors.darkBlueV2};
    `}
  ${({ $isPremium, theme }) =>
    $isPremium &&
    css`
      border-color: ${theme.colors.secondary};
    `}
`;

const PlansWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 77px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 38px 20px 0;
  }
`;

const Subtitle = styled.p<{ $hideOnMobile?: boolean }>`
  margin-top: 12px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const Title = styled.h3`
  font-size: 40px;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.topicBlue};
  font-weight: 400;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 35px;
  }
`;

const Container = styled.section`
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto 165px;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 52px;
  }
`;

export default forwardRef(ComparisonChartSection);
