import React, { useRef, useState, UIEvent, useCallback } from 'react';
import styled from 'styled-components';

import CustomScrollbar from 'components/CustomScrollbar';

type Props = {
  children: React.ReactElement[];
  itemWidth?: number;
  className?: string;
};

const MobileCarousel = ({ children, className, itemWidth = 200 }: Props) => {
  const [sliderValue, setSliderValue] = useState(0);
  const container = useRef<HTMLDivElement>(null);

  const changeValueOfSliderOnScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const resultingWidth = target.scrollWidth - target.clientWidth;

    setSliderValue((target.scrollLeft / resultingWidth) * 100);
  };

  const changeValueOfSliderWithScrollbar = useCallback((value: number) => {
    if (container.current) {
      const element = container.current;
      const resultingWidth = element.scrollWidth - element.clientWidth;

      element.scrollLeft = (value * resultingWidth) / 100;
      setSliderValue(value);
    }
  }, []);

  return (
    <>
      <Container
        ref={container}
        onScroll={changeValueOfSliderOnScroll}
        $itemWidth={itemWidth}
        className={className}
      >
        {children}
      </Container>
      <CustomScrollbar
        sliderValue={sliderValue}
        setSliderValue={changeValueOfSliderWithScrollbar}
      />
    </>
  );
};

const Container = styled.div<{ $itemWidth: number }>`
  width: 100vw;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: ${({ $itemWidth }) => `${$itemWidth}px`};
  grid-auto-flow: column;
  column-gap: 20px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 23px 0 12px;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 37px;
  }
`;

export default MobileCarousel;
