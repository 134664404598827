import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { AppleIcon } from 'components/Icons/AppleIcon';
import Loader from 'modules/corporateOffer/components/Loader';

type Props = {
  color: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isHeader?: boolean;
  isMobile?: boolean;
  variant: 'appStore' | 'googlePlay';
  onClick: () => void;
};

const MobileButton = ({
  color,
  variant,
  isDisabled,
  isLoading,
  onClick,
}: Props) => (
  <Button
    disabled={isDisabled || isLoading}
    onClick={onClick}
    $color={color}
    $variant={variant}
  >
    <LoaderWrapper $isLoading={isLoading}>
      <Loader bigCircleSize={440} smallCircleSize={100} />
    </LoaderWrapper>
    {variant === 'appStore' ? (
      <IconWrapper $isLoading={isLoading}>
        <AppleIcon prefixId="store" />
      </IconWrapper>
    ) : (
      <IconWrapper $isLoading={isLoading}>
        <img
          src="/icons/googlePlay.svg"
          alt="Google play icon"
          width="22.83"
          height="21.52"
        />
      </IconWrapper>
    )}
    <ButtonContent>
      <ButtonSubtitle>
        <p>{variant === 'appStore' ? 'DOWNLOAD ON' : 'GET IT ON'}</p>
        <span>{variant === 'appStore' ? 'App Store' : 'Google Play'}</span>
      </ButtonSubtitle>
    </ButtonContent>
  </Button>
);

const LoaderWrapper = styled.div<{ $isLoading?: boolean }>`
  position: absolute;
  left: 20px;
  width: 23px;
  margin-top: -80px;
  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      visibility: hidden;
    `};
`;

const Button = styled.button<{
  $color: string;
  $variant?: string;
}>`
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: ${({ $variant }) => ($variant === 'googlePlay' ? '7px' : '12px')};
  width: 163px;
  background-color: ${({ $color }) => $color};
  outline: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  transition: box-shadow 0.5s ease-out;
  height: 54px;
  border-radius: 50px;
  border: 2px solid white;
  color: white;
  box-shadow: 0 6px 8px -3px #bebaba;
  padding-left: 4px;
  justify-content: center;
  padding-bottom: 3px;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray};
    transition: all 1s;
    cursor: not-allowed;
  }
`;

const ButtonSubtitle = styled.div`
  p {
    font-size: 10px;
    font-weight: 400;
    color: white;
  }

  span {
    font-size: 14px;
    color: white;
    position: relative;
    top: 1px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: unset;
`;

const IconWrapper = styled.div<{
  $isMobile?: boolean;
  $isLoading?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      left: -8px;
      top: 0;
    `};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      position: absolute;
      left: 10px;
      top: 8px;
    `};
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      visibility: hidden;
    `};
`;

export default memo(MobileButton);
