import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';

const Logo = () => {
  return (
    <Logo.Wrapper>
      <Logo.Link href="https://www.shido.com/">
        <LogoIcon width={46} height={28} />
        <Logo.RegisterSymbolContainer>
          <Logo.RegisterSymbol>®</Logo.RegisterSymbol>
        </Logo.RegisterSymbolContainer>
      </Logo.Link>
    </Logo.Wrapper>
  );
};

Logo.Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }

  svg {
    width: 64px;
    height: 40px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    path {
      fill: white;
    }
  }
`;

Logo.Link = styled.a`
  cursor: pointer;
`;

Logo.RegisterSymbolContainer = styled.span`
  position: relative;
`;

Logo.RegisterSymbol = styled.span`
  font-weight: 600;
  font-size: 14px;
  position: absolute;

  ${({ theme }) => theme.breakpoints.down('md')} {
    color: white;
  }
`;

export default Logo;
