import React from 'react';
import styled, { keyframes } from 'styled-components';

import { DashedCircleIcon } from 'components/Icons/DashedCircleIcon';
import theme from 'theme/theme';

type LoaderProps = {
  bigCircleSize: number;
  smallCircleSize: number;
};

const Loader = ({ bigCircleSize, smallCircleSize }: LoaderProps) => (
  <AnimationWrapper>
    <AnimationCircle $time={3}>
      <DashedCircleIcon
        width={bigCircleSize}
        height={bigCircleSize}
        color={theme.colors.secondary}
      />
    </AnimationCircle>
    <AnimationCircle $time={2}>
      <DashedCircleIcon
        width={smallCircleSize}
        height={smallCircleSize}
        color={theme.colors.secondary}
      />
    </AnimationCircle>
  </AnimationWrapper>
);

const AnimationWrapper = styled.div`
  position: relative;
  margin-top: 80px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimationCircle = styled.div<{ $time: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} ${({ $time }) => $time}s linear infinite;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
`;

export default Loader;
